import React, {useState,useRef, useEffect} from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../js/constants'

function UserListDisplayHeader(props){

const navigate = useNavigate(); 
const [sessionData, setSessionData] = useState(null)
const [filterState, setFilterState] = useState('All Agents')
const searchRef = useRef(null)

  useEffect(() => {
    setSessionData(JSON.parse(sessionStorage.getItem('ss_sess')))
  },[])//use effect
function openUser(id){
	let path = constants.USER_URL_ABS +props.user.id ;
    console.log("path is "+path)
    navigate(path);
}
  function displayUserMenu(){
      document.getElementById("header-menu").style.display = 'inline'
  }
  function hideUserMenu(){
      document.getElementById("header-menu").style.display = 'none'
  }

  function toggleFilterBox(){
  	props.filterBoxRef.current.style.display = "block"
  }
  function createNewUser(){
  	props.showCreateUserFormFun()
  }

  function selectFilter(filter, type){
  	hideUserMenu()
  	setFilterState(type)
    searchRef.current.value = ''
  	props.filterUsers(filter,0)
  }

return (
	<div class='header-body'>
	    <div class="main-header">
		    <div class="header-dropdown" onClick={displayUserMenu} onMouseLeave = {hideUserMenu}>
				<a class="btn-icon">
					<img src={require('../../../img/dots.png')} alt='' style={{rotate: "90deg", height: "20px" }}/>
				</a>
				<div>
					<div id="header-menu" style={{display: "none"}} onClick={displayUserMenu}>
					   <div><a class="dropdown-item-menu" href="#" onClick={() => selectFilter('all', 'All Agents') }>All Agents</a></div>
					   <div><a class="dropdown-item-menu" href="#" onClick={() => selectFilter('day', 'Created Last One day') }>Created Last One day</a></div>
					   <div><a class="dropdown-item-menu" href="#" onClick={() => selectFilter('week', 'Created Last One week') }>Created Last One week</a></div>
					   <div><a class="dropdown-item-menu" href="#" onClick={() => selectFilter('month', 'Created Last One Month') }>Created Last One Month</a></div>					   
					</div>
				</div>
		    </div>
	            

	    	<div style={{cursor: 'pointer',fontWeight:'bold' }}>{filterState}</div>
     		 <div class="search-wrapper">
   					{<img src={require('../../../img/search.png')} alt=''/>}
   					<input placeholder="search here" class="search-input" onChange={(event) => props.searchUsers(event, 0)} ref={searchRef}/>
             </div>
             <div class="header-right" ref={props.groupButtonsRef}>
	             <button class='ss-button'  type="button"  onClick={() => props.performAction('delete')}>Delete</button>
	          </div>
	          { (sessionData !== null && sessionData.isAdmin) ?  <button class='ss-button'  type="button"  onClick={createNewUser}>+ New Agent</button> : '' }
	    </div>
	</div>
    );

}

export default UserListDisplayHeader;