import * as constants from "./constants.js"
import * as axiosClient from './Axios';
import { jwtDecode } from "jwt-decode";
const voice = require('@twilio/voice-sdk')


export let device = null;

var call = null;

async function intiliazeDevice(response)
{
  device = await new voice.Device(response.data.access_token, {
               codecPreferences: ["opus", "pcmu"],
               fakeLocalDTMF: true,
               enableRingingState: true,
               debug: false,
               closeProtection: true
           })
  device.register();
  try{
      device.on('incoming', function (call){
        call.accept()
      });
  }catch(error){
    console.log(error)
  }
  
  // try{
  //     device.on("ringing", function () {
  //         console.log("Ringing...");
  //     }).catch((error)=>{
  //         console.log(error)
  //     }); 
  // }catch(error){
  //   console.log(error)
  // }
  device.on("ready", function (device) {
         console.log("Twilio.Device Ready!");
         console.log("device ready")
     });
     device.on("error", function (error) {
         console.log("Twilio.Device Error: " + error.message);
         // setUpTwilioDevice()
     });

     device.on("connect", function (conn) {
         console.log('Successfully established call ! ');
         
     });

   device.on("disconnect", function (conn) {
       console.log("Call ended.");
       // setUpTwilioDevice()

   });  
}
export async function setUpTwilioDevice(incomingCallButton){
            axiosClient.postRequest(constants.CALL_URL+'access_token',{})
            .then(function (response) {                
               intiliazeDevice(response)
            }).catch(function (response) {
                console.log(response)
                // setUpTwilioDevice()
            });
  
    }
    
  

export function acceptCall(incomingCallMessage){
    console.log("Accepted call")
    let session = JSON.parse(sessionStorage.getItem('ss_sess'))
    if(device)
      call =  device.connect({ params: { type: "accept_call",
              user: session.userId,
              callId: incomingCallMessage.callId
             }});    
}

export function acceptTransfer(incomingCallMessage){
    console.log("Accepted call")
    let session = JSON.parse(sessionStorage.getItem('ss_sess'))
    if(device)
      call =  device.connect({ params: { type: "accept_"+incomingCallMessage.type,
              user: session.userId,
              callId: incomingCallMessage.callId
             }});    
}
export function mute(){
  if(call)
    call.mute(true)
}
export function unmute(){
  if(call)
    call.mute(false)
}
export async function makeACall(fromNumber, toNumber, callback,countryCode){
    console.log("Make a call "+device)
    let session = JSON.parse(sessionStorage.getItem('ss_sess'))
    try{
            if (device) {
                console.log("device is intiliazed "+ toNumber)
                let payload = {to: toNumber, type: 'browser_outgoing', user: session.userId, from: fromNumber, countryCode: countryCode}
                call =  await device.connect({ params: payload });
                callback('connecting')
                console.log("call "+call)
               try{ 
                  call.on("ringing", function () {
                        console.log("Ringing...");
                        callback('ringing')
                    });
                }
                catch(error){console.log(error)}
                try{
                  call.on("connect", function () {
                        console.log("Connected...");
                        callback('connected')
                    });
                    
                }
                catch(error){console.log(error)}
                try{                
                    call.on("disconnect", function (conn) {
                       console.log("Call ended.");
                       callback('disconnected')
                   });
                }
                catch(error){console.log(error)}

               call.on("accept", function () {
                  console.log("Connected...");
                  callback('connected')
                }).catch((error)=>{
                  console.log(error)
                });

               
                call.on("cancel", function (conn) {
                   console.log("Call cancelled.");
                   callback('cancel')
               });
               call.on("error", function (conn) {
                   console.log("Call error. "+conn);
                   callback('error')
               });        

            }
    }catch(error){
        console.log(error)        
    }

} // make a call


export function endCall(){
  console.log("endCall called")
  if (device) {
    device.disconnectAll();
  }
  // if(call)
  //   call.disconnect()
}
export function digitClicked(){

}