import React, { useState, useEffect, useRef } from 'react';
import {$,jquery} from 'jquery';
import SideNav from './SideNav'
import Topnav from './Topnav/Topnav'
import LoginCheck from './LoginCheck'
import AccountCheck from './AccountCheck'
import CallWindow from './CallWindow/js/CallWindow'
import AuthorizationCheck from './Login/AuthorizationCheck'
class StandardComponents extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    console.log('componentDidCatch '+error+ '  '+errorInfo)
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }
  
  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return (
    	<>
		  <Topnav/>
	      <SideNav/>
	      <LoginCheck />
	      <AccountCheck />
	      <CallWindow /> 
        <AuthorizationCheck   />
    	</>
    )
  }  
}

 export default StandardComponents;