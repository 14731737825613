import React, {useState, useRef} from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../js/constants'

function ContactListDisplayHeader(props){

const navigate = useNavigate();
const searchRef = useRef(null) 
const [filterState, setFilterState] = useState('All Contacts')

function openContact(id){
	let path = constants.CONTACT_URL_ABS +props.contact.id ;
  navigate(path);
}
  
function displayContactMenu(){
  if(document.getElementById("header-menu").style.display === 'none')
    document.getElementById("header-menu").style.display = 'inline'
  else
    document.getElementById("header-menu").style.display = 'none'
}
  function hideContactMenu(){
      document.getElementById("header-menu").style.display = 'none'
  }

  function createNewContact(){
  	props.showCreateConactFormFun()
  }

  function selectFilter(filter, type){
  	hideContactMenu()
  	setFilterState(type)
    searchRef.current.value = ''
  	props.filterContacts(filter,0)
  }

return (
    <div class='header-body'>
	    <div class="main-header">
		    <div class="header-dropdown" onClick={displayContactMenu} >
    				<a class="btn-icon">
    					<img src={require('../../../img/dots.png')} alt='' style={{rotate: "90deg", height: "20px" }}/>
    				</a>
				<div>
						<div id="header-menu" style={{display: "none"}} onClick={displayContactMenu}>
						   <a class="dropdown-item-menu" href="#" onClick={() => selectFilter('all', 'All Contacts') }>All Contacts</a>
               <a class="dropdown-item-menu" href="#" onClick={() => selectFilter('day', 'Created Last One day') }>Created Last One day</a>
               <a class="dropdown-item-menu" href="#" onClick={() => selectFilter('week', 'Created Last One week') }>Created Last One week</a>
               <a class="dropdown-item-menu" href="#" onClick={() => selectFilter('month', 'Created Last One Month') }>Created Last One Month</a>
            </div>
				</div>
		    </div>
	            
	    	    <div onClick={displayContactMenu} style={{cursor: 'pointer',fontWeight:'bold',fontSize:'14px' }} class='header-filter-name'>{filterState}</div>
     		     <div class="search-wrapper">
   					    {<img src={require('../../../img/search.png')} alt=''/>}
   					    <input placeholder="search here" class="search-input" onChange={(event) => props.searchContacts(event, 0)} ref={searchRef}/>
             </div>
             <div class="header-right" ref={props.groupButtonsRef}>
	             <button class='ss-button'  type="button"  onClick={() => props.performAction('delete')}>Delete</button>
	           </div>
	         <button class='ss-button'  onClick={createNewContact}>+ New Contact</button>
	    </div>
    </div>
    );
}

export default ContactListDisplayHeader;