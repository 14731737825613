
import React from "react"
import {useState, setState, useEffect} from 'react';
import {useNavigate} from "react-router-dom"


function StandardPlanCard(props){
	const [price, setPrice] = useState(<span><sup>$</sup>6</span>)
	const [currency, setCurrency] = useState('USD')

	function changePrice(event){		
		if(event.target.value === 'USD')
			setPrice(<span><sup>$</sup>6</span>)
		else if(event.target.value === 'INR')
			setPrice(<span><sup>&#x20b9;</sup>480</span>)
		else if(event.target.value === 'EURO')
			setPrice(<span><sup>&#8364;</sup>5.5</span>)		
		setCurrency(event.target.value)
	}

	return(
			<div>
			 {/* <div style={{float:'right',display:'block'}}><select style={{width:'75px', height:'34px'}} onChange={changePrice}><option>USD</option><option>INR</option></select></div> */}
		     <div class="pricing-plans">		     
		      <div class="pricing-card standard">
		        <div class="heading">
		          <h4>STANDARD</h4>
		        </div>
		        <p class="price">
		          {price}
		          <sub style={{fontSize:'14px'}}>/Month/Agent</sub>          
		        </p>
		        <ul class="features">
		          <li>
		            <i class="las la-check"></i>
		            Ticket Creation
		          </li>
		          <li>
		            <i class="las la-check"></i>
		           Contact Management
		          </li>
				  <li>
		            <i class="las la-check"></i>
		            Dialing/Receiving Calls from Browser
		          </li>

		          <li>
		            <i class="las la-check"></i>
		            Conditional Call Routing
		          </li>          
		          <li>
		            <i class="las la-check"></i>
		            Interactive Voice Response(IVR)
		          </li>		          
		          <li>
		            <i class="las la-check"></i>
		            Business Hours
		          </li>
		          <li>
		            <i class="las la-check"></i>
		            SLA
		          </li>
		          <li>
		            <i class="las la-check"></i>
		            Private Comments
		          </li>
		          <li>
		            <i class="las la-check"></i>
		            Merge Tickets
		          </li>
		        </ul>
		        
		        <button class="cta-btn" onClick={()=> props.selectPlan('STANDARD')}>{props.subscription.plan === 'STANDARD' ? 'Renew Now' : 'Choose Plan'}</button>
		      </div>
		  </div>
		</div>  
		 )
}

export default StandardPlanCard;