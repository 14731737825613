import React, { useState, useEffect, useRef,createContext } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import UserLine from "./UserLine"
import UserListDisplayHeader from './UserListDisplayHeader'
import Pagination from '../../Pagination'
import CreateUserForm from './CreateUserForm'
import {useNavigate, useParams} from "react-router-dom"
import * as ConstantStrings from '../../../js/ConstantStrings'
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function UsersListDisplay(){
	const [sessionData, setSessionData] = useState(null)
	const [filter, setFilter] = useState('all')
	const editIdRef = useRef(-1)
	const toastRef = useRef(null)
	const params = useParams()
	const [search, setSearch] = useState('')
	const [count, setCount] = useState(null)
	const [pageNo, setPageNo] = useState(0)
	const [pageSize, setPageSize] = useState(25)	
	const [userLine, setUserLine] = useState("")
	const primaryCheckBoxRef = useRef(null)
	const [ids, setIds] = useState([])
	const groupButtonsRef = useRef(null)
	const [showCreateUserForm, setShowCreateUserForm] = useState(false)
	
	useEffect(() => {
		loadUsers(pageNo, pageSize);
		setSessionData(JSON.parse(sessionStorage.getItem('ss_sess')))
		toastRef.current.style.display = 'none'
		groupButtonsRef.current.style.visibility = 'hidden'
		if(params.id !== undefined){
			editIdRef.current = params.id
			setShowCreateUserForm(true)
		}		
	},[])//use effect

function loadUsers(pn, ps){
	axiosClient.getRequest(constants.USER_URL+'?pageNo='+pn+'&pageSize='+ps)
	.then(function (response) {
		let userList = null;
		userList = response.data.map((element, index) => (									
						<UserLine openUser={openUser} user= {element} checkBoxClicked={checkBoxClicked} resendActivationLink={resendActivationLink}/>
		));
		if(Object.keys(userList).length === 0){
			setCount(0)
			setUserLine('No Records Found...')
		}else{
			setUserLine(userList)
			let countTemp = response.data[0].count;
			setCount(countTemp)
		}	
	}).catch(function (response) {
	    console.log(response);
	});	
}	
function onPrimaryCheckboxClicked(event){
	let idsTemp = ids;
	let checkBoxes = document.getElementsByClassName('user-line-checkbox')
	for(var i=0;i<checkBoxes.length;i++){
		checkBoxes[i].checked = event.target.checked
		let id = checkBoxes[i].name.split('-')[1]
		if(event.target.checked && !idsTemp.includes(id)){
			idsTemp.push(id)
			groupButtonsRef.current.style.visibility = 'visible'				
		}
	}
	if(!event.target.checked){	
		groupButtonsRef.current.style.visibility = 'hidden'
		idsTemp.splice(0,idsTemp.length)
	}
	console.log('idsTemp '+idsTemp)
	setIds(idsTemp)
}

function checkBoxClicked(id, value){
	console.log('checkBoxClicked '+value)
	let idsTemp = ids;
	if(value){
		idsTemp.push(id)
	}else{
		 const index = idsTemp.indexOf(id);
		if (index > -1) {
  			idsTemp.splice(index, 1);
		}
	}
	setIds(idsTemp)
	if(idsTemp.length !== 0){		
		groupButtonsRef.current.children[0].style.visibility = 'visible'
	}else{
		groupButtonsRef.current.children[0].style.visibility = 'hidden'
	}
	if(!value){
		console.log('primaryCheckBoxRef '+primaryCheckBoxRef.current)
		primaryCheckBoxRef.current.checked = false
	}
}

function searchUsers(event,pn){
	setSearch(event.target.value)
	setPageNo(pn)
	axiosClient.getRequest(constants.USER_URL+"search?keyword="+encodeURIComponent(event.target.value)+'&pageNo='+pn+'&pageSize=25')
	.then(function (response) {
	let userList = null;
	userList = response.data.map((element, index) => (					
					<UserLine openUser={openUser} user= {element} index={index} checkBoxClicked={checkBoxClicked} resendActivationLink={resendActivationLink}/>
	));
	if(Object.keys(userList).length === 0){
		setCount(0)
		setUserLine('No Records Found...')
	}else{
		setUserLine(userList)
		let countTemp = response.data[0].count;
		setCount(countTemp)
	}	
	}).catch(function (response) {
	    console.log(response);
	});	
}
function filterUsers(type,pn){
	setPageNo(pn)
	axiosClient.getRequest(constants.USER_URL+"filter?keyword="+type+'&pageNo='+pn)
		.then(function (response) {
		let userList = null;
		userList = response.data.map((element, index) => (					
						<UserLine openUser={openUser} user= {element} checkBoxClicked={checkBoxClicked} resendActivationLink={resendActivationLink}/>
		));
			if(Object.keys(userList).length === 0){
				setCount(0)
				setUserLine('No Records Found...')
			}else{		
				let countTemp = response.data[0].count;
				setCount(countTemp)		
				setUserLine(userList)
				setFilter(type)
			}			
		}).catch(function (response) {
		    console.log(response);
		});	
}
function filterUsersWithSearch(search, pageNo){
		setPageNo(pageNo)
		axiosClient.getRequest(constants.USER_URL+'filter_with_search?keyword='+filter+'&pageNo='+pageNo+'&search='+search)
		.then(function (response) {
			let userList = null;
			userList = response.data.map((element, index) => (					
							<UserLine openUser={openUser} user= {element} checkBoxClicked={checkBoxClicked} resendActivationLink={resendActivationLink}/>
			));
			if(Object.keys(userList).length === 0){
				setCount(0)
				setUserLine('No Records Found...')
			}else{		
				let countTemp = response.data[0].count;
				setCount(countTemp)		
				setUserLine(userList)
			}	
		}).catch(function (response) {
		    console.log(response)
		})	
}
function showCreateUserFormFun(){
	setShowCreateUserForm(true)
}
function closeCreateWindow(load){
	setShowCreateUserForm(false)
	editIdRef.current = -1
	if(load)
		loadUsers(0,25)
}


function performAction(type){
	console.log('performAction '+ ids)
	let payload = {'ids': ids}
	let url = null;
	if(type === 'delete')
		url = constants.USER_URL+'delete';

   	axiosClient.postRequest(url, payload)
       .then(function (response) {
           loadUsers(0,25)
			let elements = document.getElementsByClassName('user-line-checkbox')
			for (var i = 0; i < elements.length; i++) {
				if(elements[i].checked)
					elements[i].checked = false;		
			}
			primaryCheckBoxRef.current.checked = false;
			if(type === 'delete'){
				console.log('response '+response.data)
				if(response.data === 'ACCOUNT_OWNER'){
					toast.error('Account Owner cannnot be deleted')
					return
				}					
				toast.success(ConstantStrings.DELETE_AGENTS_SUCCESS)				
			}
       }).catch((error) => {
           console.log("error in perform action " +error)
           	
       }); 
    groupButtonsRef.current.children[0].style.visibility = 'hidden'
}

function hideToast(){
	toastRef.current.style.display = 'none'
}
function resendActivationLink(id){
	let payload = {id: id}
	axiosClient.postRequest(constants.USER_URL+'resend_activation_link', payload)
       .then(function (response) {
       	toast.success('Activation link sent.')
       	// toastRef.current.style.display = 'flex'
       	// setTimeout(hideToast, 5000)
       }).catch((error) => {
           console.log(error)
    }); 	
}
function handlePagination(number){
	setPageNo(number)
	if(filter ==='all'){
		if(search === '')
			loadUsers(number, pageSize)
		else{
			let event={'target':{'value': search }}
			searchUsers(event, number)
		}
	}
	else if(filter !== 'all'){
		if(search === '')
			filterUsers(filter, number)
		else
			filterUsersWithSearch(search, number)
	}		
	
}

function openUser(id){
	console.log('openUser '+id)
	editIdRef.current = id
	setShowCreateUserForm(true)	
}
return (
	<>
		<UserListDisplayHeader searchUsers={searchUsers} 
							   filterUsers={filterUsers}
							   showCreateUserFormFun={showCreateUserFormFun}
							   groupButtonsRef={groupButtonsRef}
							   performAction={performAction}/>

		<div class="main-body" style={{backgroundColor: '#e5e7eb'}}>

		<div class="container page-body" style={{minWidth:'100%',marginLeft:'0px', marginRight:'0px'}}>
         <div class="row">
            <div class="col-lg-12">
               <div class>
                  <div class="table-responsive">
                     <table class="table table-nowrap align-middle table-borderless">
                        <thead>
                           <tr>
                           	  { (sessionData !== null && sessionData.isAdmin) ?   
                              <th scope="col" class="ps-4" style={{width: "50px", backgroundColor:'#f4f4f4'}}>
                                 <div class="form-check font-size-16"><input type="checkbox" class="form-check-input" ref={primaryCheckBoxRef} style={{border: '1px solid #A9A9A9'}} onClick={onPrimaryCheckboxClicked}/></div>
                              </th>
                              : ''}
                              <th scope="col" style={{fontWeight:'500', backgroundColor:'#f4f4f4'}}>Name</th>
                              <th scope="col" style={{backgroundColor:'#f4f4f4'}}>Email</th>
                              <th scope="col" style={{backgroundColor:'#f4f4f4'}}>Mobile</th>
                              <th scope="col" style={{backgroundColor:'#f4f4f4'}}>WorkPhone</th>
                              <th scope="col" style={{width: "200px",backgroundColor:'#f4f4f4'}}>Role</th>
							  <th scope="col" style={{backgroundColor:'#f4f4f4'}}>Active</th>
							  { (sessionData !== null && sessionData.isAdmin) ?  
							  	<th scope="col" style={{backgroundColor:'#f4f4f4'}}></th>                            
							  	:''}
							  
                           </tr>
                        </thead>
                        <tbody>
                        	{userLine}                    
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>         
      </div>
				{ showCreateUserForm ? <CreateUserForm closeCreateWindow={closeCreateWindow} editIdRef={editIdRef}/> : '' }
			</div>
			<div class='toaster' ref={toastRef} />
		   <Pagination count={count} pageNo={pageNo}
		   					 handlePagination={handlePagination}/>
			<ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}
                newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss
                draggable pauseOnHover theme="colored"/>						
	</>
 );

}
export default UsersListDisplay;
