import React, { useState, useEffect } from 'react';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import '../css/ticket.css'
import TicketLine from "./TicketLine"
import CreateTicketForm from './CreateTicketForm'
import TicketListFilterBox from './TicketListFilterBox'
import TicketListDisplayHeader from './TicketListDisplayHeader'
import {Route, Link, Routes, useParams, useNavigate} from 'react-router-dom';
import TicketHistoryLine from './TicketHistoryLine'

function TicketHistory(props){
	const [history, setHistory] = useState(null)
	const [ticketLine, setTicketLine] = useState(null)
	const [showCreateTicketForm, setShowCreateTicketForm] = useState(false)
	const params = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		console.log('TicketHistory '+params.id)
	axiosClient.getRequest(constants.TICKET_URL+'history/'+params.id)
	  .then(function (response) {
	  		let historyList = response.data.map((element, index) => (					
					<TicketHistoryLine element= {element} />     
			));
			setHistory(historyList)
	  }).catch((error) => {
	     if(error.response !== undefined && error.response.data === 'Error while fetching ticket'){
	        let div = document.createElement('div')
	        div.innerHTML = "Either the ticket does not exist or you dont have permisions. Redirecting to home"
	        //Todo Add sleep
	        navigate(constants.TICKET_URL_ABS)
	    }            
	    console.log(error)
	  });
	},[])//use effect
	return (
	<>
		<div class="ticket-middle-box" style={{backgroundColor: '#e5e7eb'}}>
		<div class="history-container container">
		  <div class="history-wrapper">
		    <ul class="sessions">
		    	{history}
		    </ul>
		  </div>
		</div> 		
			
		</div>
	</>
		);

	}
export default TicketHistory;