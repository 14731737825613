import React from "react"
import {useRef, useState, setState, useEffect} from 'react';
import {useNavigate} from "react-router-dom"
import ReactDOMServer from 'react-dom/server';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'



function SLACondition(props){
	const fromInputRef = useRef(null)
	const fromSelectRef = useRef(null)
	const INPUT_POS = 0
	const DROP_DOWN_POS = 1;
	const HIDDEN_INPUT = 2;
	const SPECIFIC_CONTACT = 'Contact'
	const SPECIFIC_COMPANY = 'Company'
	const CONTACT_GROUP = 'ContactGroup'
	const COMPANY_GROUP = 'CompanyGroup'
	const ANYBODY = 'Anybody'
 	let fromSelectionUrl = {}
 	fromSelectionUrl[SPECIFIC_CONTACT] = constants.CONTACT_URL
 	fromSelectionUrl[SPECIFIC_COMPANY] = constants.COMPANY_URL
 	fromSelectionUrl[CONTACT_GROUP] = constants.CONTACT_GROUP_URL
 	fromSelectionUrl[COMPANY_GROUP] = constants.COMPANY_GROUP_URL


  useEffect(()=>{
  	if(props.data !== undefined){  		
  		if(props.data.fromSelection.toLowerCase() !== ANYBODY.toLowerCase()){
				axiosClient.getRequest(fromSelectionUrl[props.data.fromSelection]+props.data.fromValue)
				.then(function (response) {
				  fromSelectRef.current.value = props.data.fromSelection
				  fromInputRef.current.children[INPUT_POS].value = response.data.name
				  fromInputRef.current.children[HIDDEN_INPUT].value = response.data.id
				}).catch(function (error) {
				    console.log(error.response.data.error)
				});	
		}else{
			fromSelectRef.current.value = props.data.fromSelection
			fromInputRef.current.children[INPUT_POS].disabled = true
			fromInputRef.current.children[HIDDEN_INPUT].value = '-1'
		}
 		
	}	
  });

function fromValueSelected(event)	{
	console.log("fromValueSelected ")
	if(event.target.value !== null && event.target.value.toLowerCase() === ANYBODY.toLowerCase()){
		fromInputRef.current.children[INPUT_POS].disabled = true
	}
	else
		fromInputRef.current.children[INPUT_POS].disabled = false
	fromInputRef.current.children[INPUT_POS].value = ''
	fromInputRef.current.children[HIDDEN_INPUT].value = '-1'
}

function fetchFromData(event){
 fromInputRef.current.children[DROP_DOWN_POS].innerHTML = ''
 fromInputRef.current.children[DROP_DOWN_POS].style.display = 'none' 
 fromInputRef.current.children[HIDDEN_INPUT].value = '-1'
 if(event.target.value.length < 3)
 	return

  let fromSelection = document.getElementsByName('fromSelect-'+event.target.name.split('-')[1])[0]
  console.log('fromSelection '+fromSelection.value)
	axiosClient.getRequest(fromSelectionUrl[fromSelection.value] +"search_by_name?keyword="+event.target.value)
	.then(function (response) {
		response.data.forEach((element, index)=>{
				addChildToFromDropdown(event,element,fromSelection.value)
      })		
	}).catch(function (response) {
	    console.log(response.data.error)
	});	
}
function addChildToFromDropdown(event,element,type){
	console.log(" addChildToFromDropdown "+element.email)
 	  const DROP_DOWN_POS_CURR = 1;
 	  const HIDDEN_FIELD_CURR = 2;
	  console.log("addChildToFromDropdown ")
	  let fromConditionDropDownRef = event.target.parentElement.children[DROP_DOWN_POS_CURR]
      let aTag = document.createElement('a')
      aTag.name=element.id      
      aTag.innerHTML = (element.fullName === undefined ? element.name : element.fullName )
      aTag.onclick = onSelectFromValueDropdown
      fromConditionDropDownRef.appendChild(aTag)
      fromConditionDropDownRef.style.display='inline'
}
function onSelectFromValueDropdown(event){  
  let id = event.target.name
  console.log("onSelectFromValueDropdown new id is "+ event.target.innerHTML.split(";")[1])
  event.target.parentElement.parentElement.children[0].value = event.target.innerHTML //.split(";")[1]
  event.target.parentElement.parentElement.children[2].value = id //hidden input text
  event.target.parentElement.parentElement.children[1].style.display = 'none'
  // event.target.parentElement.parentElement.children[1].style.display = 'none'
}

	return(
			<div style={{display:'flex', fontSize:'12px',marginTop:'30px'}}>
				<div style={{ paddingTop:'1px'}}>
				  <div style={{width:'300px', fontWeight: 'bold',paddingTop:'5px', fontWeight:'600',fontSize:'16px'}}>Ticket Created by</div>				
					<select name= {'fromSelect-'+props.index} onChange={fromValueSelected} ref={fromSelectRef}
						class='sla-condition-input'>
						<option value="Contact">Contact</option>
						<option value="Company">Company</option>
						<option value="ContactGroup">Contact Group</option>
						<option value="CompanyGroup">Company Group</option>
						<option value="Anybody">Anybody</option>
					</select>
				</div>
				<div style={{fontWeight: 'bold',marginLeft:'80px',paddingTop:'5px', fontWeight:'600',fontSize:'16px'}}>
				  <div>Value </div>			  				  	
					<div  style={{position:'relative'}} ref={fromInputRef}>
						<input name={'fromInput-'+props.index} placeholder="Search ..." onChange={fetchFromData} class='sla-condition-input'/>
						<div class="sla-condition-dropdown-content" style={{display: 'none' }}/>
						<input name={'fromInput-'+props.index+'-hidden'} style= {{display: 'none'}}/>
					</div>
				</div>				

		</div>		
	)


}

export default SLACondition;