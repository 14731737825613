import React from 'react';
import {useState, setState, useRef, useEffect} from 'react';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import "../css/ticket.css"
import Tag from './Tag'

function TicketListFilterBox(props){
  const priorityDropdownRef = useRef(null)
  const statusDropdownRef = useRef(null)
  const [options, setOptions] = useState([])
  const [selectedPriorities, setSelectedPriorities] = useState('')
  const [selectedStatus, setSelectedStatus] = useState('')
  const errorRef = useRef(null)
  const REPORTER_DROP_DOWN_UL_POS = 0
  const REPORTER_DROP_DOWN_MENU_POS = 1;
  const REPORTER_DROP_DOWN_INPUT_POS = 2;
  let selectedValuesMapTemp ={'agent': [], 'reporter': [],
                          'company':[], 'team': [],
                          'contact_group':[], 'company_group':[]
                        } 
  let tagsMapTemp = {'agent': [], 'reporter': [],
              'company':[], 'team': [],
              'contact_group':[], 'company_group':[]
            }
  const [selectedValuesMap, setSelectedValuesMap] = useState(selectedValuesMapTemp)
  const [tagsMap, setTagsMap] = useState(tagsMapTemp)


function handleSearch(event, type){ 
    let url = {'agent': constants.USER_URL,
              'reporter': constants.CONTACT_URL,
              'contact_group': constants.CONTACT_GROUP_URL,
              'company' : constants.COMPANY_URL,
              'company_group' : constants.COMPANY_GROUP_URL,
              'team':constants.TEAM_URL
            }

    event.target.parentElement.parentElement.children[REPORTER_DROP_DOWN_MENU_POS].innerHTML = ''
    if(event.target.value.length < 2)
      return
    axiosClient.getRequest(url[type]+"search_by_name?keyword="+event.target.value)
    .then(function (response) {          
        response.data.forEach((element, index)=>{             
            addChildToDropdown(element,event,type)              
        })
        if(response.data.length > 0)
          event.target.parentElement.parentElement.children[REPORTER_DROP_DOWN_MENU_POS].style.display = 'block'
    }).catch(function (response) {
        console.log(response);
    });
}

function validateForm(event){
      return true;
}

function handleSubmit(event){
  event.preventDefault()    
  if(!validateForm(event)){
    return
  }
  let payload = {'agent' : selectedValuesMap['agent'],
  'reporter' : selectedValuesMap['reporter'], 'company': selectedValuesMap['company'],
  'team': selectedValuesMap['team'], 'contact_group' : selectedValuesMap['contact_group'],
  'company_group': selectedValuesMap['company_group'] , 'priority' : selectedPriorities,
  'status' : selectedStatus,
  'first_response': (event.target.first_response.value === 'Breached' ? 'no' : 'yes'),
  'createdFrom':event.target.createdFrom.value,
  'createdTo':event.target.createdTo.value
  }
  props.handleFilterSubmit(payload,0)
}

function selectStatus(event){
  event.preventDefault()
  setSelectedStatus([])
  let elements = document.getElementsByClassName('status-checkbox')
  let temp = ''
  for(var i=0;i<elements.length;i++){
    if(elements[i].checked === true){      
      temp=temp+elements[i].name +','
    }    
  }
  if(temp.length > 0)
    temp = temp.substring(0, temp.length-1);
  setSelectedStatus(temp)
  event.target.parentElement.style.visibility='hidden'
}
function selectPriority(event){
  event.preventDefault()
  setSelectedPriorities([])
  let elements = document.getElementsByClassName('priority-checkbox')
  let temp = ''
  for(var i=0;i<elements.length;i++){
    if(elements[i].checked === true){      
      temp=temp+elements[i].name +','
    }    
  }
  if(temp.length > 0)
    temp = temp.substring(0, temp.length-1);
  setSelectedPriorities(temp)
  event.target.parentElement.style.visibility='hidden'
}

function displayStatus(event){
  priorityDropdownRef.current.style.visibility='hidden'
  statusDropdownRef.current.style.visibility='visible'
  statusDropdownRef.current.children[1].scrollIntoView()
}
function displayPriorities(event){
  priorityDropdownRef.current.style.visibility='visible'
  statusDropdownRef.current.style.visibility='hidden'
  priorityDropdownRef.current.children[1].scrollIntoView()
}


function addChildToDropdown(element, event, type){
    let selectedValues = selectedValuesMap[type]
    if(selectedValues.includes(element.id))
      return
    let aTag = document.createElement('a')
    aTag.style.display='block'
    aTag.name=element.id
    aTag.innerHTML =  element.name
    aTag.onclick = (event) => addTag(event, type)
    event.target.parentElement.parentElement.children[REPORTER_DROP_DOWN_MENU_POS].appendChild(aTag)
}


function removeTag(event,id,type,ul){
  let selectedValuesMapTemp = selectedValuesMap
  let selectedValues = selectedValuesMapTemp[type]
  let idx = selectedValues.indexOf(id)  
  let tags = tagsMap[type]
  // selectedValues and tags must be in sync
  selectedValues.splice(idx, 1)
  tags.splice(idx,1)
  ul.querySelectorAll("li").forEach(li => li.remove());
  tags.slice().forEach((tag,index) =>{
      var li = document.createElement('li');  
      var span = document.createElement('span')      
      span.onclick=(event)=>removeTag(event, selectedValues[index], type, ul)
      span.innerHTML = `&times;`
      span.style.cursor = 'pointer'
      li.innerHTML = tag
      li.value = index      
      li.appendChild(span)
      ul.insertBefore(li, ul.lastChild) 
  }); 
  selectedValuesMapTemp[type] =  selectedValues
  setSelectedValuesMap(selectedValuesMapTemp)
}
function createTag(event, tags, type){
  // fetching corresponding ul
  const ul = event.target.parentElement.parentElement.children[0]
  ul.querySelectorAll("li").forEach(li => li.remove());
  let selectedValues = selectedValuesMap[type]
  tags.slice().forEach((tag,index) =>{
      var li = document.createElement('li');  
      var span = document.createElement('span')
      span.onclick=(event)=>removeTag(event, selectedValues[index], type, ul)
      span.innerHTML = `&times;`
      span.style.cursor = 'pointer'
      li.innerHTML = tag
      li.value = selectedValues[index]   
      li.appendChild(span)
      ul.insertBefore(li, ul.lastChild) 
      // ul.insertAdjacentHTML("afterbegin", li);
  });
}

function addTag(event, type){
  console.log('addTag '+event.target.name)            
  let tag = event.target.innerHTML.replace(/\s+/g, ' ');
  let tags = tagsMap[type]
  let selectedValuesMapTemp = selectedValuesMap
  let selectedValues = selectedValuesMapTemp[type]
  if( !selectedValues.includes(event.target.name)){
    // selectedValues and tags must be in sync
    selectedValues.push(event.target.name)
    tags.push(tag);        
    createTag(event,tags, type);
  }
  event.target.parentElement.parentElement.children[REPORTER_DROP_DOWN_UL_POS].lastChild.value=''
  event.target.parentElement.parentElement.children[REPORTER_DROP_DOWN_MENU_POS].style.display='none'
  event.target.parentElement.parentElement.children[REPORTER_DROP_DOWN_MENU_POS].innerHTML = ''  
  console.log('addTag '+JSON.stringify(selectedValuesMap))
  selectedValuesMapTemp[type] = selectedValues
  setSelectedValuesMap(selectedValuesMapTemp)
}

  return (
          <div class="ticket-filter-frame" ref={props.filterBoxRef} >
            <div><a href="javascript:void(0)"  onClick={props.closeFilterBox} class="ticket-filter-close-button-wrapper" >&times;</a></div>
             <b style={{marginTop: '2%'}}>Apply Filter</b>
             <form onSubmit={handleSubmit}>
             <div style={{ width:'100%', paddingleft:'0px',backgroundColor:'white',height: 'calc(100vh - 180px)', overflowY:'scroll', overflowX:'none'}}>
                 <div class="create-ticket-form-label" style={{ marginTop: "3%"}}>Assignee:</div>
                  <div class="wrapper">
                    <div class="content">
                      <ul><input type="text" style={{height:'30px', width: '95%'}} spellcheck="false" onChange={(event) => handleSearch(event, 'agent')} name='agent'/></ul>
                      <div style={{display:'none', position :'absolute',width:'100%'}} class='create-ticket-filter-dropdown-content'/>
                      <input type='text' style={{display:'none'}} />
                    </div>
                  </div>
                 <div class="create-ticket-form-label" style={{ marginTop: "3%"}}>Reporter:</div>
                 <div class="wrapper">
                    <div class="content">
                      <ul><input type="text" style={{padding:'2px',height:'30px', width: '95%'}} spellcheck="false" onChange={(event) => handleSearch(event, 'reporter')} name='reporter'/></ul>
                      <div style={{display:'none', position :'absolute'}} class='create-ticket-filter-dropdown-content'/>
                    </div>
                 </div>
                 <div class="create-ticket-form-label" style={{ marginTop: "3%"}}>Company:</div>
                 <div class="wrapper">
                      <div class="content">
                        <ul><input type="text" style={{height:'30px', width: '95%'}} spellcheck="false" onChange={(event) => handleSearch(event, 'company')} name='company'/></ul>
                        <div style={{display:'none', position :'absolute'}} class='create-ticket-filter-dropdown-content'/>
                      </div>
                 </div>                
                 <div class="create-ticket-form-label" style={{ marginTop: "3%"}}>Team:</div>
                    <div class="wrapper">
                      <div class="content">
                        <ul><input type="text" style={{height:'30px', width: '95%'}} spellcheck="false" onChange={(event) => handleSearch(event, 'team')} name='team'/></ul>
                        <div style={{display:'none', position :'absolute'}} class='create-ticket-filter-dropdown-content'/>
                      </div>
                    </div>                                
                 <div class="create-ticket-form-label" style={{ marginTop: "3%"}}>Contact Group:</div>
                    <div class="wrapper">
                      <div class="content">
                        <ul><input type="text" style={{height:'30px', width: '95%'}} spellcheck="false" onChange={(event) => handleSearch(event,'contact_group')} name='contact_group'/></ul>
                        <div style={{display:'none', position :'absolute'}} class='create-ticket-filter-dropdown-content'/>
                      </div>
                    </div>
                 <div class="create-ticket-form-label" style={{ marginTop: "3%"}}>Company Group:</div>
                    <div class="wrapper">
                      <div class="content">
                        <ul><input type="text" style={{height:'30px', width: '95%'}} spellcheck="false" onChange={(event) => handleSearch(event,'company_group')} name='company_group'/></ul>
                        <div style={{display:'none', position :'absolute'}} class='create-ticket-filter-dropdown-content'/>
                      </div>
                    </div>                    
                    <div class="create-ticket-form-label" style={{ marginTop: "3%"}}>Priority</div>
                    <div style={{position:'relative'}}>
                        <div id="input" contenteditable='false' style={{paddingTop:'10px',height:'36px',fontWeight:'500',fontSize:'18px', width: '90%', border:'1px solid black', borderRadius:'0px',verticalAlign: 'sub'}} onClick={displayPriorities} >{selectedPriorities}</div>
                        <div style={{visibility: 'hidden', position: 'absolute', overflowY:'hidden',width:'90%'}} class='create-ticket-filter-dropdown-content' ref={priorityDropdownRef}>
                            <div style={{maxHeight:'200px', overflowY:'scroll',borderBottom:'1px solid #DAD9D2'}}>                              
                              <a><input id = 'priority-escalated' type='checkbox' name="Escalated" class='priority-checkbox' style={{marginRight: '10px' }}/> <span class='priority-div-filter' style={{backgroundColor: 'red' , border:'1px solid red'}}/> <label for ='priority-escalated' style={{fontWeight:'600',fontSize:'16px',cursor:'pointer'}}>Escalated</label> </a>
                              <a><input id = 'priority-critical' type='checkbox' name="Critical"   class='priority-checkbox' style={{marginRight: '10px' }}/> <span class='priority-div-filter' style={{backgroundColor: '#dc3545', border:'1px solid #dc3545'}}/><label for ='priority-critical' style={{fontWeight:'600',fontSize:'16px',cursor:'pointer'}}>Critical</label> </a>
                              <a><input id = 'priority-high' type='checkbox' name="High"           class='priority-checkbox' style={{marginRight: '10px' }}/> <span class='priority-div-filter' style={{backgroundColor: '#ff7400', border:'1px solid #ff7400'}}/> <label for ='priority-high' style={{fontWeight:'600',fontSize:'16px',cursor:'pointer'}}>High</label> </a>
                              <a><input id = 'priority-medium' type='checkbox' name="Medium"       class='priority-checkbox' style={{marginRight: '10px' }}/> <span class='priority-div-filter' style={{backgroundColor: '#007bff', border:'1px solid #007bff'}}/> <label for ='priority-medium' style={{fontWeight:'600',fontSize:'16px',cursor:'pointer'}}>Medium</label> </a>
                              <a><input id = 'priority-low' type='checkbox' name="Low"             class='priority-checkbox' style={{marginRight: '10px' }}/> <span class='priority-div-filter' style={{backgroundColor: '#28a745', border:'1px solid #28a745'}}/> <label for ='priority-low' style={{fontWeight:'600',fontSize:'16px',cursor:'pointer'}}>Low</label> </a>
                            </div>  
                          <a onClick={selectPriority} style={{paddingLeft:'35%', cursor: 'pointer',fontWeight:'600',fontSize:'16px' }}>Done</a>
                        </div>
                    </div>                  
                    <div class="create-ticket-form-label" style={{ marginTop: "3%"}}>Status</div>
                    <div style={{position:'relative' }}>
                        <div id="input" contenteditable='false' style={{paddingTop:'10px',height:'36px',fontWeight:'500', fontSize:'18px', width: '90%', border:'1px solid black', borderRadius:'0px',verticalAlign: 'sub'}} onClick={displayStatus} >{selectedStatus}</div>
                        <div style={{visibility: 'hidden', position: 'absolute', overflowY:'hidden',width:'90%'}} class='create-ticket-filter-dropdown-content' ref={statusDropdownRef}>
                         <div style={{maxHeight:'200px', overflowY:'scroll',borderBottom:'1px solid #DAD9D2'}}>                            
                            <a><input id = 'status-open' type='checkbox' name="Open" class='status-checkbox' style={{marginRight: '10px' }}/> <label for ='status-open'> <span style={{fontWeight:'600',fontSize:'16px',cursor:'pointer'}}>Open</span></label> </a>
                            <a><input id = 'status-closed' type='checkbox' name="Closed" class='status-checkbox' style={{marginRight: '10px' }}/> <label for ='status-closed'> <span style={{fontWeight:'600',fontSize:'16px',cursor:'pointer'}}>Closed</span></label> </a>
                            <a><input id = 'status-inprogress' type='checkbox' name="inProgress" class='status-checkbox' style={{marginRight: '10px' }}/> <label for ='status-inprogress'> <span style={{fontWeight:'600',fontSize:'16px',cursor:'pointer'}}>InProgress</span></label> </a>
                            <a><input id = 'status-blocked' type='checkbox' name="Blocked" class='status-checkbox' style={{marginRight: '10px' }}/> <label for ='status-blocked'><span style={{fontWeight:'600',fontSize:'16px',cursor:'pointer'}}> Blocked</span></label> </a>
                            <a><input id = 'status-information' type='checkbox' name="Need Information" class='status-checkbox' style={{marginRight: '10px' }}/> <label for ='status-information'><span style={{fontWeight:'600',fontSize:'16px',cursor:'pointer'}}>Need Information</span></label> </a>
                         </div>
                          <a onClick={selectStatus} style={{paddingLeft:'25%', cursor: 'pointer' ,fontWeight:'600',fontSize:'16px'}}>Done</a>
                        </div>
                    </div>

                    <div class="create-ticket-form-label" style={{ marginTop: "3%"}}>First Response</div>
                    <div>
                        <select style={{width: "90%", height: '36px', border: 'solid 1px'}} name='first_response'>
                          <option>All</option>
                          <option>Breached</option>
                          <option>Not Breached</option>
                        </select>
                    </div>
                    <div class="create-ticket-form-label" style={{fontSize:'14px', marginTop: "3%"}}>Created Date</div>
                    <div>
                        <input type='date' name='createdFrom' style = {{width: "90%", borderRadius: '5px',  height: '36px', border: 'solid 1px'}}/> 
                        <div style={{width:'90%', marginTop:'3px', marginBottom:'3px', fontSize:'14px', fontWeight:'600',display:'flex',justifyContent:'center'}}>TO</div>
                        <input type='date' name='createdTo' style = {{width: "90%",  borderRadius: '5px', height: '36px', border: 'solid 1px'}}/>
                    </div>
                </div>                                
                <div ref={errorRef}/>
                <div style={{display:'flex', alignItems:'center', justifyContent:'center', position:'relative',bottom:'30px',height:'105px',width:'100%',margin:'auto'}}>
                  <button class='ss-button ticket-filter-button'  >Filter Tickets</button>
                </div>                
             </form>
        </div>
    )

}




export default TicketListFilterBox;