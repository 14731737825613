import React from "react"
import {useState, setState, useEffect, useRef} from 'react';
import {useNavigate} from "react-router-dom"
import * as axiosClient from '../../js/Axios';
import * as constants from '../../js/constants'
import Dropdown from 'react-bootstrap/Dropdown';
import '../../css/topnav.css'

function TopNavProfile(props){
	const dropdownMenuRef = useRef(null)

function logout(){
	axiosClient.postRequest(constants.USER_URL+'logoff')
			.then(function (response) {
		localStorage.removeItem('id_token')
    	localStorage.removeItem('access_token')
    	window.location.href='/'					
	}).catch(function (response) {
			    console.log(response);
	});
    
}

function displayStatus(event){
	console.log('displayStatus')
	if(dropdownMenuRef.current.style.display=== 'none')
		dropdownMenuRef.current.style.display='block'
	else
		dropdownMenuRef.current.style.display='none'
}

function setStatus(event, status){
	props.setStatus(event, status)
	document.getElementById('ssssss').click()
}
function displayChangePassword(event){
	props.displayChangePassword(true)
}

	return (
		<div class='user-profile-dropdown-menu' style={{display: 'none'}} ref={props.dropdownRef}>
			<div>
				<div class='user-profile-dropdown-item-menu' data-toggle="dropdown" role='group' style={{padding:'0px'}}>					
		          	<Dropdown drop='start'>			                               
	                    <Dropdown.Toggle  style={{textAlign:'left', fontWeight:'600', padding: '0px',margin:'5px', border: 'none', backgroundColor: 'white',color:'black',outline:'none'}} class='topnav-status-dropdown' id='ssssss'>
	                        <span>Status</span>
	                    </Dropdown.Toggle>                
	                    <Dropdown.Menu>
	                        <a class='sidenav-drop-down-item'  style={{color:'black', textDecoration:'none', display:'block'}} onClick={(event)=>setStatus(event,'online')}>Online</a>
	                        <a class='sidenav-drop-down-item'  style={{color:'black', textDecoration:'none', display:'block'}} onClick={(event)=>setStatus(event,'offline')}>Offline</a>
	                    </Dropdown.Menu>			                			                
		            </Dropdown>						
				</div>
				<div class='user-profile-dropdown-item-menu' onClick={props.displayUserProfileCreateForm} style={{paddingLeft:'20px'}}>Profile</div>
				<div class='user-profile-dropdown-item-menu' onClick={props.displayChangePassword} style={{paddingLeft:'20px'}}>Change Password</div>
				<div class='user-profile-dropdown-item-menu'onClick={logout} style={{paddingLeft:'20px'}}>Logout</div>
			</div>
		</div>
	);
}

export default TopNavProfile;