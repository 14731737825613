import React, { useEffect, useRef, useState } from 'react';


function TicketMergeSearchLine(props){
	


	return( <div style={{display : 'flex',flexDirection:'column',cursor:'pointer' }}>
                    <div><div style={{padding:'10px', float:'left',display: 'inline' }}> #{props.ticket.ticket_number}</div><div style={{padding:'10px',float:'right',display: 'inline' }}>{props.ticket.reporter_contact.name}</div></div>
                    <div style={{padding:'10px'}}>{props.ticket.summary} </div>
            </div>
)
}
export default TicketMergeSearchLine;