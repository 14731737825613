import React from "react"
import {useRef, useState, setState, useEffect} from 'react';
import {useNavigate} from "react-router-dom"
import ReactDOMServer from 'react-dom/server';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'


function IVRConditionLine(props){
	const dropdownRef = useRef(null)
	const valueNameRef = useRef(null)
	const lineRef = useRef(null)
	const digitRef = useRef(null)
	const hiddenInputRef = useRef(null)
	const [hiddenInputValue, setHiddenInputValue]=useState(null)
	const selectRef = useRef(null)
	const SELECT_POS = 1;
	const HIDDEN_INPUT = 3;
	const INPUT_POS=2
	let editFormFilled = false;
useEffect(()=>{
	if(props.element !== 1){
		editFormFilled = true
		valueNameRef.current.value = props.element.valueName
		if(props.element.select ==='EndCall'){
			valueNameRef.current.disabled = true
			valueNameRef.current.value = ''
		}
		 
		selectRef.current.value = props.element.select
		digitRef.current.value = props.element.digit
		hiddenInputRef.current.value = props.element.valueId
		setHiddenInputValue(props.element.valueId)
	}
},[])
function fetchValues(event){
	if(event.target.value.length < 3)
		return
	let urls = {'route' : constants.CALL_URL+'call_route/search?keyword='+event.target.value,
				'ivr' : constants.CALL_URL+'ivr?keyword='+event.target.value
			}		
	let selections={'CallRoute' : 'route',
					'Play Text as Voice': 'text_to_voice',
					'IVR': 'ivr',
					'Play Recording': 'play_recording',
					'EndCall' : 'end_call'
				}
	let selection = selectRef.current.value
	if(!['route', 'ivr'].includes(selections[selection]))
		return
	axiosClient.getRequest(urls[selections[selection]])
	.then(function (response) {
		dropdownRef.current.innerHTML = ''
		response.data.forEach((element, index)=>{
				addChildToDropdown(event, element)
        })
			
	}).catch(function (error) {
		console.log(error)
	    props.errorRef.current.innerHTML = error.response.data.error
	    props.errorRef.current.style.display = 'block'
	});
}

  function addChildToDropdown(event,element){
  	  
  	  lineRef.current.children[HIDDEN_INPUT].value = -1 // hidden input text
      let aTag = document.createElement('a')
      aTag.name = element.id
      aTag.innerHTML = element.name
      aTag.onclick= (event) =>onSelectValueDropdown(event, element.id, element.name)
      dropdownRef.current.appendChild(aTag)
  }

function onSelectValueDropdown(event, id, name){
  lineRef.current.children[3].value = id // hidden input text
  lineRef.current.children[2].children[0].value = name
  dropdownRef.current.innerHTML = null
}

function valueSelected(event){
	if(event.target.value === 'EndCall')
		valueNameRef.current.disabled = true
	else{
		valueNameRef.current.disabled = false
		valueNameRef.current.value = ''
		hiddenInputRef.current.value= -1
	}
}	
	return(
		<div style={{display:'flex', fontSize:'12px',marginTop:'10px',width:'100%'}} ref={lineRef}>
			<input name={'digit-'+props.index} style={{width:'50px', textAlign:'center'}} type='text' maxlength='2' placeholder={'Digit'} class='ivr-digit' ref={digitRef}/> 
			<select name={'select-'+props.index} style={{width:'120px', marginLeft:'30px'}} ref={selectRef} onChange={valueSelected}> <option>CallRoute</option><option>IVR</option><option>EndCall</option></select>
			<div class='create-ivr-input-dropdown-content'>
				<input ref={valueNameRef} name={'value-'+props.index} type='text' style={{minWidth:'320px',width:'95%'}} placeholder={'Search ...'} onChange={fetchValues} required/> 
				<div ref={dropdownRef} style={{position: 'absolute'}} class='create-ivr-input-dropdown-menu'/>
			</div>
			<input ref={hiddenInputRef} name={'hidden-'+props.index} type='text' style={{display:'none'}} value={hiddenInputValue}/>
		</div>		
	)


}

export default IVRConditionLine;