import React, {useState} from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../js/constants'

function IVRListDisplayHeader(props){
	const navigate = useNavigate(); 

function openIVR(id){
	let path = constants.CONTACT_URL_ABS +props.IVR.id ;
}

function createNewIVR(){
	navigate(constants.ADMIN_URL_ABS+'ivr/create')
}

return (
   <div class='header-body'>
	    <div class="main-header">
	    	 <div style={{fontWeight:'bold',paddingLeft:'10px'}}>IVR</div>
     		 <div class="search-wrapper">
   				{<img src={require('../../../img/search.png')} alt=''/>}
   				<input placeholder="search here" class="search-input" onChange={props.searchIVRs}/>
             </div>	    
	         <div class="header-right" ref={props.groupButtonsRef}>
	            <button class='ss-button'  type="button"  onClick={() => props.performAction('delete')}>Delete</button>	            
	         </div>
	         <button class='ss-button'  type="button" onClick={createNewIVR}>+New IVR</button>
	    </div>
	</div>
   );

}

export default IVRListDisplayHeader;