  import React from "react"
  import {useState, setState, useRef, useEffect} from 'react';
  import {useNavigate} from "react-router-dom"
  import * as axiosClient from '../../../js/Axios';
  import * as constants from '../../../js/constants'
  import '../../../css/main.css'
  import {over} from 'stompjs';
  
  import { io } from 'socket.io-client';
  import ChatUserMessage from './ChatUserMessage'
  import ChatCustomerMessage from './ChatCustomerMessage'
  import ChatMessageAttachment from './ChatMessageAttachment'
  import ChatMiddleBoxHeader from './ChatMiddleBoxHeader'

  const voice = require('@twilio/voice-sdk')

  function ChatMiddleWindow(){
      const chatBodyRef = useRef(null)
      const inputTextRef = useRef(null)
      const clientRef = useRef(null)
      var socket = null
      // const URL = process.env.NODE_ENV === 'production' ? undefined : 'http://localhost:3002';
      // const socket = io(URL);
      //     console.log("Inside "+localStorage.getItem('chat_uuid'))
      //     socket.on('connect', onConnect);
      //     socket.on('disconnect', onDisconnect);
      //     socket.on('message', onMessage);
      function onMessage(message){
          message = JSON.parse(message)
          console.log("Message on user side received "+message.type)
          if(message.type === 'welcome'){
       
          }else if(message.type === 'connect_to_user'){
             console.log(message)
          }
      }
      function onConnect(){ 
        var message = {"type": "user_connect", "user_id" : 1}
        socket.send(message)
      }

      function onDisconnect(){console.log("onDisconnect")}

      function onClickEvent(){
          var token = ((localStorage.getItem("chat_token") == null) ? "" : localStorage.getItem("chat_token"))
          var conversationId = ((localStorage.getItem("conversation_id") == null) ? 0 : localStorage.getItem("conversation_id"))
          var message = inputTextRef.current.value 
          socket.send({"type": "chat","content": message, "chat_token": token, 'conversation_id': conversationId});
      }
              

      function onChangeEvent(event){
          console.log(event.which)
          if(event.which === 13 )
              onClickEvent()
      }

      return( 
              
             <div class = 'Middle-Box Middle-Chat middle-chat-height' >
              {/*<ChatMiddleBoxHeader /> */}

              <div class="chat-wrapper ChatMyMessagewrp">
 <div class="chat-wrapper-items">
   <ChatUserMessage text = "Hi David, have you got the project report pdf?"/>
     <ChatCustomerMessage text="No i did not get it!"/>
   <ChatUserMessage text = "ok i will just sent it<br /> plz be sure to fill the details<br /> by today end of the day."/>
     <ChatCustomerMessage text="Hi David, have you got the project report pdf"/>
   <ChatMessageAttachment />  
 </div>

              <div class="chatbibox-wrapper chat-footer">
 <div class="chatbox-input">
   <input type="text" placeholder="Write something..." />
   <div class="mic-icon" ><img src={require('../../../img/mic.png')} alt=''/></div>
   <ul>    
     <li><img class="chatbox-attachment-img" src={require('../../../img/attachment.png')} /></li>
     <li><img class="chatbox-attachment-img" src={require('../../../img/attachment.png')} /></li>
     <li><img class="chatbox-attachment-img" src={require('../../../img/attachment.png')} /></li>
   </ul>
 </div>
 <div class="chatbibox-footer">   
   <button class='ss-button'  class="send"><img src={require('../../../img/paper-plane.png')}/> </button>
 </div>
              </div>

              </div>
            </div>

              
          )

  }

  export default ChatMiddleWindow;