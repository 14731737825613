import React, { useState, useEffect, useRef,createContext } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import DepartmentLine from "./DepartmentLine"
import DepartmentListDisplayHeader from './DepartmentListDisplayHeader'
import DepartmentPagination from './DepartmentPagination'
import CreateDepartmentForm from './CreateDepartmentForm'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function DepartmentsListDisplay(){
	const [sessionData, setSessionData] = useState(null)
	const [pageNo, setPageNo] = useState(0)
	const [pageSize, setPageSize] = useState(100)	
	const [departmentLine, setDepartmentLine] = useState("")
	const editDepartmentRef = useRef(-1)
	const primaryCheckBoxRef = useRef(null)
	const [ids, setIds] = useState([])
	const groupButtonsRef = useRef(null)	
	const [checkboxState, setCheckboxState] = useState(false)
	const [showCreateDepartmentForm, setShowCreateDepartmentForm] = useState(false)
	
	useEffect(() => {
		loadDepartments();
		setSessionData(JSON.parse(sessionStorage.getItem('ss_sess')))
		groupButtonsRef.current.style.visibility = 'hidden'
	},[])//use effect

function loadDepartments(){
	axiosClient.getRequest(constants.TEAM_URL+'?pageNo='+pageNo+'&pageSize='+pageSize)
	.then(function (response) {
		let departmentList = null;
		departmentList = response.data.map((element, index) => (									
						<DepartmentLine department= {element} editDepartment={editDepartment} checkBoxClicked={checkBoxClicked}/>
		));
		setDepartmentLine((Object.keys(departmentList).length === 0  ? 'No Records Found...' : departmentList))
	}).catch(function (response) {
	    console.log(response);
	});	
}
	function selectMultipleDepartments(){
		let elements = document.getElementsByClassName('department-checkbox')
		for (var i = 0; i < elements.length; i++) {
			elements[i].style.visibility = 'visible'
		}
	}

function onPrimaryCheckboxClicked(event){
	let idsTemp = ids;
	let checkBoxes = document.getElementsByClassName('department-line-checkbox')
	for(var i=0;i<checkBoxes.length;i++){
		checkBoxes[i].checked = event.target.checked
		let id = checkBoxes[i].name.split('-')[1]
		if(event.target.checked && !idsTemp.includes(id)){
			idsTemp.push(id)
			groupButtonsRef.current.style.visibility = 'visible'				
		}
	}
	if(!event.target.checked){	
		groupButtonsRef.current.style.visibility = 'hidden'
		idsTemp.splice(0,idsTemp.length)
	}
	setIds(idsTemp)
}

function checkBoxClicked(id, value){
	console.log('checkBoxClicked '+value)
	let idsTemp = ids;
	if(value){
		idsTemp.push(id)
	}else{
		 const index = idsTemp.indexOf(id);
		if (index > -1) {
  			idsTemp.splice(index, 1);
		}
	}
	setIds(idsTemp)
	if(idsTemp.length !== 0){		
		groupButtonsRef.current.children[0].style.visibility = 'visible'
	}else{
		groupButtonsRef.current.children[0].style.visibility = 'hidden'
	}
	if(!value){
		primaryCheckBoxRef.current.checked = false
	}
}
	function createDepartmentGroup(){
		let departmentsChecked = []
		let elements = document.getElementsByClassName('department-checkbox')
		for (var i = 0; i < elements.length; i++) {
			if(elements[i].checked)
				departmentsChecked.push(elements[i].id)
		
		}

		axiosClient.getRequest(constants.TEAM_URL)
		.then(function (response) {
		let departmentList = null;
		departmentList = response.data.map((element, index) => (
						<DepartmentLine department= {element} editDepartment={editDepartment} checkBoxClicked={checkBoxClicked}/>					           
		));
		setDepartmentLine((Object.keys(departmentList).length === 0  ? 'No Records Found...' : departmentList))
		console.log("Department line set")
		}).catch(function (response) {
		    console.log(response);
		});		
	}

	function oncheckboxClicked(){
		setCheckboxState(!checkboxState)
	}

function searchDepartments(event){
	let pageNo=0;
	let pageSize=10;	
	axiosClient.getRequest(constants.TEAM_URL+"search?keyword="+encodeURIComponent(event.target.value)+'&pageNo='+pageNo+'&pageSize='+pageSize)
	.then(function (response) {
	let departmentList = null;
	departmentList = response.data.map((element, index) => (					
					<DepartmentLine department= {element} index={index} editDepartment={editDepartment} checkBoxClicked={checkBoxClicked}/>					           
	));
	console.log("DepartmentList "+ JSON.stringify(departmentList))
	setDepartmentLine((Object.keys(departmentList).length === 0  ? 'No Records Found...' : departmentList))
	}).catch(function (response) {
	    console.log(response);
	});	
}
function filterDepartments(type){
	axiosClient.getRequest(constants.TEAM_URL+"filter?type="+type)
		.then(function (response) {
		let departmentList = null;
		departmentList = response.data.map((element, index) => (					
						<DepartmentLine department= {element} editDepartment={editDepartment} checkBoxClicked={checkBoxClicked}/>					           
		));
		console.log(" departmentList "+ (departmentList))
		console.log("DepartmentList "+ JSON.stringify(departmentList))
		setDepartmentLine((Object.keys(departmentList).length === 0  ? (<tr><td></td><td>No Records Found...</td></tr>) : departmentList))
		}).catch(function (response) {
		    console.log(response);
		});	
}

function showCreateConactFormFun(){
	setShowCreateDepartmentForm(true)
}
function closeCreateWindow(){
	editDepartmentRef.current = -1
	setShowCreateDepartmentForm(false)
}
function performAction(type){
	let payload = {'ids': ids}
	let url = null;
	if(type === 'delete')
		url = constants.TEAM_URL+'delete';
	else if(type === 'block')
		url = constants.TEAM_URL+'block';
	else if(type === 'spam')
		url = constants.TEAM_URL+'spam';

	//TODO: block becomes unblock
   	axiosClient.postRequest(url, payload)
       .then(function (response) {
           loadDepartments();
		let elements = document.getElementsByClassName('contact-list-checkbox')
		for (var i = 0; i < elements.length; i++) {
			if(elements[i].checked)
				elements[i].checked = false;		
		} 
		toast.success("Departments Deleted Successfully")          
       }).catch((error) => {
           console.log(error)
       });
	groupButtonsRef.current.children[0].style.visibility = 'hidden'           
}	
function editDepartment(id){
	editDepartmentRef.current = id
	setShowCreateDepartmentForm(true)
}

	return (
	<>
		<DepartmentListDisplayHeader searchDepartments={searchDepartments} 
							   filterDepartments={filterDepartments}
							   performAction={performAction}
							   showCreateConactFormFun={showCreateConactFormFun}
							   groupButtonsRef={groupButtonsRef}/>
		<div class="main-body">
	   		<div class="page-body" >
	   	 	{/*  <DepartmentListFilterBox filterBoxRef={filterBoxRef} handleSubmit={handleSubmit}/>   */}
			   
			   <table class='table' style={{fontSize: '12px', left:'2%', width: '96%',position: 'absolute'}}>
			      <thead>
			         <tr style={{backgroundColor: 'rgb(235, 237, 240)'}}>
			         { (sessionData !== null && sessionData.isAdmin) ?   <td style={{backgroundColor: 'rgb(235, 237, 240)', color:'black', fontSize:'14px',fontWeight:'bold', width:'2%'}}><input type="checkbox" value="" onClick={onPrimaryCheckboxClicked} ref={primaryCheckBoxRef}/></td> : '' }
			            <td style={{backgroundColor: 'rgb(235, 237, 240)', color:'black', fontSize:'14px',fontWeight:'bold'}}>Name</td>
			            <td style={{backgroundColor: 'rgb(235, 237, 240)', color:'black', fontSize:'14px',fontWeight:'bold'}}>Number Of Teams</td>		           
			         </tr>
			      </thead>
			      <tbody>
						{departmentLine}
			      </tbody>
			   </table>
			   <DepartmentPagination />	   	
			</div>

			{ showCreateDepartmentForm ? <CreateDepartmentForm closeCreateWindow={closeCreateWindow}
					editDepartmentRef={editDepartmentRef}/> : '' }
		</div>
	</>
 );

}
export default DepartmentsListDisplay;
