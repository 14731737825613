import React from "react"
import {useState, setState, useEffect, useRef} from 'react';
import {useNavigate} from "react-router-dom"
import * as axiosClient from '../../js/Axios';
import * as awsProfile from '../../js/awsProfile';
import * as constants from '../../js/constants';
import '../../css/topnav.css'
import '../../css/common.css'
import TopnavProfile from './TopnavProfile'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ProfileChangePassword(props){
	const errorRef = useRef(null)
	const spinnerRef = useRef(null)

useEffect(()=>{
	spinnerRef.current.style.display='none'
	errorRef.current.style.display='none'
})
function displayError(msg){
  errorRef.current.innerHTML = "<span style='color:red'>*&nbsp;&nbsp;"+msg+"</span>"
  errorRef.current.style.display='block'
  errorRef.current.scrollIntoView()
  spinnerRef.current.style.display='none'
}
function changePassword(event){
	event.preventDefault()
	errorRef.current.innerHTML = ''
	if(event.target.new_password.value !== event.target.new_password_2.value){
		displayError('New passwords does not match')
		return
	}
	spinnerRef.current.style.display='inline-block'
	const currentUser = awsProfile.GetAuthenticatedUser();
	if(currentUser){
		spinnerRef.current.style.display='block'
			currentUser.getSession(function (err, session) {
                if (err) {
                    displayError('Unexpected error.... Please login again and retry')
                } else {
                    currentUser.changePassword(event.target.old_password.value, 
                    				event.target.new_password.value, function (err,res) {
                        if (err) {
                        	if(err.code === 'NotAuthorizedException')
                        		return displayError('Incorrect Password')
                        	else if (err.code === 'InvalidPasswordException')
                        		return displayError('Invalid Password')
                        } else {
                            toast.success('Password changed')
                            spinnerRef.current.style.display='none'
                            props.hideChangePassword()
                            spinnerRef.current.style.display='none'
                        }
                    });
                }
            });
	}else{
		displayError('Unexpected error.... Please login again and retry')
	}
}

	return (
		    <div class="create-wrapper" ref={props.changePasswordRef} style={{display: 'none' }}>    
		    	<a href="javascript:void(0)" class="create-closebtn" onClick={props.hideChangePassword}>&times;</a>
	              <h8><b>Change Password</b> </h8>
    	          <div ref={errorRef} style={{fontWeight: 'bolder', fontSize:'16px'}} class='create-error'/>              
        	      <div class='create-line'></div>			   
	      			<form onSubmit={changePassword} style={{marginTop:'5%'}}>
	        	      <div>
	        	      	  <div class='create-form-label'>Current Password</div>
				          <input style={{width: "90%", paddingLeft:'10px',fontWeight:'500', fontSize:'16px'}} type="password" required name='old_password'/>			          
				      </div>
				      <div>
				      	  <div class='create-form-label' style={{marginTop:'25px'}}>New Password</div>
				          <input style={{width: "90%", paddingLeft:'10px',fontWeight:'500', fontSize:'16px'}} type="password" required name='new_password'/>
				       </div>
						<div>
						  <div class='create-form-label' style={{marginTop:'25px'}}>Confirm New Password</div>
				          <input style={{width: "90%", paddingLeft:'10px',fontWeight:'500', fontSize:'16px'}} type="password" required name='new_password_2'/>			        			          
				        </div>      			
						<button class='ss-button'  class= 'create-button'>Change Password</button>
						<span class="spinner-border" role="status" style={{position:'relative',right:'100px',top:'5px', color: 'black', height:'25px', width:'25px',zIndex:'100'}} ref={spinnerRef}/>						
	          		</form>
          			<div ref={errorRef} style={{fontSize:'14px'}}/>
          		</div> 

	);
}

export default ProfileChangePassword;