  import React from "react"
  import {useState, setState, useRef, useEffect} from 'react';
  import {useNavigate} from "react-router-dom"
  import * as axiosClient from '../../../js/Axios';
  import * as constants from '../../../js/constants'
  import '../../../css/main.css'
  import ChatProfile from './ChatProfile'
  import ChatAgentInfo from './ChatAgentInfo'

  const voice = require('@twilio/voice-sdk')

  function ChatLeftWindow(props){
      const [clickedProfileId, setClickedProfileId] = useState(-1)
      const  [chatProfileData, setChatProfileData] = useState(-1)
      const chatBodyRef = useRef(null)
      const inputTextRef = useRef(null)
      const clientRef = useRef(null)


      function chatProfileClicked(id){
        setClickedProfileId(id)
      }
      useEffect(() => {
        console.log("useEffect ChatLeftWindow ")
        // let chatProfileList = props.data.map((element, index) => (         
        //     <ChatProfile data = {element} chatProfileClicked={chatProfileClicked} clickedProfileId={clickedProfileId} id={element.id}/>
        // ));
        // console.log(chatProfileList)
        // setChatProfileData(chatProfileList)
      },[])//use effect

      return( 
            <div class ="chat-left-box left-chat chat-bg">
              <ChatAgentInfo />
              <div class="chat-search-wrapper">
 <div style= {{position: "relative" }}>
   <img src={require('../../../img/search.png')} alt=''/>
   <input placeholder="search here" class="search " />
 </div>
              </div>

              <div class="chat-listing">
 {props.data.map((element, index) => (         
     <ChatProfile data = {element} chatProfileClicked={chatProfileClicked} clickedProfileId={clickedProfileId} id={element.id}/>
   ))
 }
              </div>
            </div>
              
          )

  }

  export default ChatLeftWindow;