import React from "react"
import {useState, useRef, useEffect} from 'react';
import * as axiosClient from '../../../../js/Axios';
import * as constants from '../../../../js/constants'
import useRazorpay from "react-razorpay";
import '../css/subscription.css'
import StandardPlanCard from './StandardPlanCard'
import { toast, ToastContainer } from 'react-toastify';
import RenewSubscription from './RenewSubscription'
import AddLicenses from './AddLicenses'
import AddCallCredits from './AddCallCredits'
import Table from 'react-bootstrap/Table';
import AddAddress from './AddAddress'
function Subscription(){

	const CALCULATE_COST='Calculate Cost'
	const [transactionId, setTransactionId] = useState(null)
	const [hideRenewSubscription, setHideRenewSubscription] = useState(false)
	const [hideAddLicenses, setHideAddLicenses] = useState(null)
	const [showAddCallCredits, setShowAddCallCredits] = useState(false)	
	const [renewButtonValue, setRenewButtonValue] = useState(CALCULATE_COST)
	const [plan, setPlan] = useState(null)	
	const creditsInputRef = useRef(null)
	const [licensePayload, setLicensePayload] = useState(null)
	const [subscriptionPayload, setSubscriptionPayload] = useState(null)
	const callCreditsErrorRef = useRef(null)
	const [sessionData, setSessionData] = useState(null)
	const spinnerRef = useRef(null)
	const renewButtonRef = useRef(null)
	const [subscription, setSubscription] = useState(null)
	const [Razorpay] = useRazorpay();
	const errorRef = useRef(null)
	const billingCycleRef = useRef(null)
	const actionTypeRef = useRef(null)
	const numberOfLicensesRef = useRef(null)
	const totalCostRef = useRef(null)
	const renewTotalCostRef = useRef(null)
	const subscriptionExpired = useRef(false)
	const toastRef = useRef(null)
	const [showAddress, setShowAddress] = useState(false)
	const addressType = useRef(null)
	

	function setupPaddle(){  
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
  	  		window.Paddle.Environment.set("sandbox");
	      window.Paddle.Initialize({ 
	        token: process.env.REACT_APP_PADDLE_CLIENT_TOKEN,
	        eventCallback: function(data) {
	        if (data.event === "Checkout.Complete") {
	            checkoutCompleteCallback(data)
	        }
	        else if (data.name === "checkout.closed") {
	          checkoutClosedCallback(data)
	        }else if (data.name === "checkout.error") {
	          toastRef.current.innerHTML = 'An error occured while processing this payment. Please try again...'
	          toastRef.current.style.display = 'none'
	          setTimeout(hideToast, 5000)
	        }
	      }
	      });
  	}
	useEffect(()=>{
		setupPaddle()
		document.body.disabled = true
		toastRef.current.style.display = 'none'
		spinnerRef.current.style.display = 'none'
		axiosClient.getRequest(constants.ACCOUNT_URL+'subscription')
		.then(function (response) {
			setSubscription(response.data)
			if(response.data.subscriptionExpired){
				subscriptionExpired.current=true
			}
		}).catch(function (response) {
		    console.log(response);
		    toast.error('Unable to process the request. Please try again after sometime')
		})
	    let ssData = JSON.parse(sessionStorage.getItem('ss_sess'))
	    setSessionData(ssData)
	    if(ssData.trial && ssData.trialExpired)         {
        	toastRef.current.innerHTML = 'Trial period expired... Please subsctibe to plan'
        	toastRef.current.style.display = 'flex'
	    }
	    if(subscriptionExpired.current){
        	toastRef.current.innerHTML = 'Subscription expired. Please renew your plan'
        	toastRef.current.style.display = 'flex'		    	
	    }
	},[])

	useEffect(()=>{
	    const urlParams = new URLSearchParams(window.location.search);
	    if(urlParams.get("cancel") !== null){
	    	return
	    }
	    if((urlParams.get("return") !== null)){	    
	    	let payload={id: urlParams.get("id"), type: 'return'}
			axiosClient.postRequest('payments/payment_summary/capture',payload)
			.then(function (response) {
				// processOrderStatus(response.data.orderId)
			}).catch(function (response) {
			    console.log(response);			    
			    spinnerRef.current.style.display = 'none'
			})

	    }		
	},[])

function checkoutCompleteCallback(data){
	console.log("checkoutCompleteCallback "+data.eventData); 
}
function checkoutClosedCallback(data){
	window.location.reload()
}

function processOrderStatus(orderId){
		axiosClient.getExternalRequest('https://api-m.sandbox.paypal.com/api/orders/'+orderId+'/capture')
		.then(function (response) {			
			const errorDetail = response?.data?.details
              if (errorDetail) {
                  let payload={id: transactionId}
                  axiosClient.postRequest(constants.PAYMENT_URL+'fail',payload)
					.then(function (response) {
						spinnerRef.current.style.display = 'none'
					}).catch(function (response) {
					    console.log(response);
					    spinnerRef.current.style.display = 'none'
					    toast.error('Unable to process the request. Please try again after sometime')											
				  });			
		  		  
              } else {
                //success
                let payload={id: transactionId}
                sendSuccessRequest(payload)
              }
		}).catch(function (response) {
		    console.log(response);			    
		    spinnerRef.current.style.display = 'none'
		})
}

function openPaddleCheckout(orderId){
	window.Paddle.Checkout.open({	
		settings:{
			displayMode: 'overlay',
			locale: 'en'
		},
	    transactionId: orderId
	  });
}
function createOrder(payload){
	spinnerRef.current.style.display = 'block'
    axiosClient.postRequest('payments/create_order',payload)
		.then(function (response) {
			if(response.data.amount === 0){
				sendSuccessRequest(payload)
				return
			}
			
			if(response?.data?.transactionId === undefined || response?.data?.transactionId === null)
				return displayError('Unexpected error while processing.... Please try again after some time')
			openPaddleCheckout(response.data.transactionId)
			// window.location.href=response.data.link
			// handlePayment(response.data, payload)
			// spinnerRef.current.style.display = 'none'
		}).catch(function (response) {
		    console.log(response);
		    displayError('Unable to process the request. Please try again after sometime')
		    spinnerRef.current.style.display = 'none'
		})
}

  const handlePayment = async (order, payload) => {
  				axiosClient.postExternalRequest('https://api-m.sandbox.paypal.com/api/orders/'+order.orderId+'/capture',payload)
					.then(function (response) {
						window.location.href=response.data.href
						spinnerRef.current.style.display = 'none'
						const errorDetail = response.data?.details?.[0];
						  if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
			                // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
			                // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
			                // return actions.restart();
			              } else if (errorDetail) {
			                // (2) Other non-recoverable errors -> Show a failure message
			                throw new Error(
			                  `${errorDetail.description} (${response.data.debug_id})`
			                );
			              } else {
			                // (3) Successful transaction -> Show confirmation or thank you message
			                // Or go to another URL:  actions.redirect('thank_you.html');
			                const transaction =
			                  response.data.purchase_units[0].payments.captures[0];
			                // setMessage(
			                //   `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`
			                // );
			                console.log(
			                  "Capture result",
			                  response.data,
			                  JSON.stringify(response.data, null, 2)
			                );
			              }
					}).catch(function (response) {
					    console.log(response);
					    spinnerRef.current.style.display = 'none'
					    toast.error('Unable to process the request. Please try again after sometime')											
					});	

   return
	let amount = order.amount;
	let companyName = subscription.companyName;
	let ownerName = subscription.accountOwnerName
	let email = subscription.email
	let mobileNumber = (subscription.mobileNumber === undefined) ? '' : subscription.mobileNumber  	
	let payloadObject = payload
	
		  const options = {
		    key: order.keyId,
		    amount:  amount,
		    currency: "USD",
		    name: "SupportSPOC",
		    description: "Payment from "+companyName,
		    image: "https://example.com/your_logo",
		    order_id: order.orderId,
		    handler: function (response) {
		    	spinnerRef.current.style.display = 'block'
			    let payload = {
			    	razorSignature: response.razorpay_signature,
			    	razorPayOrderId: response.razorpay_order_id,
			    	razorPaymentId: response.razorpay_payment_id,
			    	amount: amount,
			    	action: payloadObject['action'],
			    	numberOfLicenses: payloadObject['numberOfLicenses'],
			    	billingCycle: payloadObject['billingCycle'],
			    	plan: payloadObject['plan']
			    }
				sendSuccessRequest(payload)		  		      
		    },
		    prefill: {
		      name: ownerName,
		      email: email,
		      contact: mobileNumber,
		    },
		    notes: {
		      address: "Razorpay Corporate Office",
		    },
		    theme: {
		      color: "#3399cc",
		    },
		  };
		  let rzp1 = new Razorpay(options);
		  rzp1.on("payment.failed", function (response) {
		  	spinnerRef.current.style.display = 'block'
		    let payload = {
		    	errorCode: response.error.code,
		    	errorDescription: response.error.description,
		    	errorSource: response.error.source,
		    	errorStep: response.error.step,
		    	errorReason: response.error.reason,
		    	razorPayOrderId: response.error.metadata.order_id,
		    	razorPaymentId: response.error.metadata.payment_id,
		    	amount: amount,
		    	action: payloadObject.action,
		    	numberOfLicenses: payloadObject['numberOfLicenses'],
		    	billingCycle: payloadObject['billingCycle']
		    }
		    axiosClient.postRequest(constants.PAYMENT_URL+'fail',payload)
					.then(function (response) {
						spinnerRef.current.style.display = 'none'
					}).catch(function (response) {
					    console.log(response);
					    spinnerRef.current.style.display = 'none'
					    toast.error('Unable to process the request. Please try again after sometime')											
					});			
		  });

	  	rzp1.open();
	  };

function sendSuccessRequest(payload){
    axiosClient.postRequest(constants.PAYMENT_URL+'success',payload)
		.then(function (response) {
			if((sessionData.trial && sessionData.trialExpired) ||
			   (subscription.accountStatus.toLowerCase() !== "active")){
				sessionStorage.removeItem('ss_sess')
				window.location.reload()
			}
			setSubscription(response.data)
			spinnerRef.current.style.display = 'none'
		}).catch(function (response) {
			    console.log(response);
			    spinnerRef.current.style.display = 'none'
		});		
}
function hideAddress(){
	addressType.current = null
	setShowAddress(false)
}


function selectPlan(plan){
	setPlan(plan)
	setHideRenewSubscription(true)
}

function hideRenewSubscriptionWindow(){
	setHideRenewSubscription(false)
	spinnerRef.current.style.display = 'none'
}

function hideToast(){
	toastRef.current.style.display='none'
}
function openAddLicnesesWindow(){
	setHideAddLicenses(true)
}
function closeAddLicnesesWindow(){
	setHideAddLicenses(false)
	spinnerRef.current.style.display = 'none'
}
function openAddCallCreditsWindow(){
	setShowAddCallCredits(true)
}
function closeAddCallCreditsWindow(){
	setShowAddCallCredits(false)
	spinnerRef.current.style.display = 'none'
}

function addShippingAddress(){
	addressType.current = 'Shipping'
	setShowAddress(true)
}
function addBillingAddress(){
	addressType.current = 'Billing'
	setShowAddress(true)
}
function displayError(msg){
  errorRef.current.innerHTML = "<span style='color:red'>*&nbsp;&nbsp;"+msg+"</span>"
  errorRef.current.style.display='block'
  errorRef.current.scrollIntoView()
}
  const passthrough: PaddlePassthrough = {
    userId: 1,
  };

function checkout(event){
	event.preventDefault();
}

function fetchBillingAddress(){
	let address = JSON.parse(subscription.shippingAndBillingAddress)
	if(Object.keys(address.billingAddress).length !== 0){
		return <div><p>{address.billingAddress.firstName} {address.billingAddress.lastName},</p>
					<p>{address.billingAddress.address}</p>
					<p>{address.billingAddress.city}, {address.billingAddress.state}</p>
					<p>{address.billingAddress.country},  {address.billingAddress.zipcode}</p>
				</div>
			
	}
}
function fetchShippingAddress(){
	let address = JSON.parse(subscription.shippingAndBillingAddress)
	if(Object.keys(address.shippingAddress).length !== 0){
		return <div><p>{address.shippingAddress.firstName} {address.shippingAddress.lastName},</p>
					<p>{address.shippingAddress.address}</p>
					<p>{address.shippingAddress.city}, {address.shippingAddress.state}</p>
					<p>{address.shippingAddress.country},  {address.shippingAddress.zipcode}</p>
				</div>
			
	}
}
	return(
		<>
		 <div class='header-body' style={{backgroundColor: 'white'}}>
		 	<div style={{ marginTop:'30px', color:'#044caf',fontWeight:'bolder', fontSize:'16px',marginLeft:'30px' }}>Subscription</div>
		 </div>
		  <div class="main-body" >
		    <div class='container' style={{maxHeight:'100%', overflowY:'auto'}}>		  
				<div  class='row'>
				  <div class='col-sm-12 col-md-12 col-lg-6'>	
					<div style={{  fontSize:'28px', fontWeight: '800',margin: 'auto',display: 'table' ,marginTop:'20px', color:'#044caf'}}>Account Details: </div>					
						<Table striped responsive class='subscription-table'> 
							<tbody>
							<tr>
							  <td style={{ height:'40px', fontSize:'18px',fontWeight:'600',color:'#044caf'}}>Email</td>
							  <td style={{fontSize:'18px',fontWeight:'400'}}>{(subscription === null) ? '' :subscription.email}</td>
							</tr>
							<tr>
							  <td style={{ height:'40px', fontSize:'18px',fontWeight:'600',color:'#044caf'}}>Company Name</td>
							  <td style={{fontSize:'18px',fontWeight:'400'}}>{(subscription === null) ? '--' :subscription.companyName}</td>
							</tr>
							<tr>
							  <td style={{height:'40px', fontSize:'18px',fontWeight:'600',color:'#044caf'}}>Account Owner Name</td>
							  <td style={{fontSize:'18px',fontWeight:'400'}}>{(subscription === null) ? '--' :subscription.accountOwnerName}</td>
							</tr>
							<tr>
							  <td style={{height:'40px', fontSize:'18px',fontWeight:'600',color:'#044caf'}}>Number</td>
							  <td style={{fontSize:'18px',fontWeight:'400'}}>{(subscription === null ||subscription.mobileNumber === undefined) ? '--' : subscription.mobileNumber}</td>
							</tr>
							<tr>
							  <td style={{height:'40px', fontSize:'18px',fontWeight:'600',color:'#044caf'}}>Account Status</td>
							  <td style={{fontSize:'18px',fontWeight:'400'}}>{(subscription === null) ? '--' : subscription.accountStatus}</td>
							</tr>
							<tr>
							  <td style={{height:'40px', fontSize:'18px',fontWeight:'600',color:'#044caf'}}>Plan</td>
							  <td style={{fontSize:'18px',fontWeight:'400'}}>{(subscription === null) ? '--' : subscription.plan}</td>
							</tr>						
							<tr>
							  <td style={{height:'40px',  fontSize:'18px',fontWeight:'600',color:'#044caf'}}>Next Renewal At:</td>
							  <td style={{fontSize:'18px',fontWeight:'400'}}>{(subscription === null) ? '--' : subscription.nextRenewalDate}</td>
							</tr>						
							<tr>
							  <td style={{ height:'70px',  fontSize:'18px',fontWeight:'600',color:'#044caf'}}>Call Credits:<div></div></td>
							  <td style={{fontSize:'18px',fontWeight:'400'}}>
							  	<span >{(subscription === null) ? '--' : subscription.callCredits}</span>						  
							  	<a style={{fontSize:'14px', fontWeight:'800',float: 'right', color:'#044caf',cursor:'pointer'}} onClick={openAddCallCreditsWindow}>Add Call Credits</a>	
							  </td>						  
							</tr>						
							<tr>
							  <td style={{height:'40px',  fontSize:'18px',fontWeight:'600',color:'#044caf'}}>Number of Agents:</td> 
							  <td style={{fontSize:'18px',fontWeight:'400'}}>
							  	<div>{(subscription === null) ? '--' : subscription.numberOfAgents}</div>
							  </td>						  
							</tr>						
							<tr>
							  <td style={{height:'70px',  fontSize:'18px',fontWeight:'600', minHeight:'50px',color:'#044caf'}}>Number of Agent Licenses:</td>
							  <td style={{fontSize:'18px',fontWeight:'400'}}>
							  	{(subscription === null) ? '--' : subscription.numberOfLicenses}
							  	<a style={{cursor:'pointer', fontWeight:'800', fontSize:'14px',float: 'right', color:'#044caf' }}  onClick={openAddLicnesesWindow}>Manage Licenses</a>	
							  </td>						  
							</tr>
							<tr>
							  <td style={{height:'70px',  fontSize:'18px',fontWeight:'600', minHeight:'50px',color:'#044caf'}}>Shipping Address:</td>
							  <td style={{fontSize:'18px',fontWeight:'400'}}>
							  	{(subscription === null) ? '--' : fetchShippingAddress()}
							  	<a style={{cursor:'pointer', fontWeight:'800', fontSize:'14px',float: 'right', color:'#044caf' }}  onClick={addShippingAddress}>Add Address</a>	
							  </td>						  
							</tr>
							<tr>
							  <td style={{height:'70px',  fontSize:'18px',fontWeight:'600', minHeight:'50px',color:'#044caf'}}>Billing Address:</td>
							  <td style={{fontSize:'18px',fontWeight:'400'}}>
							  	{(subscription === null) ? '--' : fetchBillingAddress()}
							  	<a style={{cursor:'pointer', fontWeight:'800', fontSize:'14px',float: 'right', color:'#044caf' }}  onClick={addBillingAddress}>Add Address</a>	
							  </td>						  
							</tr>																				
							</tbody>					
						</Table>					
					<div><button class='ss-button' style={{margin:'auto', display:'table', padding:'5px 10px',width:'150px' }} onClick={checkout}>Cancel Account </button></div>					
				  </div>
				  <div class='col-sm-12 col-md-12 col-lg-6'>
						<section id="pricing" class="mt_80">
						    <div class="d-flex justify-content-center" style={{alignItems: 'center', width:'100%'}}>
						    	{subscription !== null ?
						    		
								    	<StandardPlanCard subscription={subscription} selectPlan={selectPlan}/>
								   
								    	: ''
						    	}
						   </div>
						</section>				  
				  </div>
				</div>
			</div>
		</div>
		<div class='toaster' ref={toastRef} style={{ textAlign:'center'}} />
		<div class="overlay" ref={spinnerRef}>
			<span class="spinner-border spinner-center" role="status" style={{height:'50px', width:'50px'}} />
		</div>
		{((subscription !== null) && hideRenewSubscription )? <RenewSubscription subscription={subscription}
									createOrder={createOrder}
									spinnerRef={spinnerRef} 
									plan={plan} 
									hideRenewSubscriptionWindow={hideRenewSubscriptionWindow}
									sendSuccessRequest={sendSuccessRequest}/> : ''}
		{((subscription !== null) && hideAddLicenses )? <AddLicenses subscription={subscription}
									createOrder={createOrder}
									spinnerRef={spinnerRef} 
									closeAddLicnesesWindow={closeAddLicnesesWindow}
									sendSuccessRequest={sendSuccessRequest}/> : ''}
		{showAddCallCredits ? 									
			<AddCallCredits subscription={subscription}
						createOrder={createOrder}
						closeAddCallCreditsWindow={closeAddCallCreditsWindow}/>									
		 : ''
		}

{ showAddress ? <AddAddress hideAddress={hideAddress} addressType={addressType}
				 address={subscription.shippingAndBillingAddress}/> : '' }		
		<ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}
                newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss
                draggable pauseOnHover theme="colored"/>		          
	</>
	)
}

export default Subscription;