import React, { useEffect, useRef, useState} from 'react';
import ReactDOMServer from 'react-dom/server';
import * as axiosClient from "../../../../js/Axios"
import * as constants from '../../../../js/constants'
import * as Util from '../../../../js/Util'
import {useNavigate, useParams} from "react-router-dom"
import * as timezones from "../../../../js/timezones"

function CreateBusinessCalenderForm(props){  
  const errorRef = useRef(null)
  const [timezonesArr, setTimezonesArr] = useState([])
  const holidaysRef = useRef(null)
  const nameRef = useRef(null)
  const hoursCheckBoxRef = useRef(null)
  const timezoneRef = useRef(null)
  const navigate = useNavigate()
  let editFormFilled = false;
  let businessCalenderId = -1;
  const params = useParams();
  
  let days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday','Sunday']

  useEffect(() => {
    populateTimezones()
    errorRef.current.style.display = 'none'    
    if(params.id !== undefined && !editFormFilled){
      editFormFilled = true;
      businessCalenderId = params.id !== undefined      
      fillEditForm(); 
    }else{      
      let checkboxes = document.getElementsByClassName('check')
      for(var i =0;i<checkboxes.length;i++){
        if(checkboxes[i].name.includes('sunday') || checkboxes[i].name.includes('saturday'))
          continue;
        checkboxes[i].checked = true
      }
      timezoneRef.current.value = '(UTC) Coordinated Universal Time'
    }     
  },[])//use effect

  
useEffect(()=>{
  if(params.id === undefined)
      timezoneRef.current.value = '(UTC) Coordinated Universal Time' //sessData.timezone      
},[timezonesArr] )


function populateTimezones(){
    let timezonesArr1 = timezones.timezones.split(';')
    setTimezonesArr(timezonesArr1)    
}

function fillEditForm(){
axiosClient.getRequest(constants.BUSINESS_CALENDER_URL+params.id)
    .then(function (response) {
      nameRef.current.value= response.data.name      
      hoursCheckBoxRef.current.checked = (response.data.is24HourCalender === true);
      let event = {};
      event['target']={}
      event['target']['checked'] = hoursCheckBoxRef.current.checked
      hours24Checked(event) 
      let calender = response.data.calender           
      if(!hoursCheckBoxRef.current.checked){              
              var elements = document.getElementsByClassName('input-time') 
              // monday from hours 0

              for(var i=0;i<elements.length;i++){

                let elementNames = elements[i].name.split(' ')
                if(calender[elementNames[0]][elementNames[3]] === undefined)     
                  continue
                if(elementNames[2] === 'hours')
                  elements[i].value = calender[elementNames[0]][elementNames[3]][elementNames[1]].split(':')[0]
                else
                  elements[i].value = calender[elementNames[0]][elementNames[3]][elementNames[1]].split(':')[1]        
              }
                
              for(var i=0;i<elements.length;i++){
                let elementNames = elements[i].name.split(' ')
                let checkboxName = elementNames[0]+'-checkbox'
                if(calender[checkboxName] === true)
                {
                  let checkBoxElement = document.getElementsByName(checkboxName)
                  checkBoxElement[0].checked = true
                }else{
                  let checkBoxElement = document.getElementsByName(checkboxName)
                  checkBoxElement[0].checked = false                  
                  event = {};
                  event['target']={}
                  event['target']['name'] = checkboxName;
                  event['target']['checked'] = false
                  disableWorking(event)
                }
              }
              //fill break timings
              if(calender['break-checkbox']){
                document.getElementsByName('break-checkbox')[0].checked = true             
              }              
              elements = document.getElementsByClassName('break-timing')
              for(var i =0;i<elements.length;i++)  {
                  if(calender['break-checkbox']){
                    let nameParts = elements[i].name.split(' ')
                    if(nameParts[2] === 'hours')
                      elements[i].value = calender['break'][nameParts[1]].split(':')[0]
                    else
                      elements[i].value = calender['break'][nameParts[1]].split(':')[1]
                    elements[i].disabled = false
                  }else{
                    elements[i].disabled = true
                  }
              }
              elements = document.getElementsByClassName('select-break')             
              for(var i =0;i<elements.length;i++)  {
                if(calender['break-checkbox']){
                  elements[i].value = calender[elements[i].name]
                  elements[i].disabled = false
                }
              }
              elements = document.getElementsByClassName('select-meridian')
              for(var i =0;i<elements.length;i++)  {                
                  let nameParts = elements[i].name.split(' ')
                  if(Object.keys(calender[nameParts[1]]).length === 0)
                    continue
                  if(nameParts[2] === '0')                
                    elements[i].value = calender[nameParts[1]][0]["fromMeridian"]
                  else
                    elements[i].value = calender[nameParts[1]][0]["toMeridian"]
                  elements[i].disabled = false
                
              }              
              
          }

        //fill holidays
        if(response.data.holidays !== null){
          let holidaysArr = response.data.holidays.split(";")
          holidaysRef.current.removeChild(holidaysRef.current.lastChild);
          for(var i=0;i<holidaysArr.length -1;i++){
            handleHolidays(holidaysRef.current)
            holidaysRef.current.lastChild.value = holidaysArr[i]
          }

        }
        timezoneRef.current.value= response.data.timezone.trim()

  }).catch(function (error) {
      console.log(error)
  });
}

function capitalizeFirstLetter(string) {
  if(string === null || string === undefined)
    return 
    return string.charAt(0).toUpperCase() + string.slice(1);
}
function validateForm(event){
  let days= ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
  for(var j=0;j<days.length; j++){
    if(!event.target[days[j]+'-checkbox'].checked)
      continue;
    let fromMeridian = event.target['select '+days[j]+' 0'].value
    let toMeridian = event.target['select '+days[j]+' 1'].value
    let input1 = Number(event.target[days[j]+' from hours 0'].value)
    let input2 = Number(event.target[days[j]+' to hours 0'].value)
    let breakFromMeridian = event.target['select-break-open'].value
    let breakToMeridian = event.target['select-break-close'].value
    let breakInput1 = Number(event.target['break from hours'].value)
    let breakInput2 = Number(event.target['break to hours'].value)

    //add check am and 12 does not come togather
    if(fromMeridian.toLowerCase() === 'pm' && input1 != 12){
      input1 = input1+12
    }
    if(toMeridian.toLowerCase() === 'pm' && input2 != 12){
      input2= input2+12
    }
    if(breakFromMeridian.toLowerCase() === 'pm' && breakInput1 != 12)
      breakInput1 = breakInput1 + 12 ;

    if(breakToMeridian.toLowerCase() === 'pm' && breakInput2 != 12)
      breakInput2 = breakInput2 + 12 ;    

    if(fromMeridian.toLowerCase() === toMeridian.toLowerCase() && input1 > input2){
      displayError('Closing hours are before opening hours for '+capitalizeFirstLetter(days[j]))
      return false
    }
    if(fromMeridian.toLowerCase() === 'am' && toMeridian.toLowerCase() === 'pm' && event.target['break-checkbox'].checked){
      if(input1 > breakInput1){
        displayError('Break hours are before opening hours ')
        return false
      }
      if(breakInput1 > breakInput2){
        console.log('error '+breakInput1+' '+breakInput2)
        displayError('Break Closing hours are before opening hours')
        return false
      }
      if(breakInput2 > input2){
        displayError('Break Closing hours are after closing hours for '+capitalizeFirstLetter(days[j]))
        return false
      }    
    }

    if(fromMeridian.toLowerCase() === 'pm' && toMeridian.toLowerCase() === 'am' && event.target['break-checkbox'].checked){

    }
  }
  return true;
}
function displayError(msg){
  errorRef.current.innerHTML = "<span style='color:red'>*&nbsp;&nbsp;"+msg+"</span>"
  errorRef.current.style.display='block'
  errorRef.current.scrollIntoView()
}
  function handleSubmit(event){
    event.preventDefault()
    errorRef.current.style.display = 'none'
    errorRef.current.innerHTML = ''
    if(!validateForm(event))
      return
    let morningHours = ['00','01','02','03','04','05','06','07','08','09',
                 '10','11','12']
    let afternoonHours = ['13','14','15','16','17','18','19', '20',
        '21', '22', '23']
    let minutes = ['00','01','02','03','04','05','06','07','08','09']
    for(var i = 10;i<= 59;i++){
      minutes.push(String(i))
    }    
    
    let payload = {timezone: timezoneRef.current.value}
    let calender = {'monday':[{"from" :"", "to": "","fromMeridian":"","toMeridian": ""}],'tuesday':[{"from" :"", "to": "","fromMeridian":"","toMeridian": ""}],'wednesday':[{"from" :"", "to": "","fromMeridian":"","toMeridian": ""}],'thursday':[{"from" :"", "to": "","fromMeridian":"","toMeridian": ""}],'friday':[{"from" :"", "to": "","fromMeridian":"","toMeridian": ""}],'saturday':[{"from" :"", "to": "","fromMeridian":"","toMeridian": ""}],'sunday':[{"from" :"", "to": "","fromMeridian":"","toMeridian": ""}],
                 'break':{"from":"","to" : "","fromMeridian":"","toMeridian": ""},
              'select-break-open':event.target['select-break-open'].value,
              'select-break-close':event.target['select-break-close'].value}
    // create calender json in first pass
    for(var i=0;i<event.target.length ; i++){
      if(event.target[i].type === 'checkbox' && event.target[i].name === 'hours24'){
          payload['is24HourCalender'] = event.target[i].checked
          continue
        }
      if(event.target[i].name.split(' ').length === 4){
        // monday from hours 0
        var nameParts = event.target[i].name.split(' ');
          if(nameParts[2] === 'hours')
            calender[nameParts[0]][nameParts[3]][nameParts[1]] = event.target[i].value+":"+calender[nameParts[0]][nameParts[3]][nameParts[1]]
          else
            calender[nameParts[0]][nameParts[3]][nameParts[1]] = calender[nameParts[0]][nameParts[3]][nameParts[1]]+event.target[i].value 
      }
      else if(event.target[i].name.split(' ').length === 3){
          var nameParts = event.target[i].name.split(' ');
          if(nameParts[0] === 'break'){
              //break from hours
              if(nameParts[2] === 'hours')
                calender['break'][nameParts[1]] = event.target[i].value+":"+calender[nameParts[0]][nameParts[1]]
              else
                calender['break'][nameParts[1]] = calender[nameParts[0]][nameParts[1]]+event.target[i].value
          }else if(nameParts[0] === 'select'){
              //break from hours
              if(nameParts[2] === '0')
                calender[nameParts[1]][0]['fromMeridian'] = event.target[i].value
              else
                calender[nameParts[1]][0]["toMeridian"] = event.target[i].value
          }        
      }else if(event.target[i].type === 'checkbox'){
        calender[event.target[i].name] = event.target[i].checked
      }else
        //this is for timezone
        payload[event.target[i].name]=event.target[i].value
      
    }

    payload['calender'] = calender
    // diable not working days in second pass
    for(var i=0;i<event.target.length ; i++){     
      if(event.target[i].name === 'hours24' && event.target[i].checked ){
        payload['calender'] = null
        break;
      }
      if(event.target[i].type === 'checkbox' && !event.target[i].checked){
        payload['calender'][event.target[i].name.split('-')[0]]=[]
      }           
    }


    let holidays = []
    let holidaysInput = document.getElementsByClassName('holidays');
    for(var i=0;i<holidaysInput.length; i++){
      if(holidaysInput[i].value !== '')
        holidays.push(holidaysInput[i].value)
    }
    payload['holidays'] = holidays
    if(params.id !== undefined){
      axiosClient.patchRequest(constants.BUSINESS_CALENDER_URL+params.id, payload)
      .then(function (response) {
           navigate(constants.BUSINESS_HOURS_URL_ABS)
      }).catch(function (error) {
          if(error.response.status === 400){
              if(error.response.data.error === '')
                errorRef.current.innerHTML = 'Error in processing the request'
              else
                errorRef.current.innerHTML = error.response.data.error
            }else{
              errorRef.current.innerHTML = 'Error in processing the request'
            }
            errorRef.current.style.display = 'block'
    });      
    }else{
        axiosClient.postRequest(constants.BUSINESS_CALENDER_URL, payload)
        .then(function (response) {
            navigate(constants.BUSINESS_HOURS_URL_ABS)
        }).catch(function (error) {
          if(error.response.status === 400){
              if(error.response.data.error === '')
                errorRef.current.innerHTML = 'Error in processing the request'
              else
                errorRef.current.innerHTML = error.response.data.error
            }else{
              errorRef.current.innerHTML = 'Error in processing the request'
            }
            errorRef.current.style.display = 'block'
            });
    }
  } 

  function hours24Checked(event){
    var  selectedInputs = document.getElementsByClassName("input-time");
    for(var i=0;i<selectedInputs.length; i++){
      selectedInputs[i].value = ''
      selectedInputs[i].disabled = event.target.checked
    }
    var  selectedCheckBox = document.getElementsByClassName("check");
    for(var i=0;i<selectedCheckBox.length; i++){
      if(selectedCheckBox[i].name.startsWith('sunday') || selectedCheckBox[i].name.startsWith('saturday')){
        selectedCheckBox[i].checked = false
        var inputs = document.getElementsByClassName('sunday')
        for(var j=0;j<inputs.length;j++)
          inputs[j].disabled = true
        inputs = document.getElementsByClassName('saturday')
        for(var j=0;j<inputs.length;j++)
          inputs[j].disabled = true
        inputs = document.getElementsByClassName('break-timing')
        for(var j=0;j<inputs.length;j++)
          inputs[j].disabled = true
        
        continue  
      }
              
      if(selectedCheckBox[i] !== event.target)
        selectedCheckBox[i].checked = !event.target.checked
    }
    var  breakInput = document.getElementsByClassName("select-break");
   for(var i=0;i<breakInput.length; i++){
      breakInput[i].disabled = !event.target.checked
   }
   var meridians = document.getElementsByClassName('select-meridian')
   for(var i=0;i<meridians.length; i++){
      meridians[i].disabled = event.target.checked
   }
  // let elements = document.getElementsByClassName('break-timing')
  // for(var i=0;i<elements.length; i++){  
  //   elements[i].disabled = event.target.checked
  //   elements[i].value = ''
  // }
  let elements = document.getElementsByClassName('select-break')
  for(var i=0;i<elements.length; i++)  
    elements[i].disabled = event.target.checked
  elements = document.getElementsByClassName('break-checkbox')
  elements[0].checked = false
  
}

function autoFillTheMeridian(event){
  let name = event.target.name
  let nameParts = name.split(' ')
  let days = ['tuesday','wednesday','thursday','friday'];
  for(var i=0;i<days.length; i++){
    let element = document.getElementsByName('select '+days[i]+' '+nameParts[2])[0]
    element.value = event.target.value
  }
}
function autoFillTheForm(event, time){
  if(!checkInputTime(event, time))
    return
  let days = ['tuesday','wednesday','thursday','friday'];
  //name='sunday from hours 1'
  let name = event.target.name;
  let names = name.split(' ')
  
  for(var i =0 ; i < days.length; i++){      
      let requiredName = days[i]+' '+names[1]+' '+names[2]+' '+names[3]
      let ele = document.getElementsByName(requiredName)
      ele[0].value = event.target.value
  }

}

function checkInputTime(event, time){
  event.target.setCustomValidity('')
  event.target.style.borderColor = 'black'
  let singleDigits = ['0','1','2','3','4','5','6','7','8','9'];
  let morningHours = ['00','01','02','03','04','05','06','07','08','09',
               '10','11','12']
  // let afternoonHours = ['13','14','15','16','17','18','19', '20',
  //     '21', '22', '23']
  let minutes = ['00','01','02','03','04','05','06','07','08','09']
  for(var i = 10;i<= 59;i++){
    minutes.push(String(i))
  }
  if(isNaN(event.target.value))
      event.target.value = '' //event.target.value.substring(0,event.target.value.length-1)

  // monday from hours 0
  if(time === 'hours'){
      if(singleDigits.includes(event.target.value)){
          event.target.value = '0' + event.target.value
      }
      if(!(morningHours.includes(event.target.value))){
         event.target.setCustomValidity('Hours value must be between 0 and 12')      
       return false;
      }

    }else if(time === 'minutes'){
      if(singleDigits.includes(event.target.value)){
          event.target.value = '0' + event.target.value
      }
      if(!minutes.includes(event.target.value)){
         event.target.setCustomValidity('Minutes value must be between 0 and 59')      
         return false;
      }      
      
    }

    let names = event.target.name.split(' ')
      if(event.target.name.includes('to hours 0')){
        let ele = document.getElementsByName(names[0]+' from hours 0')
        if(Number(ele[0].value) > Number(event.target.vlaue)){
          event.target.setCustomValidity('Ending hours must be greater than Starting hours') 
          return false;
        }
      }
      if(event.target.name.includes('to hours 1')){
        let ele1 = document.getElementsByName(names[0]+' from hours 0')
        let ele2 = document.getElementsByName(names[0]+' to hours 0')
       if(Number(ele1[0].value) > Number(event.target.vlaue) ||
           Number(ele2[0].value) > Number(event.target.vlaue)){
          event.target.setCustomValidity('Ending hours must be greater than Starting hours') 
          return false;
        }        
      }
    return true;
}    

function removeHolidays(event){
  event.preventDefault();
  holidaysRef.current.removeChild(holidaysRef.current.lastChild)
  if(holidaysRef.current.children.length === 1)
      event.target.style.visibility = 'hidden'

}
  function handleHolidays(event){
    if(typeof event.preventDefault === 'function')
      event.preventDefault()
    let parentElement = holidaysRef.current.parentElement
    let input = document.createElement('input');
    input.style.width = '60%'
    input.style.minWidth = '550px'
    input.style.marginTop = '10px'
    input.style.height = '38px'
    input.classList.add('holidays')
    input.classList.add('holidays-input-box')
    input.type = 'date'
    input.style.display = 'block'
    holidaysRef.current.appendChild(input)
    if(holidaysRef.current.children.length > 1)
      parentElement.children[2].style.visibility = 'visible'
  }


  function disableWorking(event){
    let names = event.target.name.split('-');
    var ele = document.getElementsByClassName(names[0])
    for(var i=0;i<ele.length; i++){
        ele[i].disabled = !event.target.checked
        ele[i].value=''
    }    
  }
function clickCancel(){
  navigate(constants.BUSINESS_HOURS_URL_ABS)
}

function disableBreak(event){
  let elements = document.getElementsByClassName('break-timing')
  for(var i=0;i<elements.length; i++){  
    elements[i].disabled = !event.target.checked
    elements[i].value = ''
  }
  elements = document.getElementsByClassName('select-break')
  for(var i=0;i<elements.length; i++)  
    elements[i].disabled = !event.target.checked
}
function redirectToBusinessHoursList(){
  navigate(constants.BUSINESS_HOURS_URL_ABS)
}
  return (
    <>
        <div class='header-body' style={{backgroundColor: 'white' }}>
            <a href='' style={{marginLeft:'10px', marginTop:'60px', textDecoration: 'none', fontSize:'16px'}} onClick={redirectToBusinessHoursList}> > All Business Hours</a>
        </div>
        <div class="main-body" style={{top:'0px', backgroundColor: 'white',fontFamily:'-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif', display: 'flex', height:'99vh'}}>
         <div class='busines-calender-create-wrapper'>
          <form onSubmit={handleSubmit}>            
            <div style={{ padding:'30px', paddingleft:'0px',backgroundColor:'white',height: 'calc(100vh - 145px'}}>                  
            <h8 style={{color:'#044caf',fontWeight:'1000',fontSize:'18px'}}>Configure Business Hours</h8>  
            <div class='create-line'></div>     
            <div ref={errorRef} style={{color: 'red', marginLeft:'50px'}}/>
            <div class= 'create-form-label' style={{alignItems: 'center', display: 'flex', flexDirection: 'row', marginTop: '3%'}}>Name: <input type="text" placeholder={'Enter calender Name'} name='name' style={{ marginLeft: '10px'}} ref={nameRef} required class='custom-select'/> </div>
            <div class= 'create-form-label'><input type='checkbox' onClick={hours24Checked} name="hours24" style={{marginTop:'15px',marginLeft: '3%',height:'15px'}} ref={hoursCheckBoxRef}/><span> is 24*7 Business</span></div>
            <table class= 'table' style={{marginTop: '3%',minWidth: '750px'}}>
            <thead><tr><th style={{width:'200px'}}></th><th><span style={{marginLeft:'50px'}}>Open</span><span style={{marginLeft:'200px'}}>Close</span></th></tr></thead>
                <tbody>
                  <tr >
                    <td><div style={{alignItems: 'center',display:'inline'}}><input type='checkbox' onClick={disableWorking} name="monday-checkbox" style={{marginLeft: '50px', marginRight: '10px'}} class='check'/></div>
                        <span class= 'create-form-label'>Monday</span>
                    </td>
                    <td>
                        <div style={{display:'inline-block', border: '1px solid black', width:'68px', borderRadius:'5px',marginRight:'5px'}}>
                            <input tabindex= '1' maxLength = '2' type='text' style= {{ outline:'none',textAlign: 'right', border:'none',width: '30px', height:'20px'}}  name='monday from hours 0' class='input-time monday' required onBlur={(event) => autoFillTheForm(event, 'hours')}/>: 
                            <input  tabindex= '2' maxLength = '2' type='text' style= {{ outline:'none', border:'none',width: '30px', height:'20px'}}  name='monday from minutes 0' class='input-time monday' required onBlur={(event) => autoFillTheForm(event, 'minutes')}/>                                                        
                        </div> 
                        <select class='select-meridian' name='select monday 0' style= {{ width: '50px', height:'24px'}} onChange={autoFillTheMeridian}><option>AM</option><option>PM</option></select>
                        <span style={{marginLeft:'50px',marginRight:'50px',fontSize: '10px',fontWeight:'600' }}>To</span>
                        <div style={{display:'inline-block',border: '1px solid black', width:'68px', borderRadius:'5px', marginLeft:'5px'}}>
                            <input tabindex= '3'  maxLength = '2' type='text' style= {{outline:'none',textAlign: 'right', border:'none',width: '30px', height:'20px'}} name='monday to hours 0' class='input-time monday' required required onBlur={(event) => autoFillTheForm(event, 'hours')}/>: 
                            <input tabindex= '4'  maxLength = '2' type='text' style= {{outline:'none',border:'none',width: '30px', height:'20px'}} name='monday to minutes 0' class='input-time monday'  required required onBlur={(event) => autoFillTheForm(event, 'minutes')}/>
                        </div>
                        <select class='select-meridian' name='select monday 1' style= {{marginLeft:'5px', width: '50px', height:'24px'}} onChange={autoFillTheMeridian}><option>AM</option><option>PM</option></select>
                    </td>                 
                  </tr>
                  <tr>
                    <td ><div style={{alignItems: 'center',display:'inline'}}><input type='checkbox' onClick={disableWorking} name="tuesday-checkbox" style={{marginLeft: '50px', marginRight: '10px'}} class='check' /></div>
                        <span class= 'create-form-label'>Tuesday</span>
                    </td>
                    <td>
                        <div style={{display:'inline-block', border: '1px solid black', width:'68px', borderRadius:'5px',marginRight:'5px'}}>
                            <input  maxLength = '2' type='text' style= {{ outline:'none', textAlign: 'right',border:'none',width: '30px', height:'20px'}}  name='tuesday from hours 0' class='input-time tuesday' required onBlur={(event) => checkInputTime(event, 'hours')}/>: 
                            <input  maxLength = '2' type='text' style= {{ outline:'none', border:'none',width: '30px', height:'20px'}}  name='tuesday from minutes 0' class='input-time tuesday' required onBlur={(event) => checkInputTime(event, 'minutes')}/>
                        </div>
                        <select class='select-meridian' name='select tuesday 0' style= {{ width: '50px', height:'24px'}}><option>AM</option><option>PM</option></select>
                        <span style={{marginLeft:'50px',marginRight:'50px',fontSize: '10px',fontWeight:'600' }}>To</span>
                        <div style={{display:'inline-block',border: '1px solid black', width:'68px', borderRadius:'5px', marginLeft:'5px'}}>
                            <input  maxLength = '2' type='text' style= {{outline:'none', textAlign: 'right',border:'none',width: '30px', height:'20px'}} name='tuesday to hours 0' class='input-time tuesday' required onBlur={(event) => checkInputTime(event, 'hours')}/>: 
                            <input  maxLength = '2' type='text' style= {{outline:'none',border:'none',width: '30px', height:'20px'}} name='tuesday to minutes 0' class='input-time tuesday' required onBlur={(event) => checkInputTime(event, 'minutes')}/>
                        </div>
                        <select class='select-meridian' name='select tuesday 1' style= {{ marginLeft:'5px',width: '50px', height:'24px'}}><option>AM</option><option>PM</option></select>
                    </td>                   
                  </tr>            
                  <tr>
                    <td><div style={{alignItems: 'center',display:'inline'}}><input type='checkbox' onClick={disableWorking} name="wednesday-checkbox" style={{marginLeft: '50px', marginRight: '10px'}} class='check' /></div>
                        <span class= 'create-form-label'>Wednesday</span>
                    </td>
                    <td>
                        <div style={{display:'inline-block', border: '1px solid black', width:'68px', borderRadius:'5px',marginRight:'5px'}}>
                            <input  maxLength = '2' type='text' style= {{ outline:'none',textAlign: 'right', border:'none',width: '30px', height:'20px'}}  name='wednesday from hours 0' class='input-time wednesday' required onBlur={(event) => checkInputTime(event, 'hours')}/>: 
                            <input  maxLength = '2' type='text' style= {{ outline:'none', border:'none',width: '30px', height:'20px'}}  name='wednesday from minutes 0' class='input-time wednesday' required onBlur={(event) => checkInputTime(event, 'minutes')}/>
                        </div> 
                        <select class='select-meridian' name='select wednesday 0' style= {{ width: '50px', height:'24px'}}><option>AM</option><option>PM</option></select>
                        <span style={{marginLeft:'50px',marginRight:'50px',fontSize: '10px',fontWeight:'600' }}>To</span>
                        <div style={{display:'inline-block',border: '1px solid black', width:'68px', borderRadius:'5px', marginLeft:'5px'}}>
                            <input  maxLength = '2' type='text' style= {{outline:'none',textAlign: 'right', border:'none',width: '30px', height:'20px'}} name='wednesday to hours 0' class='input-time wednesday' required onBlur={(event) => checkInputTime(event, 'hours')}/>: 
                            <input  maxLength = '2' type='text' style= {{outline:'none',border:'none',width: '30px', height:'20px'}} name='wednesday to minutes 0' class='input-time wednesday' required onBlur={(event) => checkInputTime(event, 'minutes')}/>
                        </div>
                        <select class='select-meridian' name='select wednesday 1' style= {{ marginLeft:'5px',width: '50px', height:'24px'}}><option>AM</option><option>PM</option></select>
                    </td>                   
                  </tr>            
                  <tr>
                    <td ><div style={{alignItems: 'center',display:'inline'}}><input type='checkbox' onClick={disableWorking} name="thursday-checkbox" style={{marginLeft: '50px', marginRight: '10px'}} class='check' /></div>
                        <span class= 'create-form-label'>Thursday</span>
                    </td>
                    <td>
                        <div style={{display:'inline-block', border: '1px solid black', width:'68px', borderRadius:'5px',marginRight:'5px'}}>
                            <input  maxLength = '2' type='text' style= {{ outline:'none',textAlign: 'right', border:'none',width: '30px', height:'20px'}}  name='thursday from hours 0' class='input-time thursday' required onBlur={(event) => checkInputTime(event, 'hours')}/>: 
                            <input  maxLength = '2' type='text' style= {{ outline:'none', border:'none',width: '30px', height:'20px'}}  name='thursday from minutes 0' class='input-time thursday' required onBlur={(event) => checkInputTime(event, 'minutes')}/>
                        </div> 
                        <select class='select-meridian' name='select thursday 0' style= {{ width: '50px', height:'24px'}}><option>AM</option><option>PM</option></select>
                        <span style={{marginLeft:'50px',marginRight:'50px',fontSize: '10px',fontWeight:'600' }}>To</span>
                        <div style={{display:'inline-block',border: '1px solid black', width:'68px', borderRadius:'5px', marginLeft:'5px'}}>
                            <input  maxLength = '2' type='text' style= {{outline:'none',textAlign: 'right', border:'none',width: '30px', height:'20px'}} name='thursday to hours 0' class='input-time thursday' required onBlur={(event) => checkInputTime(event, 'hours')}/>: 
                            <input  maxLength = '2' type='text' style= {{outline:'none',border:'none',width: '30px', height:'20px'}} name='thursday to minutes 0' class='input-time thursday' required onBlur={(event) => checkInputTime(event, 'minutes')}/>
                        </div>
                        <select class='select-meridian' name='select thursday 1' style= {{marginLeft:'5px', width: '50px', height:'24px'}}><option>AM</option><option>PM</option></select>
                    </td>                   
                  </tr>            
                  <tr>
                    <td ><div style={{alignItems: 'center',display:'inline'}}><input type='checkbox' onClick={disableWorking} name="friday-checkbox" style={{marginLeft: '50px', marginRight: '10px'}} class='check' /></div>
                        <span class= 'create-form-label'>Friday</span>
                    </td>
                    <td>
                        <div style={{display:'inline-block', border: '1px solid black', width:'68px', borderRadius:'5px',marginRight:'5px'}}>
                            <input  maxLength = '2' type='text' style= {{ outline:'none', textAlign: 'right',border:'none',width: '30px', height:'20px'}}  name='friday from hours 0' class='input-time friday' required onBlur={(event) => checkInputTime(event, 'hours')}/>: 
                            <input  maxLength = '2' type='text' style= {{ outline:'none', border:'none',width: '30px', height:'20px'}}  name='friday from minutes 0' class='input-time friday' required onBlur={(event) => checkInputTime(event, 'minutes')}/>
                        </div> 
                        <select class='select-meridian' name='select friday 0' style= {{ width: '50px', height:'24px'}}><option>AM</option><option>PM</option></select>
                        <span style={{marginLeft:'50px', marginRight:'50px',fontSize: '10px',fontWeight:'600' }}>To</span>
                        <div style={{display:'inline-block',border: '1px solid black', width:'68px', borderRadius:'5px', marginLeft:'5px'}}>
                            <input  maxLength = '2' type='text' style= {{outline:'none', textAlign: 'right',border:'none',width: '30px', height:'20px'}} name='friday to hours 0' class='input-time friday' required onBlur={(event) => checkInputTime(event, 'hours')}/>: 
                            <input  maxLength = '2' type='text' style= {{outline:'none',border:'none',width: '30px', height:'20px'}} name='friday to minutes 0' class='input-time friday' required onBlur={(event) => checkInputTime(event, 'minutes')}/>
                        </div>
                        <select class='select-meridian' name='select friday 1' style= {{marginLeft:'5px', width: '50px', height:'24px'}}><option>AM</option><option>PM</option></select>
                    </td>                  
                  </tr>            
                  <tr>
                    <td ><div style={{alignItems: 'center',display:'inline'}}><input type='checkbox' onClick={disableWorking} name="saturday-checkbox" style={{marginLeft: '50px', marginRight: '10px'}} class='check'/></div>
                        <span class= 'create-form-label'>Saturday</span>
                    </td>
                    <td>
                        <div style={{display:'inline-block', border: '1px solid black', width:'68px', borderRadius:'5px',marginRight:'5px'}}>
                            <input disabled maxLength = '2' type='text' style= {{ outline:'none', textAlign: 'right',border:'none',width: '30px', height:'20px'}}  name='saturday from hours 0' class='input-time saturday' required onBlur={(event) => checkInputTime(event, 'hours')}/>: 
                            <input disabled maxLength = '2' type='text' style= {{ outline:'none', border:'none',width: '30px', height:'20px'}}  name='saturday from minutes 0' class='input-time saturday' required onBlur={(event) => checkInputTime(event, 'minutes')}/>
                        </div> 
                        <select class='select-meridian' name='select saturday 0' disabled style= {{ width: '50px', height:'24px'}}><option>AM</option><option>PM</option></select>
                        <span  style={{marginLeft:'50px',marginRight:'50px',fontSize: '10px',fontWeight:'600' }}>To</span>
                        <div style={{display:'inline-block',border: '1px solid black', width:'68px', borderRadius:'5px', marginLeft:'5px'}}>
                            <input disabled maxLength = '2' type='text' style= {{outline:'none', textAlign: 'right',border:'none',width: '30px', height:'20px'}} name='saturday to hours 0' class='input-time saturday' required onBlur={(event) => checkInputTime(event, 'hours')}/>: 
                            <input disabled maxLength = '2' type='text' style= {{outline:'none',border:'none',width: '30px', height:'20px'}} name='saturday to minutes 0' class='input-time saturday' required onBlur={(event) => checkInputTime(event, 'minutes')}/>
                        </div>
                        <select class='select-meridian' name='select saturday 1' disabled style= {{ marginLeft:'5px',width: '50px', height:'24px'}}><option>AM</option><option>PM</option></select>
                    </td>                  
                  </tr>
                  <tr>
                    <td ><div style={{alignItems: 'center',display:'inline'}}><input type='checkbox' onClick={disableWorking} name="sunday-checkbox" style={{marginLeft: '50px', marginRight: '10px'}} class='check'/></div>
                        <span class= 'create-form-label'>Sunday</span>
                    </td>
                    <td>
                        <div style={{display:'inline-block', border: '1px solid black', width:'68px', borderRadius:'5px',marginRight:'5px'}}>
                            <input disabled maxLength = '2' type='text' style= {{ outline:'none',textAlign: 'right', border:'none',width: '30px', height:'20px'}}  name='sunday from hours 0' class='input-time sunday' required onBlur={(event) => checkInputTime(event, 'hours')}/>: 
                            <input disabled maxLength = '2' type='text' style= {{ outline:'none', border:'none',width: '30px', height:'20px'}}  name='sunday from minutes 0' class='input-time sunday' required onBlur={(event) => checkInputTime(event, 'minutes')}/>
                        </div> 
                        <select class='select-meridian' name='select sunday 0' disabled style= {{ width: '50px', height:'24px'}}><option>AM</option><option>PM</option></select>
                        <span style={{marginLeft:'50px',marginRight:'50px',fontSize: '10px',fontWeight:'600' }}>To</span>
                        <div style={{display:'inline-block',border: '1px solid black', width:'68px', borderRadius:'5px', marginLeft:'5px'}}>
                            <input disabled maxLength = '2' type='text' style= {{outline:'none', textAlign: 'right',border:'none',width: '30px', height:'20px'}} name='sunday to hours 0' class='input-time sunday' required onBlur={(event) => checkInputTime(event, 'hours')}/>: 
                            <input disabled maxLength = '2' type='text' style= {{outline:'none',border:'none',width: '30px', height:'20px'}} name='sunday to minutes 0' class='input-time sunday' required onBlur={(event) => checkInputTime(event, 'minutes')}/>
                        </div>
                        <select class='select-meridian' name='select sunday 1' disabled style= {{ marginLeft:'5px',width: '50px', height:'24px'}}><option>AM</option><option>PM</option></select>
                    </td>                  
                  </tr>                          
                </tbody>
            </table>
              <div style={{marginTop: '10px',marginLeft:'50px',minWidth:'750px'}}>
                <input type='checkbox' onClick={disableBreak} style={{marginLeft:'5px'}} name="break-checkbox" class='break-checkbox' />
                <span class= 'create-form-label' style={{marginLeft:'5px'}}>Break Timings: </span>
                <div style={{marginLeft:'25px',display:'inline-block', border: '1px solid black', width:'68px', borderRadius:'5px',marginRight:'5px'}}>
                    <input disabled maxLength = '2' type='text' style= {{ outline:'none',textAlign: 'right', border:'none',width: '30px', height:'20px'}}  name='break from hours' class='break-timing' required onBlur={(event) => checkInputTime(event, 'hours')}/>: 
                    <input  disabled maxLength = '2' type='text' style= {{ outline:'none', border:'none',width: '30px', height:'20px'}}  name='break from minutes' class='break-timing' required onBlur={(event) => checkInputTime(event, 'minutes')}/>
                </div> 
                <select class='select-meridian' name='select break 0' disabled class='select-break' name='select-break-open' style= {{ width: '50px', height:'24px'}}><option>AM</option><option>PM</option></select>
                <span style={{marginLeft:'50px',marginRight:'50px',fontSize: '10px',fontWeight:'600' }}>To</span>
                <div style={{display:'inline-block',border: '1px solid black', width:'68px', borderRadius:'5px', marginLeft:'5px'}}>
                    <input disabled maxLength = '2' type='text' style= {{outline:'none', textAlign: 'right',border:'none',width: '30px', height:'20px'}} name='break to hours' class='break-timing' required onBlur={(event) => checkInputTime(event, 'hours')}/>: 
                    <input disabled maxLength = '2' type='text' style= {{outline:'none',border:'none',width: '30px', height:'24px'}} name='break to minutes' class='break-timing' required onBlur={(event) => checkInputTime(event, 'minutes')}/>
                </div>
                <select class='select-meridian' name='select break 1' disabled class='select-break' name='select-break-close' style= {{marginLeft:'5px', width: '50px', height:'24px'}}><option>AM</option><option>PM</option></select>
              </div>
              <div style={{marginTop: '10px'}}>
                Timezone:
                  <div>
                    <select ref = {timezoneRef} name='timezone' class="custom-select">
                     {
                            timezonesArr.map((option, index) => 
                                  <option key={index} value={option}>
                                    {option}
                                  </option>
                          )
                     }
                    </select>
                </div>
              </div>

              <div style={{marginTop: '10px'}}>
                Holidays:
                <div style={{marginBottom:' 50px'}}>
                  <div ref={holidaysRef}>
                    <input type="date"  class='holidays holidays-input-box'/>
                  </div>
                  <a href='' onClick={handleHolidays} >Add Holiday </a>
                  <a href='' onClick={removeHolidays} style={{visibility: 'hidden', marginLeft:'40%' }}>Remove Holiday </a>
                </div>
              </div>
          </div>
          <div style={{display:'flex'}}>
                <button class='ss-button business-calender-cancel-button' onClick={clickCancel}>Cancel</button>
                <button class='ss-button business-calender-create-button' >{params.id === undefined ? 'Create' : 'Update' } Business Calender</button>
          </div>
          </form> 
         </div>
          <div class='business-calender-create-right-box'  >
                <div style={{fontWeight:'600', marginTop:'5px',fontFamily:'Amplitude',lineHeight:'2'}}>Business hours refer to the set hours during which a business is open and operational to serve customers, clients, or conduct its operations. </div>
                <div style={{fontWeight:'600', marginTop:'20px',fontFamily:'Amplitude',lineHeight:'2'}} >
                  <ol>
                    <li>Business timings must be in 24 hour format </li>
                    <li>For 24*7 business hours, Check the box with label <underline>is 24*7 Business </underline></li>
                    <li><div>In the context of businesses operating night shifts, where the closing time is typically earlier than the opening time of the following day, it's generally considered as the business being closed on the next day. This is because the night shift often starts in the evening of one day and ends in the early morning hours of the next day.</div>
                        <div>For example, if a business operates a night shift from 10:00 PM (22:00) to 6:00 AM (06:00) the next day, it's considered closed on the next day.</div></li>
                  </ol> 
                </div>
          </div>                    
        </div>

   </>
  );
}

export default CreateBusinessCalenderForm;