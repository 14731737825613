  import React from "react"
  import {useState, setState, useRef, useEffect} from 'react';
  import {useNavigate} from "react-router-dom"
  import * as axiosClient from '../../../js/Axios';
  import * as constants from '../../../js/constants'
  import '../../../css/main.css'
  import {over} from 'stompjs';
  
  import { io } from 'socket.io-client';
  const voice = require('@twilio/voice-sdk')

  function ChatProfile(props){
    const [selected, setSelected] = useState(false)
      const chatBodyRef = useRef(null)
      const inputTextRef = useRef(null)
      const clientRef = useRef(null)
      var socket = null
      // const URL = process.env.NODE_ENV === 'production' ? undefined : 'http://localhost:3002';
      // const socket = io(URL);
      //     console.log("Inside "+localStorage.getItem('chat_uuid'))
      //     socket.on('connect', onConnect);
      //     socket.on('disconnect', onDisconnect);
      //     socket.on('message', onMessage);
      function onMessage(message){
          message = JSON.parse(message)
          console.log("Message on user side received "+message.type)
          if(message.type === 'welcome'){
       
          }else if(message.type === 'connect_to_user'){
             console.log(message)
          }
      }
      function onConnect(){ 
        var message = {"type": "user_connect", "user_id" : 1}
        socket.send(message)
      }

      function onDisconnect(){console.log("onDisconnect")}

      // useEffect(() => {
      //     console.log("useEffect chat ")
      // axiosClient.postRequest(constants.CHAT_URL+'generate_token',payload)
      //     .then(function (response) {
      //         console.log(response)
      //     }).catch(function (error) {
      //         console.log(error)
      // });
      // },[])//use effect

      function componentClicked(){  
        console.log("OnClick")
        props.chatProfileClicked(props.id)

      }
      console.log("Profile id "+ props.id)
      return(
 <div class="chat-Profile" onClick = {componentClicked}  style = {{backgroundColor : (props.id === props.clickedProfileId)  ? "grey" : "white" }}>
     <div class="chat-profile-img">
       <img src={require('../../../img/delete.png')} alt=''/>
     </div>
     <div class="Profile-details">
       <h2 class="blue-color"><p style={{colour: "blue"}}>{props.data.contact.name === undefined ? "UNKNOWN" : props.data.contact.fullName}</p> </h2>
       <p>{props.data.content}</p>
     </div>
     <div class="chat-time">
       <p>{props.data.createdDate}</p>
       <span>1</span>
     </div>
 </div>              
          )

  }

  export default ChatProfile;