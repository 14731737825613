import React from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../js/constants'

function SlaLine(props){

const navigate = useNavigate(); 

function checkBoxClicked(event){
	props.checkBoxClicked(props.sla.id, event.target.checked)
} 

function openSLA(id){
	let path = constants.SLA_URL_ABS +props.sla.id ;
    navigate(path);
}

return (
	        <tr>
		        <th scope="row" class="ps-4" style={{width:'50px'}}>
	            	 <div class="form-check font-size-16"><input type="checkbox" class="form-check-input sla-list-checkbox" onClick={checkBoxClicked} name={'checkbox-'+props.sla.id} style={{border:'1px solid #A9A9A9'}}/></div>
	          	</th>
	            <td >
					<a href='#' onClick={openSLA} style={{width: "2%", textDecoration: 'underline', cursor: 'pointer', fontWeight:'800',fonFamily:'Helvetica'}}>  
						{props.sla.name}
					</a>
	            </td>
	            <td>
					<span style={{marginLeft: '10px', display:'inline-block',width: '350px', width:'350px',overflow: 'hidden',whiteSpace: 'nowrap', textOverflow:'ellipsis'}}>
						 {(props.sla.businessCalender === 'null' || props.sla.businessCalender === null) ? '--' : props.sla.businessCalender.name} 
					</span>
	            </td>
	            <td>
					{props.sla.priority}
	            </td>
	         </tr>
            );

}

export default SlaLine;