import React from "react"
import {useState, setState, useRef, useEffect} from 'react';



function TicketCreateSuccessMessage(props){
function redirectToTicket(){
	window.location.href='/tickets/'+props.ticketId
}
return(
	<div>
		Ticket Number <a href="#" onClick={redirectToTicket}>{props.ticketId}</a> Created Succesfuly
	</div>
	)
}
export default TicketCreateSuccessMessage