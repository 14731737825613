import React, {useState} from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../js/constants'

function TeamListDisplayHeader(props){

const navigate = useNavigate(); 
const [filterState, setFilterState] = useState('All Teams')

function openTeam(id){
	let path = constants.TEAM_URL_ABS +props.team.id ;
    navigate(path);
}

  function toggleFilterBox(){
  	props.filterBoxRef.current.style.display = "block"
  }
  function createNewTeam(){
  	// navigate(constants.TEAM_URL_ABS+'new')
  	props.showCreateConactFormFun()
  }

return (
  <div class='header-body'>
	    <div class="main-header">
	    	<div style={{fontWeight:'bold',paddingLeft:'10px' }}>{filterState}</div>
     		 <div class="search-wrapper">
   					{<img src={require('../../../img/search.png')} alt=''/>}
   					<input placeholder="search here" class="search-input" onChange={props.searchTeams}/>
             </div>	          
	         <div class="header-right" ref={props.groupButtonsRef}>
	            <button class='ss-button'  type="button" onClick={() => props.performAction('delete')}>Delete</button>	            
	         </div>
           <button class='ss-button'  type="button" onClick={createNewTeam}>+ New Team</button>
	    </div>
  </div>
);

}

export default TeamListDisplayHeader;