import React, { useState, useEffect, useRef,useContext } from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../js/constants'
import MyContext from './DepartmentsListDisplay'
function DepartmentLine(props){

const navigate = useNavigate(); 
 const [sessionData, setSessionData] = useState(null)
  useEffect(()=>{
    setSessionData(JSON.parse(sessionStorage.getItem('ss_sess')))
},[])

function editDepartment(){
	props.editDepartment(props.department.id)
}
function checkBoxClicked(event){
	props.checkBoxClicked(props.department.id, event.target.checked)
}

let marginTopIndex = (props.index+3)+'px'
return (
	<tr >
	  { (sessionData !== null && sessionData.isAdmin) ?  <td style = {{width: "2%"}}><input type="checkbox" onClick={checkBoxClicked} class='department-line-checkbox'/></td> : '' }
	    <td style = {{width: "10%", cursor: 'pointer', color: '#0052cc'}} onClick = {editDepartment}>{props.department.name}</td>
	    <td style = {{width: "10%"}}>{props.department.count}</td>
	 </tr>
            );

}

export default DepartmentLine;