import React, { useState, useEffect, useRef,useContext } from 'react';
import {useNavigate} from "react-router-dom"
import ProfilePicture from '../../ProfilePicture/ProfilePicture'
import * as constants from '../../../js/constants'

function ContactLine(props){
 const [sessionData, setSessionData] = useState(null)
  useEffect(()=>{
    setSessionData(JSON.parse(sessionStorage.getItem('ss_sess')))
},[])
function openContact(event){
	event.preventDefault()
    props.openContact(props.contact.id)
}

function fetchWorkPhones(){
	let workPhone = '';
	if(props.contact.workPhone !== '' && props.contact.workPhone !== 'null')
		workPhone = workPhone+props.contact.workPhone;
	if(props.contact.workPhone1 !== '' && props.contact.workPhone1 !== 'null')
		workPhone = workPhone+','+props.contact.workPhone1;
	if(props.contact.workPhone2 !== '' && props.contact.workPhone2 !== 'null')
		workPhone = workPhone+','+props.contact.workPhone2;
	if(workPhone === '')
		workPhone = '--'
	return workPhone;

}
function checkBoxClicked(event){
	props.checkBoxClicked(props.contact.id, event.target.checked)
}

return (
		<tr scope="row" style={{color:'rgb(31, 35, 40)',backgroundColor:'transparent'}}>
          <th scope="row" class="ps-4" style={{width:'50px'}}>
             <div class="form-check font-size-16"><input type="checkbox" class="form-check-input contact-line-checkbox" onClick={checkBoxClicked} name={'checkbox-'+props.contact.id} style={{border:'1px solid #A9A9A9'}}/></div>
          </th>
          <td onClick = {openContact}>
          			{	props.contact.image === 'null' ? 
		            		<ProfilePicture name={props.contact.name} />
		            		: <img class="list-image"  src={ props.contact.image} /> 
		            }
	            	<a href=""  style = {{  marginLeft: '10px',fontWeight:'800',fonFamily:'Helvetica'}} >{props.contact.name}</a>
	      </td>
	      <td>{props.contact.email === 'null' ? '--' : props.contact.email }</td>
	      <td style={{fontWeight:'500'}}>{(props.contact.phoneNumber === 'null' || props.contact.phoneNumber === '') ? '--' : props.contact.phoneNumber}</td>
        <td>{fetchWorkPhones()}</td>
        <td>{(props.contact.uniqueIdentifier === 'null' || props.contact.uniqueIdentifier === '') ? '--' : props.contact.uniqueIdentifier}</td>
	      <td>{props.contact.company === null ? '--' : props.contact.company.name}</td>        
      </tr> 	        
    );

}

export default ContactLine;