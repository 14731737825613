import React, {useState} from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../js/constants'

function CallRouteListDisplayHeader(props){
const navigate = useNavigate(); 

function createNewCallRoute(){
	navigate(constants.CALL_ROUTE_URL_ABS+'create')
}

return (
	<div class='header-body'>
	    <div class="main-header">
	    	<div style={{fontWeight:'bold',paddingLeft:'10px'}}>All CallRoutes</div>
     		 <div class="search-wrapper">
   					{<img src={require('../../../img/search.png')} alt=''/>}
   					<input placeholder="search here" class="search-input" onChange={props.searchCallRoutes}/>
             </div>	          
	         <div class="header-right" ref={props.groupButtonsRef}>
	         	<button class='ss-button'  type="button"  onClick={() => props.performAction('delete')}>Delete</button>   
	         </div>
	         <button class='ss-button'  type="button" style={{marginTop: '2px'}} onClick={createNewCallRoute}>+ New Call Route</button>
	    </div>
	</div>
    );

}

export default CallRouteListDisplayHeader;