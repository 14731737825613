import React from 'react';
import {useState, setState, useRef, useEffect} from 'react';
import Attachment from './Attachment'


function ReplyBox(props){
	
	

  return (
                 <div class="reply-box-wrapper" ref={props.replyBoxRef}>
                    <div class="reply-box-header">
                        <div class="reply-box-header-row">
                          <div class="from-chatbibox">
                            {props.toRef ? <span class="ticket-comment-to">To: <input value={props.ticket['reporter_contact'].email} ref={props.toRef} style={{width:'300px',fontSize:'16px'}}/></span> : '' }
                            <div style={{float:'right', fontSize:'16px', fontWeight:'800',cursor: 'pointer', color:'black'}} onClick ={props.closeReplyBox}>&times;</div>
                          </div>
                        </div>
                  </div>
                <div contenteditable='true' class="chatbox-input" placeholder="Type a message" ref={props.commentRef} id='editor' style={{fontWeight:'400',marginLeft:'10px',marginRight:'10px', height:'300px',padding:'24px',fontSize:'17px',lineHeight:'1.5',overflowY:'auto',border:'2px solid #115fcc',borderRadius:'5px' }}></div>
                <div style={{display: 'flex',flexWrap:'wrap'}}>
                    {props.attachmentFilesList.map((attachment,index) => 
                        <Attachment name={attachment.name} 
                            isEditing={true}
                            index = {index}
                            size={attachment.size}
                            removeAttachmentFromEdit={props.removeAttachmentFromEdit}
                            source={'create'}/>
                    )}
                </div>
                <div class="chatbibox-footer">
                   <ul style={{alignItems:'center',  display:'flex'}}>
                    <li style={{marginLeft: '1px'}}>
                       <label for="file-input">           
                           <img class="chatbox-attachment-img" src={require('../../../img/attachment.png')}/>
                       </label>
                       <input id="file-input" type="file" style = {{display: "none" }} onChange={props.handleAttachment}/>
                    </li>
                    <li style={{marginLeft: '1px'}}>
                        <img src={require('../../../img/bold.png')} onClick={(event)=>props.format(event,'bold')} style = {{cursor:'pointer',height: "20px", width: '20px',padding:'3px' }}/>
                    </li>
                    <li style={{marginLeft: '1px'}}>
                        <img src={require('../../../img/italic.png')} onClick={(event)=>props.format(event,'italic')} style = {{cursor:'pointer',height: "20px", width: '20px',padding:'3px' }}/>
                    </li>                    
                    <li style={{marginLeft: '1px'}}>
                        <img src={require('../../../img/underline.png')} onClick={(event)=>props.format(event,'underline')} style = {{cursor:'pointer',height: "20px", width: '20px',padding:'3px'}}/>
                    </li>
                    <li style={{margin: 'auto'}}>
                      <button class='ss-button'   onClick={(event) => props.addComment(event, props.commentRef.current.innerHTML, props.toRef ? props.toRef.current.value : '' )}>
                        <span class="spinner-border" role="status" style={{color: 'white', height:'15px', width:'15px',position: 'relative',display: 'none' }} ref={props.spinnerRef}/>
                        <img src={require('../../../img/paper-plane.png')} style={{marginRight:'10px', maxWidth:'12px'}}/> 
                          Add {props.buttonText}
                      </button>
                    </li> 
                    {props.toRef ?
                      <li>
                        <span style={{verticalAlign: 'middle', display:' flex'}}><input type='checkbox' style={{float:'right', marginRight:'5px'}} name='private-comment' ref={props.privateCommentRef}/> Private Comment </span>
                      </li>
                     :''
                    } 
                  </ul>
                  <div ref={props.errorRef} style={{color: 'red'}}/>
                </div>
            </div>  
    )
}

export default ReplyBox;