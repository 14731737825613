import React from "react"
import {useRef, useState, setState, useEffect} from 'react';
import {useNavigate} from "react-router-dom"
import ReactDOMServer from 'react-dom/server';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
;
import './call_route.css'

function CallRouteCondition(props){
	const toConditionDropDownRef = useRef(null)
	const fromInputRef = useRef(null)
	const toInputRef = useRef(null)
	const fromSelectRef = useRef(null)
	const toSelectRef = useRef(null)
	const toSelectTypeRef = useRef(null)
	const INPUT_POS = 0
	const DROP_DOWN_POS = 1;
	const HIDDEN_INPUT = 2;
	const SPECIFIC_AGENT = 'Agent'
	const SPECIFIC_TEAM = 'Team'
	const ALL_AGENTS_SIM = 'All Agents Simultaneously'
	const ALL_AGENTS_SINGLE = 'All Agents One By One'
	const SPECIFIC_CONTACT = 'Contact'
	const SPECIFIC_COMPANY = 'Company'
	const CONTACT_GROUP = 'ContactGroup'
	const COMPANY_GROUP = 'CompanyGroup'
	const ANYBODY = 'Anybody'
	const ALL_AGENTS = 'AllAgents'
 	let fromSelectionUrl = {}
 	fromSelectionUrl[SPECIFIC_CONTACT] = constants.CONTACT_URL
 	fromSelectionUrl[SPECIFIC_COMPANY] = constants.COMPANY_URL
 	fromSelectionUrl[CONTACT_GROUP] = constants.CONTACT_GROUP_URL
 	fromSelectionUrl[COMPANY_GROUP] = constants.COMPANY_GROUP_URL
 	

  useEffect(()=>{
  	toSelectTypeRef.current.style.dispay = 'none'
  	if(props.data !== undefined){
  		if(props.data.fromSelection.toLowerCase() !== ANYBODY.toLowerCase()){
				axiosClient.getRequest(fromSelectionUrl[props.data.fromSelection]+props.data.fromValue)
				.then(function (response) {
				  fromSelectRef.current.value = props.data.fromSelection
				  fromInputRef.current.children[INPUT_POS].value = response.data.name
				  fromInputRef.current.children[HIDDEN_INPUT].value = response.data.id
				}).catch(function (response) {
					if(response.data)
				    	console.log(response.data.error)
				});	
		}else{
			fromSelectRef.current.value = props.data.fromSelection
			fromInputRef.current.children[INPUT_POS].disabled = true
			fromInputRef.current.children[HIDDEN_INPUT].value = '-1'
		}

		let url = null;
 	 if(props.data.toSelection === SPECIFIC_AGENT)
 	 	url = constants.USER_URL
 	else if(props.data.toSelection === SPECIFIC_TEAM)
 		 url = constants.TEAM_URL
 		if(url !== null){
			axiosClient.getRequest(url+props.data.toValue)
			.then(function (response) {
			  toSelectRef.current.value = props.data.toSelection
			  toSelectTypeRef.current.value=props.data.toSelectionType
			  toInputRef.current.children[INPUT_POS].value = response.data.name
			  toInputRef.current.children[HIDDEN_INPUT].value = response.data.id			
			}).catch(function (response) {
			    console.log(response.data)
			});
		}else{
			toSelectRef.current.value = props.data.toSelection
			toSelectTypeRef.current.value=props.data.toSelectionType
			toInputRef.current.children[INPUT_POS].disabled = true
			toInputRef.current.children[HIDDEN_INPUT].value = '-1'
		}
	}	
  });
function fromValueSelected(event)	{
	if(event.target.value !== null && event.target.value.toLowerCase() === ANYBODY.toLowerCase()){
		fromInputRef.current.children[INPUT_POS].disabled = true
	}
	else
		fromInputRef.current.children[INPUT_POS].disabled = false
	fromInputRef.current.children[INPUT_POS].value = ''
	fromInputRef.current.children[HIDDEN_INPUT].value = '-1'
}

function fetchFromData(event){
 fromInputRef.current.children[DROP_DOWN_POS].innerHTML = ''
 fromInputRef.current.children[DROP_DOWN_POS].style.display = 'none' 
 fromInputRef.current.children[HIDDEN_INPUT].value = '-1'
 if(event.target.value.length < 3)
 	return

  let fromSelection = document.getElementsByName('fromSelect-'+event.target.name.split('-')[1])[0]
	axiosClient.getRequest(fromSelectionUrl[fromSelection.value] +"search?keyword="+event.target.value)
	.then(function (response) {
		response.data.forEach((element, index)=>{
				addChildToFromDropdown(event,element,fromSelection.value)
      })		
	}).catch(function (response) {
	    console.log(response.data.error)
	});	
}
function addChildToFromDropdown(event,element,type){
 	  const DROP_DOWN_POS_CURR = 1;
 	  const HIDDEN_FIELD_CURR = 2;
	  let fromConditionDropDownRef = event.target.parentElement.children[DROP_DOWN_POS_CURR]
  	  // fromConditionDropDownRef.innerHTML = ''   
      let aTag = document.createElement('a')
      aTag.name=element.id      
      aTag.innerHTML = (element.fullName === undefined ? element.name : element.fullName )
      aTag.onclick = onSelectFromValueDropdown
      fromConditionDropDownRef.appendChild(aTag)
      fromConditionDropDownRef.style.display='inline'
}
function onSelectFromValueDropdown(event){  
  let id = event.target.name
  event.target.parentElement.parentElement.children[0].value = event.target.innerHTML //.split(";")[1]
  event.target.parentElement.parentElement.children[2].value = id //hidden input text
  event.target.parentElement.parentElement.children[1].style.display = 'none'
  // event.target.parentElement.parentElement.children[1].style.display = 'none'
}

function onSelectToValueDropdown(event){
  let id = event.target.name
  event.target.parentElement.parentElement.children[0].value = event.target.innerHTML
  event.target.parentElement.parentElement.children[2].value = id //hidden input text
  event.target.parentElement.innerHTML = ''
}

  function addChildToToDropdown(event,element){
 	  const DROP_DOWN_POS_CURR = 1;
 	  const HIDDEN_FIELD_CURR = 2;
	  // let toConditionDropDownRef = event.target.parentElement.children[DROP_DOWN_POS_CURR]
  	  toConditionDropDownRef.innerHTML = ''   
      let aTag = document.createElement('a')
      aTag.name=element.id
      aTag.innerHTML = (element.fullName === undefined ? element.name : element.fullName )
      aTag.onclick = onSelectToValueDropdown
      toConditionDropDownRef.current.appendChild(aTag)
      toConditionDropDownRef.current.style.display='inline'  
 }

function toValueSelected(event)	{
	let values = [ANYBODY , ALL_AGENTS_SIM, ALL_AGENTS_SINGLE, ALL_AGENTS]
	if(event.target.value !== null && values.includes(event.target.value)){
		toInputRef.current.children[INPUT_POS].disabled = true
	}
	else
		toInputRef.current.children[INPUT_POS].disabled = false
	toInputRef.current.children[INPUT_POS].value = ''
	toInputRef.current.children[HIDDEN_INPUT].value = '-1'
}

function fetchToData(event,element){
 const DROP_DOWN_POS_CURR = 1;
 const HIDDEN_FIELD_CURR = 2;
 event.target.parentElement.children[DROP_DOWN_POS_CURR].style.display = 'none'
 event.target.parentElement.children[DROP_DOWN_POS_CURR].innerHTML = '' 
 event.target.parentElement.children[HIDDEN_FIELD_CURR].value = -1
 if(event.target.value.length < 3)
 	return

 let url = null;
 let toSelectionRef = document.getElementsByName('toSelect-'+event.target.name.split('-')[1])[0]
 if(toSelectionRef.value === SPECIFIC_AGENT)
 	 url = constants.USER_URL
 else if(toSelectionRef.value === SPECIFIC_TEAM)
 	 url = constants.TEAM_URL
		axiosClient.getRequest(url +"search?keyword="+event.target.value)
		.then(function (response) {
			response.data.forEach((element, index)=>{
					addChildToToDropdown(event, element)
          })
			
		}).catch(function (response) {
		    console.log(response.data)
		});	
}

	return(
			<div style={{display:'flex', fontSize:'12px',marginTop:'10px'}}>
				<div style={{ padding:'20px',width:'50%'}}>					
				  <div style={{fontWeight: '800'}}>Incoming Call From</div>
				  <div>
					<select name= {'fromSelect-'+props.index} class="create-call-route-select" onChange={fromValueSelected} ref={fromSelectRef}>
						<option value="Contact">Contact</option>
						<option value="Company">Company</option>
						<option value="ContactGroup">Contact Group</option>
						<option value="CompanyGroup">Company Group</option>
						<option value="Anybody">Anybody</option>
					</select>
				  </div>
				  <div style={{marginTop:'10px'}}>
				  	<div style={{fontWeight: '800'}}>Route Call To</div>
					<select name={'toSelect-'+props.index} class="create-call-route-select" onChange={toValueSelected} ref={toSelectRef}>
						<option value="Agent">Agent</option>
						<option value="Team">Team</option>
						<option value="AllAgents">All Agents</option>
					</select>
					<select name={'toSelectType-'+props.index} class="create-call-route-select"  style={{marginTop: '20px',display:'none' }} ref={toSelectTypeRef}>
						<option value={ALL_AGENTS_SIM.replace(/ /g,'')}>{ALL_AGENTS_SIM}</option>
						<option value={ALL_AGENTS_SINGLE.replace(/ /g,'')}>{ALL_AGENTS_SINGLE}</option>
					</select>				
				  </div>
				</div>
				<div style={{padding:'20px',width:'50%'}}>
				  <div>	
				  <div style={{fontWeight: '800'}}>Value </div>			  				  	
					<div class="create-call-route-input-dropdown-content" ref={fromInputRef}>
						<input name={'fromInput-'+props.index} class="create-call-route-input" placeholder="Search ..." onChange={fetchFromData} />
						<div class="create-call-route-input-dropdown-menu"/>
						<input name={'fromInput-'+props.index+'-hidden'} style= {{display: 'none'}}/>
					</div>
				  </div>
				  <div style={{marginTop:'10px'}}>
				  	<div style={{fontWeight: '800'}}>Value </div>			  				  	
					<div class="create-call-route-input-dropdown-content" ref={toInputRef}>
						<input name={'toInput-'+props.index} class="create-call-route-input" placeholder="Search ..." onChange={fetchToData} />
						<div class="create-call-route-input-dropdown-menu" ref={toConditionDropDownRef}/>
						<input name={'toInput-'+props.index+'-hidden'} style={{display: 'none'}}/>
					</div>
				  </div>
				</div>				

		</div>		
	)


}

export default CallRouteCondition;