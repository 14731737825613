import React, { useEffect, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as axiosClient from "../../../js/Axios"
import * as constants from '../../../js/constants'
import {useNavigate, useParams} from "react-router-dom"
import TicketMergeSearchLine from './TicketMergeSearchLine'

function TicketMergeWindow(props){  
  const errorRef = useRef(null)
  const dropdownRef = useRef(null)
  const spinnerRef = useRef(null)
  const nameRef = useRef(null)
  const params = useParams()
  const [ticketsList, setTicketsList] = useState([])
  const [selectedTicketsList, setSelectedTicketsList] = useState([])
  const [ids, setIds] = useState([])
  const selectedTicketsRef = useRef(null)
  const REPORTER_DROP_DOWN_INPUT_POS = 0;
  const REPORTER_DROP_DOWN_MENU_POS = 1;
  const REPORTER_DROP_DOWN_HIDDEN_INPUT_POS = 2;
  useEffect(() => {
    dropdownRef.current.style.display = 'none'
    errorRef.current.style.display = 'none'
    spinnerRef.current.style.display = 'none'    
  
  },[]);

  
function displayError(msg){
  errorRef.current.innerHTML = "<span style='color:red'>*&nbsp;&nbsp;"+msg+"</span>"
  errorRef.current.style.display='block'
  errorRef.current.scrollIntoView()
}
  function handleSubmit(event){  
    event.preventDefault()
    if(ids.length === 0){
      displayError('No Tickets Selected')
      return
    }

    errorRef.current.style.display = 'none'
    spinnerRef.current.style.display = 'inline-block'
    let payload = {'id': params.id, 'ticketIds':ids}
        axiosClient.postRequest(constants.TICKET_URL+'merge_tickets', payload)
        .then(function (response) {    
          props.closeMergeWindow();
          window.location.reload()    
        }).catch(function (error) {
            console.log("Error is "+error)
            errorRef.current.innerHTML = error.response.data.error
            errorRef.current.style.display = 'block'
            spinnerRef.current.style.display = 'none'
        });
  
  }

function searchTickets(event){
  event.target.parentElement.children[1].innerHTML = ''
  dropdownRef.current.innerHTML = ''
  axiosClient.getRequest(constants.TICKET_URL+"search_for_merge?keyword="+event.target.value)
    .then(function (response) {
    response.data.map((element, index) => (
      addChildToTicketDropdown(event, element)    
    ));
    }).catch(function (response) {
        console.log(response);
    });
}

  function onSelectTicketDropdown(event, ticket){
    let innerDiv = document.createElement('div')
    innerDiv.innerHTML = ReactDOMServer.renderToStaticMarkup(<TicketMergeSearchLine ticket={ticket}/>)
    let outerDiv = document.createElement('div')
    outerDiv.className = 'contact-group-selected-contact'
    let innerDivRight = document.createElement('div')
    innerDivRight.style.float = 'right'
    innerDivRight.style.cursor = 'pointer'
    innerDivRight.innerHTML = '&times;'
    innerDivRight.onclick = (event) => removeSelectedTicket(event, ticket.id)
    outerDiv.appendChild(innerDivRight)
    outerDiv.appendChild(innerDiv)
    selectedTicketsRef.current.appendChild(outerDiv)
    let idsTemp = ids;
    idsTemp.push(ticket.id)
    setIds(idsTemp)
    dropdownRef.current.innerHTML = ''
    dropdownRef.current.style.display = 'none'           
  }

function removeSelectedTicket(event, id){
  console.log('removeSelectedTicket '+id)
    let children = selectedTicketsRef.current.children  
    if(children.length === 0)
      return
    for(var i=0;i<children.length; i++){
      if(event.target.parentElement === children[i]){
        let idsTemp = ids;
        selectedTicketsRef.current.removeChild(children[i])
        const index = idsTemp.indexOf(id);
         if (index > -1) {
              idsTemp.splice(index, 1);
          }
          setIds(idsTemp) 
          console.log('after delete '+idsTemp)           
        break
      }
    }
  }

  function addChildToTicketDropdown(event, element){ 
      if(ids.includes(element.id))
        return;
      if(element.id === params.id)
        return
      let temp = ticketsList
      let div = document.createElement('div')
      div.innerHTML = ReactDOMServer.renderToStaticMarkup(<TicketMergeSearchLine ticket={element}/>)
      dropdownRef.current.appendChild(div)
      div.onclick = (event) => onSelectTicketDropdown(event, element)
      event.target.parentElement.children[REPORTER_DROP_DOWN_MENU_POS].style.display = 'block'
      dropdownRef.current.style.display = 'block'
  }


  return (
    <div class='Ticket-Left-Box ticket-merge-box ' style={{zIndex:'10000 !important'}}>
      <form onSubmit={handleSubmit}>          
          <div style={{boxShadow:'0 0 2px grey', padding:'30px', paddingleft:'0px',backgroundColor:'white',height: 'calc(100vh - 105px', overflowY: 'auto'}}>
              <a href="javascript:void(0)" class="create-closebtn" onClick={props.closeMergeWindow}>&times;</a>
              <div ref={errorRef} name="error"/>
              <h8><b>Merge Tickets</b> </h8>
              <div class='create-line'></div>
              <div class='ticket-merge-message-box'>
                This Ticket is the master ticket into which secondary tickets are merged 
              </div>
              <div class='create-form-label' style={{ marginTop: "3%"}}>Search For Secondary Tickets:<p class='red-color'>*</p></div>
              <div style={{position: 'relative'}}>
                <input type="text" name="tickets" style={{width: "90%"}}  placeholder={'Search For Secondary Tickets'} onChange={searchTickets}/>
                <div style={{position: 'absolute'}} class='create-dropdown-content' ref={dropdownRef}>
                  {
                    ticketsList.map((element, index) => (          
                      <TicketMergeSearchLine ticket = {element}/>
                    )) 
                  }
                </div>                
              </div>              
              <div style = {{ marginTop: '100px'}} ref={selectedTicketsRef}  class='team-selected-agents-box'>
                { selectedTicketsList}              
              </div>              
          </div>
          <div style={{position:'absolute',bottom:'0px',height:'75px',width:'100%'}}>
            <button class='ss-button'  class='create-button' style={{marginLeft:'30%'}}>Merge Tickets</button>
            <span class="spinner-border" role="status" style={{position:'relative',top:'10px',right:'75px', color: 'black', height:'25px', width:'25px',zIndex:'100'}} ref={spinnerRef}/>
          </div>               
      </form>            
    </div>

    );
}

export default TicketMergeWindow;