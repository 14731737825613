import React, { useState, useEffect, useRef,useContext } from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../js/constants'

function CallLine(props){

function fetchFromNumber(){

}
function navigateToUser(event, id){
	event.preventDefault();
	window.location.href='/agents/'+id
}
return (
		<div style={{borderRadius: '6px', padding:'10px',  borderRadius:'7px',width:'96%', marginTop:'10px', fontWeight:'600',color: 'white' }} class='call-window-contact'>
            <div style={{display:'inline-block', color: 'white',fontSize: '12px',width:'150px',overflow:'hidden',textOverflow:'ellipsis',whiteSpace: 'nowrap',maxWidth:'50%',marginRight:'20px' }}>
	            	{(props.call.contact !== null && props.call.contact !== 'null') ? <a href='' style={{textDecoration:'none', color:'white'}} >{props.call.contact.name}</a> : 'Unknown'}</div>
            <div style={{cursor:'pointer', color: 'white',fontSize: '12px',textAlign:'right',float:'right' }} onClick={()=>props.callNumber(props.call.otherPhoneNumber)}>{props.call.otherPhoneNumber}</div>
		</div>
    );

}

export default CallLine;