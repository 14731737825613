import axios from 'axios';
import * as constants from './constants'

const axiosClient = axios.create();
const axiosExternalClient = axios.create()
let baseURL = ''
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    baseURL = 'http://localhost:8080/v1';
    axiosClient.defaults.baseURL = 'http://localhost:8080/v1/';
    axiosClient.defaults.headers = {
      'id_token': localStorage.getItem('id_token'),
      'Content-Type' :'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:3000',
      'Access-Control-Allow-Methods' : 'GET, POST, OPTIONS, PATCH',
      'Access-Control-Allow-Headers' : 'Origin, X-Requested-With, Content-Type, Accept' ,
      'Access-Control-Expose-Headers': 'correlationid'
    };    
} else {
    if(process.env.REACT_APP_UI_ENV && process.env.REACT_APP_UI_ENV === 'staging'){
          baseURL = 'https://api.support-spoc.com/v1';
          axiosClient.defaults.baseURL = 'https://api.support-spoc.com/v1';
          axiosClient.defaults.headers = {
            'id_token': localStorage.getItem('id_token'),
            'Content-Type' :'application/json',
            'Access-Control-Allow-Origin': 'https://app.support-spoc.com',
            
            'Access-Control-Allow-Methods' : 'GET, POST, OPTIONS, PATCH',
            'Access-Control-Allow-Headers' : 'Origin, X-Requested-With, Content-Type, Accept' ,
            'Access-Control-Expose-Headers': 'correlationid'
          };
    }else{
          baseURL = 'https://api.supportspoc.com/v1';
          axiosClient.defaults.baseURL = 'https://api.supportspoc.com/v1';
          axiosClient.defaults.headers = {
            'id_token': localStorage.getItem('id_token'),
            'Content-Type' :'application/json',
            'Access-Control-Allow-Origin': 'https://app.supportspoc.com',
            
            'Access-Control-Allow-Methods' : 'GET, POST, OPTIONS, PATCH',
            'Access-Control-Allow-Headers' : 'Origin, X-Requested-With, Content-Type, Accept' ,
            'Access-Control-Expose-Headers': 'correlationid'
          };    
    }    
}


//All request will wait 2 seconds before timeout
axiosClient.defaults.timeout = 10000;

axiosClient.defaults.withCredentials = true;

export  function getRequest(URL) {
   return axiosClient.get(baseURL+`/${URL}`).then(response => response);
}

export function postRequest(URL, payload, headers) {
  return axiosClient.post(baseURL+`/${URL}`, payload, headers).then(response => response);
}
export function patchRequest(URL, payload) {
  return axiosClient.patch(baseURL+`/${URL}`, payload).then(response => response);
}

export function deleteRequest(URL) {
  return axiosClient.delete(baseURL+`/${URL}`).then(response => response);
}

export function postExternalRequest(URL, payload, headers) {
  return axiosExternalClient.post(URL, payload,headers).then(response => response);
}
export function putExternalRequest(URL, payload, headers) {
  return axiosExternalClient.put(URL, payload,headers).then(response => response);
}
export function getExternalRequest(URL, payload, headers) {
  return axiosExternalClient.get(URL, payload,headers).then(response => response);
}
export function headExternalRequest(URL, payload, headers) {
  return axiosExternalClient.head(URL, payload,headers).then(response => response);
}

// axios.interceptors.request.use((config) => {
//   config.params = {...config.params }
//   return config
// })
axiosClient.interceptors.request.use(function (request) {
  return request;
}, null, { synchronous: true });

axiosClient.interceptors.request.use(function (request) {
  if((request.url.endsWith('v1/accounts/') && request.method === 'post')||
     request.url.includes('/activate/'))
      return request
  if(request.url.endsWith('v1/login/') || 
     request.url.endsWith('v1/login')){
    request.headers['id_token'] = localStorage.getItem('id_token')
  }
  if((localStorage.getItem('id_token') === null)&&
    !window.location.href.includes('/login'))
  {    
    window.location.href='/login'
    return
  }
  return request
});

axiosClient.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if(error.response !== undefined && error.response.status === 401){ 
        if(error.response.data !== undefined && error.response.data.message === 'INVALID_TOKEN')
          {  
            console.log('INVALID_TOKEN in client... Redirecting to Login page ') 
            localStorage.removeItem('id_token')
            if(!window.location.href.includes('/login')){
              window.location.href='/login'
              return
            }else{
              return error.response
            }
                
            
          }                 
    }
    return Promise.reject(error);
});


export default axiosClient;