import React, { useState, useEffect, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import * as ConstantStrings from '../../../js/ConstantStrings'
import '../../../css/common.css'
import '../css/contact.css'
import ContactLine from "./ContactLine"
import ContactListDisplayHeader from './ContactListDisplayHeader'
import Pagination from '../../Pagination'
import CreateContactForm from './CreateContactForm'
import { useLocation } from 'react-router-dom';
import {useNavigate, useParams} from "react-router-dom"
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ContactsListDisplay(){
	const [count, setCount] = useState(null)
	const editIdRef = useRef(-1)
	const [filter, setFilter] = useState('all')
	const [search, setSearch] = useState('')
	const [pageNo, setPageNo] = useState(0)
	const [pageSize, setPageSize] = useState(25)
	const [contactLine, setContactLine] = useState("")
	const primaryCheckBoxRef = useRef(null)
	const [ids, setIds] = useState([])
	const groupButtonsRef = useRef(null)
	const [showCreateContactForm, setShowCreateContactForm] = useState(false)
	const location = useLocation();
	const navigate = useNavigate(); 
	const params = useParams();
	const [sessionData, setSessionData] = useState(null)
	useEffect(() => {
		loadContacts(pageNo, pageSize);
		groupButtonsRef.current.style.visibility = 'hidden'	
		setSessionData(JSON.parse(sessionStorage.getItem('ss_sess')))
		if(params.id !== undefined){
			editIdRef.current = params.id
			setShowCreateContactForm(true)
		}
	},[])//use effect

function loadContacts(pn,ps){
		axiosClient.getRequest(constants.CONTACT_URL+'?pageNo='+pn+'&pageSize='+ps)
		.then(function (response) {
		let contactList = null;
		contactList = response.data.map((element, index) => (									
						<ContactLine contact= {element} checkBoxClicked={checkBoxClicked} openContact={openContact}/>
		));		
		if(Object.keys(contactList).length === 0){
			setCount(0)
			setContactLine('No Records Found...')
		}else{
			setContactLine(contactList)
			let countTemp = response.data[0].count;
			setCount(countTemp)
		}
	}).catch(function (error) {
	    console.log("Response" + error);
	});
}


function searchContacts(event,pn){
	setSearch(event.target.value)
	setPageNo(pn)
	axiosClient.getRequest(constants.CONTACT_URL+"search?keyword="+encodeURIComponent(event.target.value)+'&pageNo='+pn)
	.then(function (response) {
		let contactList = null;
		contactList = response.data.map((element, index) => (					
						<ContactLine contact= {element} checkBoxClicked={checkBoxClicked} openContact={openContact}/>					           
		));
		if(Object.keys(contactList).length === 0){
			setCount(0)
			setContactLine('No Records Found...')
		}else{
			setContactLine(contactList)
			let countTemp = response.data[0].count;
			setCount(countTemp)
		}	
	}).catch(function (response) {
	    console.log(response);
	});	
}

function filterContactsWithSearch(search, pn){
		setPageNo(pn)
		axiosClient.getRequest(constants.CONTACT_URL+'filter_with_search?keyword='+filter+'&pageNo='+pn+'&search='+search)
		.then(function (response) {
			let contactList = null;
			contactList = response.data.map((element, index) => (					
							<ContactLine contact= {element} openContact={openContact} checkBoxClicked={checkBoxClicked}/>					           
			));
			if(Object.keys(contactList).length === 0){
				setCount(0)
				setContactLine('No Records Found...')
			}else{		
				let countTemp = response.data[0].count;
				setCount(countTemp)		
				setContactLine(contactList)
			}	
		}).catch(function (response) {
		    console.log(response)
		})	
}
function filterContacts(type,pn){
	setPageNo(pn)
	axiosClient.getRequest(constants.CONTACT_URL+'filter?keyword='+type+'&pageNo='+pn)
		.then(function (response) {
			let contactList = null;
			contactList = response.data.map((element, index) => (					
							<ContactLine contact= {element} openContact={openContact} checkBoxClicked={checkBoxClicked}/>					           
			));
			if(Object.keys(contactList).length === 0){
				setCount(0)
				setContactLine('No Records Found...')
			}else{		
				let countTemp = response.data[0].count;
				setCount(countTemp)		
				setContactLine(contactList)
				setFilter(type)
			}	
		}).catch(function (response) {
		    console.log(response)
		})
}

function showCreateConactFormFun(){
	setShowCreateContactForm(true)
}
function displayMessage(type){
	let message;
	if(type === 'delete')
		message = ConstantStrings.DELETE_CONTACT_SUCCESS
	else if(type === 'block')
		message = ConstantStrings.BLOCK_CONTACT_SUCCESS
	else if(type === 'spam')
		message = ConstantStrings.SPAM_CONTACT_SUCCESS
	toast.success(message)
}
function performAction(type){	
	let payload = {'ids': ids}
	let url = null;
	if(type === 'delete')
		url = constants.CONTACT_URL+'delete';
	else if(type === 'block')
		url = constants.CONTACT_URL+'block';
	else if(type === 'spam')
		url = constants.CONTACT_URL+'spam';

//TODO: block becomes unblock
   	axiosClient.postRequest(url, payload)
       .then(function (response) {
           loadContacts(pageNo, pageSize);
		let elements = document.getElementsByClassName('contact-line-checkbox')
		for (var i = 0; i < elements.length; i++) {
			if(elements[i].checked)
				elements[i].checked = false;		
		}
			displayMessage(type)
       }).catch((error) => {
           console.log(error)
       }); 
       groupButtonsRef.current.style.visibility = 'hidden'     
}
function closeCreateWindow(load){
	setShowCreateContactForm(false)
	editIdRef.current = -1
	if(load)
		loadContacts(0,25)
}

function displayIds(){
	console.log('displayIds')
}
function onPrimaryCheckboxClicked(event){
	let idsTemp = ids;
	let checkBoxes = document.getElementsByClassName('contact-line-checkbox')
	for(var i=0;i<checkBoxes.length;i++){
		checkBoxes[i].checked = event.target.checked
		let id = checkBoxes[i].name.split('-')[1]
		if(event.target.checked && !idsTemp.includes(id)){
			idsTemp.push(id)
			groupButtonsRef.current.style.visibility = 'visible'				
		}
	}
	if(!event.target.checked){	
		groupButtonsRef.current.style.visibility = 'hidden'
		idsTemp.splice(0,idsTemp.length)
	}
	setIds(idsTemp)

}

function checkBoxClicked(id, value){
	let idsTemp = ids;
	if(value && !idsTemp.includes(id)){
		idsTemp.push(id)
	}else{
		 const index = idsTemp.indexOf(id);
		if (index > -1) {
  			idsTemp.splice(index, 1);
		}
	}
	setIds(idsTemp)
	if(idsTemp.length !== 0){		
		groupButtonsRef.current.style.visibility = 'visible'
	}else{
		groupButtonsRef.current.style.visibility = 'hidden'
	}
	if(!value){
		primaryCheckBoxRef.current.checked = false
	}
	console.log('checkBoxClicked '+ idsTemp)
}

function handlePagination(number){
	console.log('handlePagination '+ number)
	setPageNo(number)
	if(filter ==='all'){
		if(search === '')
			loadContacts(number, pageSize)
		else{
			let event={'target':{'value': search }}
			searchContacts(event, number)
		}
	}
	else if(filter !== 'all'){
		if(search === '')
			filterContacts(filter, number)
		else
			filterContactsWithSearch(search, number)
	}	
}

function openContact(id){
	console.log('openContact '+id)
	editIdRef.current = id
	setShowCreateContactForm(true)	
}
return (
		<>
			<ContactListDisplayHeader searchContacts={searchContacts}
									  filterContacts={filterContacts}
									  showCreateConactFormFun={showCreateConactFormFun}
									  groupButtonsRef={groupButtonsRef}
									  performAction={performAction}/>
		
		<div class="main-body" >
		   <div class="page-bodyyy" >
		<div class="container" style={{minWidth:'100%',marginLeft:'0px', marginRight:'0px'}}>
         <div class="row">
            <div class="col-lg-12">
               <div class>
                  <div class="table-responsive">
                     <table class="table table-nowrap align-middle table-borderless alternate-border-color" >
                        <thead>
                           <tr>
                              <th style={{backgroundColor:'#f4f4f4'}} scope="col" class="ps-4">
                                 <div class="form-check font-size-16"><input type="checkbox" class="form-check-input" id="contacusercheck" ref={primaryCheckBoxRef} style={{border:'1px solid #A9A9A9'}} onClick={onPrimaryCheckboxClicked}/></div>
                              </th>
                              <th style={{backgroundColor:'#f4f4f4'}} scope="col">Name</th>
                              <th style={{backgroundColor:'#f4f4f4'}} scope="col">Email</th>
                              <th style={{backgroundColor:'#f4f4f4'}} scope="col">Mobile</th>
                              <th style={{backgroundColor:'#f4f4f4'}} scope="col">WorkPhone</th>
                              <th style={{backgroundColor:'#f4f4f4'}} scope="col">Unique Id</th>
							  <th style={{backgroundColor:'#f4f4f4'}} scope="col">Company</th>                              
                           </tr>
                        </thead>
                        <tbody>
                        	{contactLine}                    
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>         
      </div>
				   <Pagination count={count} pageNo={pageNo}
				   					 handlePagination={handlePagination}/>	   	
				</div>				
			</div>
			{ showCreateContactForm ? <CreateContactForm closeCreateWindow= {closeCreateWindow} editIdRef={editIdRef}/> : '' }
			<ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}
                newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss
                draggable pauseOnHover theme="colored"/>			
		</>
 );

}
export default ContactsListDisplay;
