import React, { useEffect, useRef, useState} from 'react';
import ReactDOMServer from 'react-dom/server';
import * as axiosClient from "../../../js/Axios"
import * as constants from '../../../js/constants'
import {useNavigate} from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import * as timezones from "../../../js/timezones"

function CreateUserForm(props){
  const emailRef = useRef(null)
  const [timezonesArr, setTimezonesArr] = useState([])
  const timezoneRef = useRef(null)
  const imageRemoveRef = useRef(null)
  const [imageModified, setImageModified] = useState(false)
  const roleRef = useRef(null)
  const mobileRef = useRef(null)
  const nameRef = useRef(null)
  const spinnerRef = useRef(null)
  const uniqueIdentifierRef = useRef(null)
  const [image, setImage] = useState(null)
  const [user, setUser] = useState(null)
  const uploadImageRef = useRef(null)  
  const workPhoneRef = useRef(null)
  const addWorkPhoneLink = useRef(null)
  const removeWorkPhoneLink = useRef(null)
  const addressRef = useRef(null)
  const addAddressLink = useRef(null)
  const removeAddressLink = useRef(null)
  const errorRef = useRef(null)
  const navigate = useNavigate()
  let error = ''
  
  useEffect(() => {
    populateTimezones()
    spinnerRef.current.style.display='none'
    imageRemoveRef.current.style.display='none'
    removeWorkPhoneLink.current.style.visibility = 'hidden'
    let editFilled = false;    
    if(props.profile || isEdit()){
      if(editFilled)
        return
      editFilled = true
      loadUserProfile()
      return
    }
    timezoneRef.current.value = '(UTC) Coordinated Universal Time' //sessData.timezone      
  },[])//use effect 

useEffect(()=>{
  if(!(props.profile || isEdit()))
      timezoneRef.current.value = '(UTC) Coordinated Universal Time' //sessData.timezone      
},[timezonesArr] )

function populateTimezones(){
    let timezonesArr1 = timezones.timezones.split(';')
    setTimezonesArr(timezonesArr1)    
}
function isEdit(){
  return props.editIdRef !== undefined && props.editIdRef.current !== undefined && props.editIdRef.current !== -1
}
function loadUserProfile(){
    let userId = null
    if(props.profile){
        let sessionStorageJSON = JSON.parse(sessionStorage.getItem('ss_sess'))
        userId= sessionStorageJSON.id
    }else{
      userId = props.editIdRef.current
    } 
      axiosClient.getRequest(constants.USER_URL+userId)
      .then(function (response) {
        setUser(response.data)
        if(response.data.image !== 'null'){
          document.getElementById("drop-area").style.backgroundImage=`url(${response.data.image})`;
          uploadImageRef.current.style.display='none'
          imageRemoveRef.current.style.display='inline-block'
        }
        nameRef.current.value = response.data.name
        emailRef.current.value = response.data.email
        emailRef.current.disabled = true
        mobileRef.current.value = (response.data.mobile_number === 'null' ? '' : response.data.mobile_number)
        addressRef.current.value = (response.data.address === 'null' ? '' : response.data.address.replaceAll("<br/>","\n"))
        workPhoneRef.current.children[0].value = (response.data.workPhone !== 'null' ? response.data.workPhone : '')
        timezoneRef.current.value = response.data.timezone
        if(response.data.workPhone1 !== 'null'){
          addWorkPhoneField()
          workPhoneRef.current.children[1].value = response.data.workPhone1
        }
        if(response.data.workPhone2 !== 'null'){
          addWorkPhoneField()
          workPhoneRef.current.children[2].value = response.data.workPhone2
        }
        if(!props.profile){
          roleRef.current.value = (response.data.role === 'null' ? '' : response.data.role)
          // uniqueIdentifierRef.current.value = (response.data.uniqueIdentifier === 'null' ? '' : response.data.uniqueIdentifier)
        }
      }).catch(function (error) {
        if(error.response.data.error === "USER_DOESNOT_EXIST"){
          toast.error('User does not exist')
          props.closeCreateWindow()
          return
        }        
          console.log(error);
      });  
}
function validateEmail(email){
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

function validatePhoneNumber(number){
  return String(number)
    .toLowerCase()
    .match(
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
    );
}

function workPhonesHaveSameValue(event){
    let workPhones = [];
    if(event.target.workPhone.value !== '')workPhones.push(event.target.workPhone.value)
    if(event.target.workPhone1 !== undefined && event.target.workPhone1.value !== '')workPhones.push(event.target.workPhone1.value)
    if(event.target.workPhone2 !== undefined && event.target.workPhone2.value !== '')workPhones.push(event.target.workPhone2.value)
    if(workPhones.length < 2)  
      return false;
    if(workPhones.length == 2){
          return workPhones[0] === workPhones[1]
    }
    if(workPhones.length == 3){
          return (workPhones[0] === workPhones[1] ||
   workPhones[2] === workPhones[1] ||
   workPhones[0] === workPhones[2])
    }
}

  function handleSubmit(event){
    console.log("handleSubmit "+event.target.email.value)
    event.preventDefault()
    errorRef.current.style.display = 'none'
    
    let address = [{},{},{}]
    let company = {}
    if(error !== ''){
      displayError(error)
      return
    }    
    //validations
    if(event.target.email.value !== '' && !validateEmail(event.target.email.value)){
      console.log("Invalid email id")
        errorRef.current.innerHTML = 'Invalid Email id'
        errorRef.current.style.display = 'block'
        return
    }
    if(event.target.mobileNumber.value !== '' && !validatePhoneNumber(event.target.mobileNumber.value)){
      console.log("Invalid email id")
        errorRef.current.innerHTML = 'Invalid Phone number'
        errorRef.current.style.display = 'block'
        return
    }
    if(workPhonesHaveSameValue(event)){
        console.log("Work Phones has same value")
        errorRef.current.innerHTML = 'Repeated Work Phone numbers present'
        errorRef.current.style.display = 'block'
        return        
    }
    //create payload
    spinnerRef.current.style.display='inline-block'
    let payload = {"fullName":event.target.fullName.value,
     "email" : event.target.email.value,
     "imageModified":imageModified,
     "workPhone": event.target.workPhone.value,
     "mobileNumber": event.target.mobileNumber.value,
     "role": props.profile ? null : event.target.role.value,
     "address": event.target.address.value,
     "workPhone":event.target.workPhone !== undefined ? event.target.workPhone.value : null,
     "workPhone1":event.target.workPhone1 !== undefined ? event.target.workPhone1.value : null,
     "workPhone2":event.target.workPhone2 !== undefined ? event.target.workPhone2.value : null,
     'timezone': timezoneRef.current.value
   } 
    let userId = null
    if(props.profile){
        let sessionStorageJSON = JSON.parse(sessionStorage.getItem('ss_sess'))
        userId= sessionStorageJSON.id
    }else{
      userId = props.editIdRef.current
    } 
    if(image !== null)
        payload['image'] = image.name
    if(!isEdit() && !props.profile ){
        axiosClient.postRequest(constants.USER_URL, payload)
        .then(function (response) {      
          if(image !== null){
              axiosClient.putExternalRequest(response.data.image, image,{headers: {
                'Content-Type': 'multipart/form-data',
                'Content-Length': image.size
              }})
              .then(function (response2) {
                  spinnerRef.current.style.display = 'none'
                  toast.success('User created ...')
                  props.closeCreateWindow()
              }).catch((error) => {
                    spinnerRef.current.style.display = 'none'
                    toast.success('User created ...')
                    props.closeCreateWindow(true)  
              })  
          } else{
                    spinnerRef.current.style.display = 'none'
                    toast.success('User created ...')
                    props.closeCreateWindow(true)  
          }
        }).catch((error) => {
          console.log(error)
            if(error.response.status === 400){
              if(error.response.data.error === '')
                displayError('Error in processing the request')
              else
                displayError(error.response.data.error)
            }else{
              displayError('Error in processing the request')
            }
            spinnerRef.current.style.display = 'none'        
        });
    }else{
      axiosClient.patchRequest(constants.USER_URL+userId, payload)
        .then(function (response) {      
          if(image !== null){
              axiosClient.putExternalRequest(response.data.image, image,{headers: {
                'Content-Type': 'multipart/form-data',
                'Content-Length': image.size
              }})
              .then(function (response2) {            
                    toast.success('User Updated')          
                    spinnerRef.current.style.display = 'none'
              }).catch((error) => {
                spinnerRef.current.style.display = 'none'
              })  
          } else{
                    toast.success('User Updated')          
                    spinnerRef.current.style.display = 'none'
          }
        }).catch((error) => {
          console.log(error)
            if(error.response.status === 400){
              if(error.response.data.error === '')
                displayError('Error in processing the request')
              else
                displayError(error.response.data.error)
            }else{
              displayError('Error in processing the request')
            }
            spinnerRef.current.style.display = 'none'        
        });
    }
}


function checkIfEmailExist(event){
  return
  console.log('checkIfEmailExist')
  errorRef.current.style.dislay='none';
  axiosClient.getRequest(constants.USER_URL+'email_check?email='+event.target.value)
  .then(function (response) {
    console.log(response.data+'  ')
    if(response.data === true){
      displayError('Email id already used...')
      error = 'Email id already used...'
    }else{
      error = ''
    }
  }).catch(function (response) {
      console.log(response);
  });
}


  function addWorkPhoneField(){
    console.log("addNewAddressField "+workPhoneRef.current.children.length)
      if(workPhoneRef.current.children.length >= 3)
        return
      // let div = document.createElement('div')
      let input = document.createElement('input')
      input.style.width = '90%'
      input.style.marginTop = '20px'
      input.name = "workPhone"+workPhoneRef.current.children.length
      input.placeholder = 'Work Phone'
      // div.innerHTML = '<input type="text" name="workPhone'+workPhoneRef.current.children.length+'"  placeholder = "Work Phone" style={{width: "35%",  borderRadius: "5px"}} />'
      workPhoneRef.current.appendChild(input)
      removeWorkPhoneLink.current.style.visibility = 'visible'
      if(workPhoneRef.current.children.length  >= 3)
        addWorkPhoneLink.current.style.visibility='hidden'
  }

  function removeWorkPhoneField(){
         if(workPhoneRef.current.children.length <= 1){
          removeWorkPhoneLink.current.style.visibility = 'hidden'
          return
         }
         workPhoneRef.current.removeChild(workPhoneRef.current.lastChild)
         addWorkPhoneLink.current.style.visibility='visible'
         if(workPhoneRef.current.children.length <= 1)
            removeWorkPhoneLink.current.style.visibility = 'hidden'
  }

function inputClick(event){
  if('focus' === event.type)
    event.target.style.borderColor = 'blue'
  if('blur' === event.type)
    event.target.style.borderColor = 'black'
}
function displayError(msg){
  errorRef.current.innerHTML = "<span style='color:red'>*&nbsp;&nbsp;"+msg+"</span>"
  errorRef.current.style.display='block'
  errorRef.current.scrollIntoView()
}
function removeImage(event){
  event.preventDefault()
  document.getElementById("drop-area").style.backgroundImage = null;
  setImage(null)
  uploadImageRef.current.style.display='inline'
  imageRemoveRef.current.style.display='none'
  setImageModified(true)
}
function uploadUserImage(event){
  event.target.setCustomValidity('')
    if(Object.keys(event.target.files).length === 0){
      return
    }
    uploadImageRef.current.style.display='none'
    if(event.target.files[0].size > 2000000){
      event.target.setCustomValidity('Maximum image size is 2MB')
      return
    }
    setImage(event.target.files[0])
    var imgLink = URL.createObjectURL(event.target.files[0])
    document.getElementById("drop-area").style.backgroundImage=`url(${imgLink})`;
    imageRemoveRef.current.style.display='block'
    setImageModified(true)
}   

  return (
        <div class="create-wrapper" >          
            <form onSubmit={handleSubmit}>
              <div style={{boxShadow:'0 0 2px grey', padding:'30px', paddingleft:'0px',backgroundColor:'white',height: 'calc(100vh - 105px', overflowY: 'scroll'}}>
                <a href="javascript:void(0)" class="create-closebtn" onClick={props.closeCreateWindow}>&times;</a>
                <h8 style={{color:'#044caf',fontWeight:'800', fontSize:'20px'}}><b>{props.profile ? 'Update Profile' : '+ Create Agent' }</b> </h8>
                <div ref={errorRef}  class='create-error'/>              
                <div class='create-line'></div>
                <div class = 'create-display-image-wrapper'>
                  <div class='drop-area-wrapper'>                          
                    <label for="input-file" id="drop-area" onChange={uploadUserImage}>
                        <div>
                            <img src={require('../../../img/placeholder.png')} ref={uploadImageRef}/>
                        </div>
                      <input type="file" accept=".png, .jpg, .jpeg" id="input-file" hidden name='image'/>                  
                    </label>              
                  </div>                    
                  <div class='create-form-label' style={{marginLeft: '2%', color: '#2e2f32',fontSize:'18px' }}>
                    {props.profile ? 'Upload Image' : 'Upload Image of the Agent' }                    
                  </div>                  
              </div>
              <div ref={imageRemoveRef}><a href='' style={{fontSize:'14px',textDecoration:'none',marginLeft:'50px' }} onClick={removeImage}>Remove</a></div>
              <div class='create-form-label' style={{ marginTop: "3%"}}>Full Name:<p class='red-color'>*</p></div>
              <div><input type="text" name="fullName" style={{width: "90%"}} onFocus={inputClick} onBlur={inputClick} placeholder='Full Name' required ref={nameRef}/></div>
              <div style={{ marginTop: "3%"}} class='create-form-label'>Email:<p class='red-color'>*</p></div>
              <div><input type="email" name="email" onBlur={checkIfEmailExist} style={{width: "90%", outline: 'none' }}  placeholder='Email' required ref={emailRef}/></div>
              <div style={{marginTop: "3%"}} class='create-form-label'>Mobile Number:</div>
              <div><input type="text" name="mobileNumber"  style={{width: "90%"}} onFocus={inputClick} onBlur={inputClick} placeholder={'Mobile Number'} ref={mobileRef}/></div>
              <div style={{marginTop: "3%"}} class='create-form-label'> WorkPhone:
                   <div ref={workPhoneRef}> 
                     <input type="text" name="workPhone"  style={{width: "90%"}} placeholder={'Work Phone'}/>
                   </div>
                     <a style={{fontSize: '15px', fontWeight: 'bolder'}} href="#" onClick={addWorkPhoneField} ref={addWorkPhoneLink} >Add Another</a>
                     <a style={{fontSize: '15px', fontWeight: 'bolder', left: '50%',position:'relative'}}href="#" onClick={removeWorkPhoneField} ref={removeWorkPhoneLink}>Remove </a>
              </div>
               
              {
                props.profile ? '' : 
                <div>
                  <div style={{marginTop: "3%"}} class='create-form-label'> Role:</div>
                        <div><select name="role" style={{width: '90%',padding: '5px',borderRadius:'5px'}} ref={roleRef}>
                              <option value="agent">Agent</option>      
                              <option value="admin">Admin</option>
                              <option value="Account Owner">Account Owner</option>
                            </select>          
                        </div>             
                  </div>
              }
                <div style={{ marginTop: "3%"}} class='create-form-label'>
                   Timezone:</div>
                <div>
                  <select style={{width:'90%', height:'34px'}} ref={timezoneRef}>
                         {
                            timezonesArr.map((option, index) => 
                                  <option key={index} value={option}>
                                    {option}
                                  </option>
                          )}
                  </select>
                </div>
                                        
                <div style={{ marginTop: "3%"}} class='create-form-label'>
                  Address:
                </div>
                <div  style={{width: "90%"}}>
                      <textarea name="address"  style={{width: "100%", height: "100px" }} placeholder={'Complete Address'} ref={addressRef}/>
                </div>            
              
            </div>
            <div style={{position:'absolute',bottom:'0px',height:'75px',width:'100%'}}>
                  <button class='ss-button' class='create-button' style={{marginLeft:'30%'}}>{(isEdit() || props.profile ) ? 'Update User' : 'Create User'}</button>
                  <span class="spinner-border" role="status" style={{position:'relative',top:'10px',right:'75px', color: 'black', height:'25px', width:'25px',zIndex:'100'}} ref={spinnerRef}/>
            </div>                            
          </form>
          <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}
                newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss
                draggable pauseOnHover theme="colored"/>                      

          </div>
    );
}


export default CreateUserForm;