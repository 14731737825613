  import React from "react"
  import {useState, setState, useRef, useEffect} from 'react';
  import {useNavigate} from "react-router-dom"
  import * as axiosClient from '../../../js/Axios';
  import * as constants from '../../../js/constants'
  import '../../../css/main.css'
  import {over} from 'stompjs';
  
  import { io } from 'socket.io-client';
  const voice = require('@twilio/voice-sdk')

  function ChatCustomerMessage(props){
      const chatBodyRef = useRef(null)
      const inputTextRef = useRef(null)
      const clientRef = useRef(null)      
      // const URL = process.env.NODE_ENV === 'production' ? undefined : 'http://localhost:3002';
      // const socket = io(URL);
      //     console.log("Inside "+localStorage.getItem('chat_uuid'))
      //     socket.on('connect', onConnect);
      //     socket.on('disconnect', onDisconnect);
      //     socket.on('message', onMessage);
      

      // useEffect(() => {
      //     console.log("useEffect chat ")
      // axiosClient.postRequest(constants.CHAT_URL+'generate_token',payload)
      //     .then(function (response) {
      //         console.log(response)
      //     }).catch(function (error) {
      //         console.log(error)
      // });
      // },[])//use effect


      return(
 <div class="chat-header-row ChatMyMessage">     
     <div class="chat-header-info">
       <div class="main-header">
         <p>{props.text}</p>       
       </div>
     </div>
     <div className={'chat-header-img'}>
       <img src={require('../../../img/test.png')} alt=''/>
     </div>
   </div>     
          )

  }

  export default ChatCustomerMessage;