import React from "react"
import { CognitoUserPool, CognitoUserAttribute, CognitoUser, AuthenticationDetails, CognitoIdentityCredentials, WebIdentityCredentials } from 'amazon-cognito-identity-js';
import {useState, setState, useRef, useEffect} from 'react';
import {useNavigate, Redirect} from "react-router-dom"
import * as axiosClient from '../../js/Axios';
import * as constants from '../../js/constants';
import * as awsProfile from '../../js/awsProfile.js'
import '../../css/main.css'

function ForgotPassword(props){
	const errorRef=useRef(null)
	const spinnerRef = useRef(null)
	const buttonRef = useRef(null)
	const codeRef = useRef(null)
	const toastRef = useRef(null)
	const emailRef=useRef(null)
	const [email, setEmail] = useState(null)
	const navigate = useNavigate();
	useEffect(() => {
		console.log('ForgotPassword')
		toastRef.current.style.display = 'none'
		codeRef.current.style.display = 'none'
		errorRef.current.style.display = 'none'
		spinnerRef.current.style.display = 'none'

	})	
	function sendActivationCode(event){
		console.log('sendActivationCode '+event.target.email.value)
		errorRef.current.style.display = 'none'
		event.preventDefault();
		spinnerRef.current.style.display = 'inline-block'
		event.target.disabled = true
		if(codeRef.current.style.display !== 'none'){
			resetPassword(event)
			return
		}
		setEmail(event.target.email.value)
		const userData = {
       			Username: event.target.email.value,
       			Pool: awsProfile.userPool
  		}
  		const cognitoUser = new CognitoUser(userData)
			cognitoUser.forgotPassword({
				onSuccess: function (data) {
					codeRef.current.style.display = 'inline'
					buttonRef.current.innerHTML = 'Reset Password'
				},
				onFailure: function (err) {
					console.log(err.message || JSON.stringify(err));
				},
			});
		spinnerRef.current.style.display = 'none'		  		 
	}

function displayError(msg){
  errorRef.current.innerHTML = "<span style='color:red'>*&nbsp;&nbsp;"+msg+"</span>"
  errorRef.current.style.display='block'
  errorRef.current.scrollIntoView()
}
function resetPassword(event){
	event.preventDefault()
	if(event.target.code.value === ''){
		displayError('Code is mandatory')
		spinnerRef.current.style.display = 'none'
		return
	}
	if(event.target.password.value === ''){
		displayError('Password is mandatory')
		spinnerRef.current.style.display = 'none'
		return
	}
	if(event.target.password.value !== event.target.cpassword.value){
		displayError('Passwords does not Match')
		spinnerRef.current.style.display = 'none'
		return
	}	
	const userData = {
   			Username: email,
   			Pool: awsProfile.userPool
		}
	const cognitoUser = new CognitoUser(userData)	
	cognitoUser.confirmPassword(event.target.code.value, event.target.password.value, {
		onSuccess() {
			toastRef.current.innerHTML='Password Changed'
			toastRef.current.style.display = 'flex'
			setTimeout(redirectToLogin,3000)
		},
		onFailure(err) {
			console.log('Password not confirmed!');
		},
	});
	spinnerRef.current.style.display = 'none'	
}

function redirectToLogin(){
	window.location.href='/login'
}
function redirectToSignup(){
	window.location.href='/signup'
}

	return(
<div class="login-wrapper" >
   <div class="inside-wrapper" >
      <div class="logo"> <img src={require('../../img/logo.png')} /></div>
      <div class="form-container">
         <div class="slide-controls">
            <input type="radio" name="slide" id="login" onClick={redirectToLogin}/>
            <input type="radio" name="slide" id="signup" onClick={redirectToSignup}/>
            <label for="login" class="slide login">Login</label>
            <label for="signup" class="slide signup">Signup</label>
            <div class="slider-tab"></div>
         </div>
         <div class="form-inner">
            <form onSubmit={sendActivationCode} class="reset">
               <div class="field" style={{marginBottom:'30px'}}>
                  <input type="text" placeholder="Email Address" required name='email' style={{height:'34px'}}/>
               </div>
               <div ref={codeRef}>
	               <div class="field" style={{marginBottom:'30px'}}>
	                  <input type="text" placeholder="Activation Code" name='code' />
	               </div>
	               <div class="field" style={{marginBottom:'30px'}}>
	                  <input type="password" placeholder="New Password" name='password'/>
	               </div>
	               <div class="field" style={{marginBottom:'30px'}}>
	                  <input type="password" placeholder="Confirm Password" name='cpassword'/>
	               </div>	               
	           </div>
               <div style={{display:'table',margin:'auto',width:'100%'}}>
                	<button type='submit' class='ss-button' style={{borderRadius: '10px', padding:'10px 20px',width:'100%' }} ref={buttonRef}>Send Activation Code</button>
               </div>
               <span class="spinner-border" role="status" style={{position:'relative', left:'145px',bottom:'45px', color: 'white', height:'35px', width:'35px',zIndex:'100'}} ref={spinnerRef}/>
            </form>  
         </div>
         <div ref={errorRef}/>
         <div class='toaster' ref={toastRef}/>
      </div>
   </div>
</div>
		)

}

export default ForgotPassword