import React from "react"

function ProfilePicture(props){

var colors = {'0' : ['rgb(92, 138, 240)', 'white'],
			  '1' : ['#bf360c', 'white'],
			  '2' : ['#5375c9', 'white'],
			  '3' : ['grey', 'white'],
			  '4' : ['#854deb', 'white'],
			  '5' : ['#444791', 'white']
			}
var alphabets={'a':0,'b':1,'c':2,'d':3,'e':4,'f':5,'g':6,'h':7,'i':8,
	'j':9,'k':10,'l':11,'m':12,'n':13,'o':14,'p':15,'q':16,'r':17,'s':18,
	't':19,'u':20,'v':21,'w':22,'x':23,'y':24,'z':25,'0':0,'1':1,'2':2,'3':3,
	'4':4,'5':5,'6':6,'7':7,'8':8,'9':9
}
function findFirstCharIndex(str){
	if(str == null)
		str="a"
	if(str.charAt(0).toLowerCase() in alphabets)
		return (alphabets[str.charAt(0).toLowerCase()] % 6)

	return getRandomInt()
}
function getRandomInt() {
  const minCeiled = Math.ceil(1);
  const maxFloored = Math.floor(6);
  return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled); // The maximum is exclusive and the minimum is inclusive
}
	return (		
		<div style={{display:'inline'}} class='add-ellipsis' >
			{
				props.classProp === undefined ?
					 props.name !== '' ?
						<button  style={{fontWeight:'600',borderColor:colors[findFirstCharIndex(props.name)][0], background: colors[findFirstCharIndex(props.name)][0],color:colors[findFirstCharIndex(props.name)][1],boxShadow:'2px', borderRadius:'50%', height: (props.smallSize === false) ? '40px' : '25px' ,width:(props.smallSize === false) ? '40px' : '25px',fontSize:'14px', border:'1px solid white' }}>		
							{(props.name === '')? 'A' : props.name.charAt(0).toUpperCase()}
			        	</button>
			          : '--'
				: 
					  <button  style={{fontWeight:'600', border:'1px solid white', borderColor:colors[findFirstCharIndex(props.name)][0], background: colors[findFirstCharIndex(props.name)][0],color:colors[findFirstCharIndex(props.name)][1]}} class={props.classProp} >
							{props.name.charAt(0).toUpperCase()}
		        	  </button>
			}			
	        	
		</div>
	);
}

export default ProfilePicture;