import React, { useEffect, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as axiosClient from "../../../js/Axios"
import * as constants from '../../../js/constants'
import {useNavigate} from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';

function CreateCompanyGroupForm(props){  
  const errorRef = useRef(null)
  const [ids, setIds] = useState([])
  const spinnerRef = useRef(null)
  const dropdownRef = useRef(null)
  const selectedCompaniesRef = useRef(null)
  const REPORTER_DROP_DOWN_INPUT_POS = 0;
  const REPORTER_DROP_DOWN_MENU_POS = 1;
  const REPORTER_DROP_DOWN_HIDDEN_INPUT_POS = 2;
  const nameRef = useRef(null)
  let editFilled = false
  useEffect(() => {
    errorRef.current.style.display = 'none'
    spinnerRef.current.style.display = 'none'
    if(isEdit() && !editFilled){
      editFilled = true;
      fillEditForm(); 
    }    
  },[]);
function isEdit(){
  console.log('isEdit '+props.editIdRef.current)
  return props.editIdRef.current !== undefined && props.editIdRef.current !== -1
}

function displayError(msg){
  errorRef.current.innerHTML = "<span style='color:red'>*&nbsp;&nbsp;"+msg+"</span>"
  errorRef.current.style.display='block'
  errorRef.current.scrollIntoView()
}
function fillEditForm(){
  console.log('fillEditForm')
    axiosClient.getRequest(constants.COMPANY_GROUP_URL+props.editIdRef.current)
  .then(function (response) {
      nameRef.current.value = response.data.name
      for(var i=0;i<response.data.companies.length; i++){
        let event = {'target':{'parentElement':{'innerHTML': ''}}}
        event['target']['name'] = response.data.companies[i].id
        event['target']['innerHTML'] = response.data.companies[i].name
        onSelectCompanyDropdown(event, response.data.companies[i].id, response.data.companies[i].name)
      }      
  }).catch(function (error) {
    console.log(error)
      errorRef.current.innerHTML = error.response === undefined ? error : error.response.data.error
      errorRef.current.style.display = 'block'
  });
}
  function handleSubmit(event){  
    event.preventDefault()
    let element = document.getElementsByName('companies')[0]
    element.setCustomValidity('')
    errorRef.current.style.display = 'none'
    console.log('length of ids '+ids.length)
    if(ids.length === 0){
      displayError('No Company Selected')
      return
    }
    if(ids.length === 1){
      displayError('More than one company required for a Group')
      return
    }
    spinnerRef.current.style.display = 'inline-block'    
    let payload = {'name': event.target.name.value, 'ids':ids}
    if(!isEdit()){
        axiosClient.postRequest(constants.COMPANY_GROUP_URL, payload)
        .then(function (response) {    
          props.closeCreateWindow();
          window.location.reload()    
        }).catch(function (error) {
            console.log("Error is "+error.response.data.error)
            errorRef.current.innerHTML = error.response.data.error
            errorRef.current.style.display = 'block'
            spinnerRef.current.style.display = 'none'    
        });
    }else{
        axiosClient.patchRequest(constants.COMPANY_GROUP_URL+props.editIdRef.current, payload)
        .then(function (response) {    
          props.closeCreateWindow();
          window.location.reload()    
        }).catch(function (error) {
            console.log("Error is "+error.response.data.error)
            errorRef.current.innerHTML = error.response.data.error
            errorRef.current.style.display = 'block'
            spinnerRef.current.style.display = 'none'
            spinnerRef.current.style.display = 'none'     
        });      
    }    
  }

function searchCompanies(event){
  event.target.parentElement.children[1].innerHTML = ''
  if(event.target.value.length < 3)
    return
  axiosClient.getRequest(constants.COMPANY_URL+"search?keyword="+event.target.value)
    .then(function (response) {
    response.data.map((element, index) => (
      addChildToCompanyDropdown(event, element)    
    ));
    }).catch(function (response) {
        console.log(response);
    });
}

  function onSelectCompanyDropdown(event, id, name){
    event.target.parentElement.innerHTML = ''
    let div = document.createElement('div')
    let input = document.createElement('input')
    input.style.display = 'none'
    input.value = id
    div.className = 'company-group-selected-company'
    let innerDivLeft = document.createElement('div')
    let innerDivRight = document.createElement('div')
    innerDivLeft.style.float = 'left'
    innerDivRight.style.float = 'right'
    innerDivLeft.innerHTML = name
    innerDivRight.innerHTML = '&times;'
    innerDivRight.onclick = removeSelectedCompany
    innerDivRight.style.cursor = 'pointer'
    div.appendChild(input)
    div.appendChild(innerDivRight)
    div.appendChild(innerDivLeft)
    selectedCompaniesRef.current.appendChild(div)
    let idsTemp = ids;
    idsTemp.push(id)
    setIds(idsTemp)
    dropdownRef.current.innerHTML = ''
    dropdownRef.current.style.display = 'none'    
  }

  function removeSelectedCompany(event){
    let children = selectedCompaniesRef.current.children
    if(children.length === 0)
      return
    for(var i=0;i<children.length; i++){
      if(event.target.parentElement === children[i]){
        let id = children[i].children[0].value
        let idsTemp = ids;
        selectedCompaniesRef.current.removeChild(children[i])
        const index = idsTemp.indexOf(id);
         if (index > -1) {
              idsTemp.splice(index, 1);
          }
          setIds(idsTemp)
        return
      }
    }
  }
  function addChildToCompanyDropdown(event, element){ 
      if(ids.includes(element.id))
        return;
      let aTag = document.createElement('a')
      aTag.name=element.id
      let outerDiv = document.createElement('div')
      let innerLeftDiv = document.createElement('div')
      let innerRightDiv = document.createElement('div')
      innerLeftDiv.innerHTML = element.name
      innerRightDiv.innerHTML=element.email
      if(element.email === 'null')
        innerRightDiv.innerHTML = ''
      innerLeftDiv.style.float='left'
      innerLeftDiv.classList.add('add-ellipsis')
      innerRightDiv.classList.add('add-ellipsis')
      innerRightDiv.style.float='right'
      outerDiv.appendChild(innerRightDiv)
      outerDiv.appendChild(innerLeftDiv)
      aTag.appendChild(outerDiv)
      // aTag.onclick = onSelectDropdown()
      aTag.addEventListener('click', function(event){
          onSelectCompanyDropdown(event,element.id,element.name);
      });            
      event.target.parentElement.children[REPORTER_DROP_DOWN_MENU_POS].appendChild(aTag)
      event.target.parentElement.children[REPORTER_DROP_DOWN_MENU_POS].style.display = 'block'
  }


  return (
    <div class='create-wrapper'>
      <form onSubmit={handleSubmit}>          
          <div style={{boxShadow:'0 0 2px grey', padding:'30px', paddingleft:'0px',backgroundColor:'white',height: 'calc(100vh - 105px', overflowY: 'scroll'}}>
              <a href="javascript:void(0)" class="create-closebtn" onClick={props.closeCreateWindow}>&times;</a>
              <div ref={errorRef} name="error"/>
              <h8 style={{color:'#044caf',fontWeight: '800', fontSize:'20px'}}><b>+ Create Company Group</b> </h8>
              <div class='create-line'></div>
              <div class='create-form-label' style={{ marginTop: "3%"}} >Company Group Name:<p class='red-color'>*</p></div>
              <div><input type="text" name="name" style={{width: "90%"}}  placeholder={'Name'} required ref={nameRef}/></div>
              <div class='create-form-label' style={{ marginTop: "3%"}}>Search Companies For The Group:<p class='red-color'>*</p></div>
              <div style={{position: 'relative' }}>
                <input type="text" name="companies" style={{width: "90%"}}  placeholder={'Search Companies'} onChange={searchCompanies}/>
                <div style={{position: 'absolute', display: 'none'}} class='create-dropdown-content' ref={dropdownRef}/>
              </div>
              
              <div style = {{ marginTop: '100px'}} ref={selectedCompaniesRef}  class='company-group-selected-companies-box'></div>              
          </div>
          <div style={{position:'absolute',bottom:'0px',height:'75px',width:'100%'}}>
            <button class='ss-button'  class='create-button' style={{marginLeft:'30%'}}>{isEdit() ? 'Update Group' : 'Create Group'}</button>
            <span class="spinner-border" role="status" style={{position:'relative',top:'10px',right:'75px', color: 'black', height:'25px', width:'25px',zIndex:'100'}} ref={spinnerRef}/>
          </div>     
      </form>
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}
                newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss
                draggable pauseOnHover theme="colored"/>
    </div>

    );
}

export default CreateCompanyGroupForm;