import React, {useState} from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../js/constants'

function DepartmentListDisplayHeader(props){

const navigate = useNavigate(); 
const [filterState, setFilterState] = useState('All Departments')

function openDepartment(id){
	let path = constants.TEAM_URL_ABS +props.department.id ;
    console.log("path is "+path)
    navigate(path);
}

  function toggleFilterBox(){
  	props.filterBoxRef.current.style.display = "block"
  }
  function createNewDepartment(){
  	// navigate(constants.TEAM_URL_ABS+'new')
  	props.showCreateConactFormFun()
  }

return (
  <div class='header-body'>
	    <div class="main-header">
	    	<div style={{fontWeight:'bold',fontSize:'14px' }}>{filterState}</div>
     		 <div class="search-wrapper">
   					{<img src={require('../../../img/search.png')} alt=''/>}
   					<input placeholder="search here" class="search-input" onChange={props.searchDepartments}/>
             </div>	          
	         <div class="header-right" ref={props.groupButtonsRef}>
	            <button class='ss-button'  type="button" onClick={() => props.performAction('delete')}>Delete</button>	            
	         </div>
           <button class='ss-button'  type="button" onClick={createNewDepartment}>+ New Department</button>
	    </div>
  </div>
);

}

export default DepartmentListDisplayHeader;