import React from "react"
import SideNav from './SideNav'
import Topnav from './Topnav/Topnav'
import {useState, setState, useRef, useEffect} from 'react';
import * as awsProfile from '../js/awsProfile'
import { jwtDecode } from "jwt-decode";
import {CognitoUser,CognitoRefreshToken } from 'amazon-cognito-identity-js';
var AmazonCognitoIdentity = require('amazon-cognito-identity-js');

function LoginCheck(){

	useEffect(()=>{	
		checkLoginRefresh();			
	},[])

function setLastSeenCookie() {
  const d = new Date();
  let cname = 'lastseen'
  let cvalue = d.getTime();  
  d.setTime(d.getTime() + (8*60*60*1000));  
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}


function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
  return "";
}

function isIdTokenExpired(){
  let idToken = localStorage.getItem('id_token')	
  const decoded = jwtDecode(idToken);
  let now = parseInt(Date.now()/1000)
  return ((decoded.exp - now) < 3600)
}
function checkLoginRefresh(){
           if(!isIdTokenExpired())
		          return setLastSeenCookie()

			     var refreshToken = localStorage.getItem("refresh_token");
            let refreshTokenExpiry = parseInt(localStorage.getItem('refresh_token_expiry'))
            let now = Date.now()
            var refreshTokenExpired = (refreshTokenExpiry < now)
            var idToken = localStorage.getItem('id_token')
            const decodedIdToken = jwtDecode(idToken)
            console.log('refreshTokenExpired '+decodedIdToken.email+' '+refreshTokenExpired)

		    if(!refreshTokenExpired){
                  var token = new AmazonCognitoIdentity.CognitoRefreshToken({ RefreshToken: refreshToken })
                  const userData = {
                       Username: decodedIdToken.email,
                       Pool: awsProfile.userPool
                      }
                  const cognitoUser = new CognitoUser(userData)                  
                  cognitoUser.refreshSession(token, function (err, session) {
                      if(err){
                        // if(err.code === 'UserNotFoundException'){
                        //   localStorage.removeItem('id_token')
                        //   window.location.href='/login'
                        // }                        
                        localStorage.removeItem('id_token')                        
                        window.location.href='/login'
                      }
                      idToken = session.getIdToken().getJwtToken();
                      let accessToken = session.getAccessToken().getJwtToken();
                      localStorage.setItem('id_token',idToken)
                      localStorage.setItem('access_token', accessToken)
                      setLastSeenCookie()
                  })              
                  return
		    }
        
            if(getCookie('lastseen') === ''){
                localStorage.removeItem('id_token')
                if(!window.location.href.contains('/login')){
                    window.location.href='/login' 
                }
                return             
            }
            setLastSeenCookie()
            	
}
	return (
		<>	
    	</>
	)
}

export default LoginCheck;