import React, { useState, useEffect, useRef,createContext } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as axiosClient from '../../../../js/Axios';
import * as constants from '../../../../js/constants'
import PaymentLine from './PaymentLine'
import {ToastContainer, toast } from 'react-toastify';

function PaymentsList(){
	const [paymentLine, setPaymentLine] = useState([])
	const [count, setCount] = useState(0)
	useEffect(() => {
		loadPayments()
	},[])//use effect

	function loadPayments(){
		axiosClient.getRequest(constants.PAYMENT_URL)
		.then(function (response) {
			let paymentList = null;
			paymentList = response.data.map((element, index) => (									
							<PaymentLine payment= {element} />
			));		
			if(Object.keys(paymentList).length === 0){
				setCount(0)
				setPaymentLine('No Records Found...')
			}else{
				setPaymentLine(paymentList)
				let countTemp = response.data[0].count;
				setCount(countTemp)
			}
		}).catch(function (response) {
		    console.log(response);
		});
	}

return (
	<>
		 <div class='header-body' style={{backgroundColor: 'white'}}>
		 	<div style={{ marginTop:'30px', fontWeight:'bolder', fontSize:'16px',marginLeft:'30px' }}><a href='/admin/subscriptions'>Subscription</a> > Payments</div>
		 </div> 

		<div class="main-body">
		   <div class="page-body" >		   	   
				   <table class='table'>
				      <thead>
				         <tr >				            
				            <td style = {{backgroundColor: 'rgb(235, 237, 240)', maxWidth: "50px"}}>Action</td>
				            <td style = {{backgroundColor: 'rgb(235, 237, 240)', width: "18%"}}>Amount</td>
				            <td style = {{backgroundColor: 'rgb(235, 237, 240)', width: "18%"}}>Date</td>
				         </tr>
				      </thead>
				      <tbody>
							{paymentLine}
				      </tbody>
				   </table>
				</div>
		
			</div>
			<ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}
                newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss
                draggable pauseOnHover theme="colored"/>						
	</>
 );

}
export default PaymentsList;
