import React from "react"
import {useState, setState, useRef, useEffect} from 'react';
import DialPad from './DialPad'
import CreateTicketFormMain from '../../Ticket/js/CreateTicketForm'
import CreateTicketForm from './CreateTicketForm'
import {useNavigate} from "react-router-dom"
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import  '../css/call_window.css'
import * as twilioMain from '../../../js/twilio-main';
import CallLine from './CallLine'
function RecentCalls(props){
  const dropdownRef = useRef(null)
  const [callLine, setCallLine]= useState(null)
  useEffect(()=>{
      fetchCalls()  
  },[])

  function callNumber(number){
    console.log('recent calls callNumber '+number)
    props.callNumber(number)
  }
  function fetchCalls(){
      axiosClient.getRequest(constants.CALL_URL)
      .then(function (response) {
          let callArr = response.data.map((element, index) =>(
                  <CallLine  call={element} callNumber={callNumber}/>
                ))
          setCallLine((Object.keys(callArr).length === 0  ? <span style={{color: 'white'}}>No Calls Found...</span> : callArr))                    
      }).catch(function (response) {
          console.log(response)
      });   
    }

	return(
				<div>
            <div className = "call-window-input-wrapper" style={{marginTop:'60px'}}>
                <input placeholder= 'Search Calls...' type="text"  className = "call-window-input" />
            </div>
            <div ref={dropdownRef} class='call-window-recent-calls'>
              {callLine}
            </div>
				</div>


	)
}

export default RecentCalls;