import React, { useState, useEffect, useRef,useContext } from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../js/constants'
import ProfilePicture from '../../ProfilePicture/ProfilePicture'

function CallLine(props){
let sess = JSON.parse(sessionStorage.getItem("ss_sess"))
function navigateToUser(event, id){
	event.preventDefault();
	window.location.href='/agents/'+props.call.user.id
}
function navigateToContact(event, id){
	event.preventDefault();
	window.location.href='/contacts/'+props.call.contact.id
}
return (
	        <tr>
	            <td style={{fontSize:'14px', width:'250px'}} onClick={navigateToContact}> 
	            {	props.call.contact.image === 'null' ? 
		            		<ProfilePicture name={props.call.contact.name} />
		            		: <img class="list-image"  src={ props.call.contact.image} /> 
		            }
	            	<a href="" style = {{textDecoration: "none", marginLeft: '10px',fontWeight:'800',fonFamily:'Helvetica'}}>{props.call.contact.name}</a>
	            </td>
	            <td>{props.call.otherPhoneNumber}</td>
	            <td> {(props.call.user !== null && props.call.user !== 'null') ? <a href='' onClick={(event) => navigateToUser(event, props.call.user.id)} style={{textDecoration:'none'}}>{props.call.user.name}</a> : ''} </td>
	            <td><a href="#" style={{textDecoration:'none'}} >{props.call.twilioPhoneNumber}</a></td>
	            <td style={{color:'black',fontWeight:'600'}}>{props.call.direction}</td>
	            <td>{props.call.status}</td>
	            <td>{props.call.duration}</td>
	         	{(sess !== null && sess['role'].toUpperCase() === "agent".toUpperCase()) ? '' :    <td>{props.call.cost}</td> }
	            <td>{props.call.createdDate}</td>	            
	         </tr>
    );

}

export default CallLine;