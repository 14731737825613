import React from "react"
import { CognitoUserPool, CognitoUserAttribute, CognitoUser, AuthenticationDetails, CognitoIdentityCredentials, WebIdentityCredentials } from 'amazon-cognito-identity-js';
import {useState, setState, useRef, useEffect} from 'react';
import {useNavigate, Redirect} from "react-router-dom"
import * as axiosClient from '../../js/Axios';
import * as constants from '../../js/constants';
import * as awsProfile from '../../js/awsProfile.js'
import DynamicCheckbox from './DynamicCheckbox'
import InputBox from './js/InputBox'

function FormBuilder(props){
	const [children, setChildren] = useState([])
	const [dragValue, setDragValue] = useState(null)
useEffect(()=>{
  
},[])
function allowDrop(ev) {
  ev.preventDefault();
}

function drag(ev) {
  console.log('drag '+ev.target.value)
  setDragValue(ev.target.value)
}

function drop(ev) {
  ev.preventDefault();
  ev.stopPropagation()
  console.log('drop called data '+dragValue)
  if(dragValue === 2){
  	let childrenTemp = children;
  	childrenTemp.push(<InputBox />)
  	setChildren(childrenTemp)
  	console.log('children '+childrenTemp.length)
  }
}

function submitHandler(){
}
	return(
	   <div class='main-body'>
		<div class='custom-form-builder'>
			<form onSubmit={submitHandler}>
				<input type="text" name="name"  style={{width: "90%"}}  placeholder={'Full Name'} required maxlength='70' size='255'/>
			</form>
		</div>
	   </div>
		)


}

export default FormBuilder