  import React from "react"
  import {useState, setState, useRef, useEffect} from 'react';
  import {useNavigate} from "react-router-dom"
  import * as axiosClient from '../../../js/Axios';
  import * as constants from '../../../js/constants'
  // import '../../css/main.css'
  import {over} from 'stompjs';
  
  import { io } from 'socket.io-client';
  const voice = require('@twilio/voice-sdk')

  function ChatContactBox(props){
    const contactBoxRef = useRef(null)
      const chatBodyRef = useRef(null)
      const inputTextRef = useRef(null)
      const clientRef = useRef(null)
      var socket = null
      // const URL = process.env.NODE_ENV === 'production' ? undefined : 'http://localhost:3002';
      // const socket = io(URL);
      //     console.log("Inside "+localStorage.getItem('chat_uuid'))
      //     socket.on('connect', onConnect);
      //     socket.on('disconnect', onDisconnect);
      //     socket.on('message', onMessage);
      function onMessage(message){
          message = JSON.parse(message)
          console.log("Message on user side received "+message.type)
          if(message.type === 'welcome'){
       
          }else if(message.type === 'connect_to_user'){
             console.log(message)
          }
          //TODO On receiving message check the time stamp of previous received messaage
          // against the prevois message time stamp in payload
          // if not matching send a request to backend to fetch all messages
      }
      function onConnect(){ 
        var message = {"type": "user_connect", "user_id" : 1}
        socket.send(message)
      }

      function onDisconnect(){console.log("onDisconnect")}

      function onClickEvent(){
          var token = ((localStorage.getItem("chat_token") == null) ? "" : localStorage.getItem("chat_token"))
          var conversationId = ((localStorage.getItem("conversation_id") == null) ? 0 : localStorage.getItem("conversation_id"))
          var message = inputTextRef.current.value 
          socket.send({"type": "chat","content": message, "chat_token": token, 'conversation_id': conversationId});
      }
              

      function onChangeEvent(event){
          console.log(event.which)
          if(event.which === 13 )
              onClickEvent()
      }

      // useEffect(() => {
      //     console.log("useEffect chat ")
      // axiosClient.postRequest(constants.CHAT_URL+'generate_token',payload)
      //     .then(function (response) {
      //         console.log(response)
      //     }).catch(function (error) {
      //         console.log(error)
      // });
      // },[])//use effect



      return( 
              
<div class = 'Right-Box Right-Chat chat-bg'>
              <div class="user-wrapper">
 <div class="btn-div" onClick={props.closeWindow}><button class='ss-button'  class="close-btn"><img src={require('../../../img/close.png')} alt=""/></button></div>
 {/*   <div class="Right-search">
         <div style= {{position: "relative"}}>
           <img src={require('../../../img/search.png')} alt=""/>
           <input placeholder="Search here" class="search " />
         </div> 
       </div>*/}
 <div class="user-main-info">
   <div class="chat-header-img">
     <img src={require('../../../img/Headshot.jpg')} />
   </div>
   <h4>Leonard Campbell</h4>
   <p>Senior developer</p>
 </div>
 <div class="Right-chat-icons ">
   <div class="chat-icon">
     <button class='ss-button'  class="button-chat"><img src={require('../../../img/test.png')} alt=""/></button>
     <p>chat</p>
   </div>
   <div class="video-icon">
     <button class='ss-button' ><img src={require('../../../img/facetime-button.png')} alt=""/></button>
     <p>video call</p>
   </div>
 </div>
 {/*<div class="friend-details">
   <div class="view-friend">
     <img src={require('../../../img/user12.png')} alt=""/>
     <p>view friends</p>
   </div>
   <div class="favourite">
     <img src={require('../../../img/heart12.png')} alt=""/>
     <p>favourite</p>
   </div>
 </div>
 
 <div class="contact-wrapper">
   <h6>Attachments</h6>
   <div class="attachment-icons">
     <button class='ss-button' ><img src={require('../../../img/pdf-file.png')} alt=""/>PDF</button>
     <button class='ss-button' ><img src={require('../../../img/video.png')} alt=""/>VIDEO</button>
     <button class='ss-button' ><img src={require('../../../img/music.png')} alt=""/>MP3</button>
     <button class='ss-button' ><img src={require('../../../img/photo.png')} alt=""/>IMAGE</button>
   </div>
   <div class="view-div"><button class='ss-button'  class="view-button">View All</button></div>
   
 </div> */}
              </div>
            </div>

              
          )

  }

  export default ChatContactBox;