import React from "react"
import { CognitoUserPool, CognitoUserAttribute, CognitoUser, AuthenticationDetails, CognitoIdentityCredentials, WebIdentityCredentials } from 'amazon-cognito-identity-js';
import {useState, setState, useRef, useEffect} from 'react';
import {useNavigate, useParams, Redirect} from "react-router-dom"
import * as axiosClient from '../../js/Axios';
import * as constants from '../../js/constants';
import * as awsProfile from '../../js/awsProfile.js'
import '../../css/main.css'

function VerifyUser(props){
	const errorRef=useRef(null)
  const [displayForm, setDisplayForm] = useState(false)
  const toastRef = useRef(null)
  const spinnerRef = useRef(null)
	const codeRef=useRef(null)
  const emailRef= useRef(null)
	const navigate = useNavigate();	
  const params = useParams(); 
  const [user, setUser] = useState(null)
  let email = null;
	useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const userId = params.id    
    if(code !== null && userId !== null){
      toastRef.current.style.display = 'none'
      return verifyUser(userId, code)
    }else{
      window.location.href='/login'
    }   

	},[])

function verifyUser(userId,code){
  axiosClient.postRequest(constants.USER_URL+'activate/'+userId+'?code='+code)
      .then(function (response) {  
          setUser(response.data)
          if(response.data.is_primary === 'true'){            
            if(localStorage.getItem('id_token') === null){
              toastRef.current.innerHTML = 'Email verified. Please login'
              setTimeout(redirectToLogin, 3000)
            }
            else{              
              toastRef.current.innerHTML = 'Email verified successfuly.'
              setTimeout(hideToastAndRedirect, 3000)
              console.log('verifyuser '+ sessionStorage.getItem('ss_sess'))
            }          
          }
          else{
            toastRef.current.innerHTML = 'Email verified. Please signup with password'            
            setTimeout(displayFormFun(response.data.id), 30000)
          }
          if(spinnerRef.current)
            spinnerRef.current.style.display = 'none'
          if(toastRef.current)
            toastRef.current.style.display = 'flex'
      }).catch(function (error) {
          console.log(error);
          toastRef.current.innerHTML = 'Activation code expired. Request for another activation link'
          toastRef.current.style.display = 'flex'
          // setTimeout(redirectToLogin,5000)
      });
}
function displayError(msg){
  errorRef.current.innerHTML = "<span style='color:red'>*&nbsp;&nbsp;"+msg+"</span>"
  errorRef.current.style.display='block'
  errorRef.current.scrollIntoView()
}

function redirectToVerifyUser(){
  window.location.href='/verify_user'
}
function redirectToLogin(){
  // localStorage.removeItem('id_token')
	window.location.href= '/login'
}

function hideToastAndRedirect(){
  toastRef.current.style.display = 'none'
  window.location.href='/'
}
function displayFormFun(id){
  setDisplayForm(true)
  if(toastRef.current)
    toastRef.current.style.display = 'none'
  if(spinnerRef.current)
    spinnerRef.current.style.display = 'none' 
}

function handleSubmit(event){
    errorRef.current.style.display = 'none'
    event.preventDefault();
    if(event.target.password.value !== event.target.cpassword.value){
      displayError('Passwords does not match')
      return
    }
    if(event.target.email.value !== user.email){
      displayError('Incorrect email')
      return      
    }
      // spinnerRef.current.style.display = 'block'
      localStorage.removeItem('id_token')
      awsProfile.userPool.signUp(event.target.email.value, 
        event.target.password.value, [], null, 
        function(err, result){
          if (err) {
          displayError(err.message)
          // spinnerRef.current.style.display = 'none'
            return
          }
        window.location.href='/login'
      })// awsProfile signup
  }
	return(
<>
{ displayForm ?
<div class="login-wrapper" >
   <div class="inside-wrapper" >
      <div class="logo"> <img src={require('../../img/logo.png')} /></div>
      <div class="form-container">
         <div class="slide-controls">
            <input type="radio" name="slide" id="login" onChange={redirectToLogin}/>
            <input type="radio" name="slide" id="signup" checked/>
            <label for="login" class="slide login">Login</label>
            <label for="signup" class="slide signup">Signup</label>
            <div class="slider-tab"></div>
         </div>
         <div class="form-inner">
            <form onSubmit={handleSubmit} >
               <div class="field">
                  <input type="text" placeholder="Email Address" required name='email'/>
               </div>
               <div class="field">
                  <input type="password" placeholder="Password" required name='password'/>
               </div>
               <div class="field" style={{marginBottom: '30px' }}>
                  <input type="password" placeholder="Confirm Password" required name='cpassword'/>
               </div>               
               <div style={{display:'table',margin:'auto',width:'100%'}}>
                <button type='submit' class='ss-button' style={{borderRadius: '10px', padding:'10px 20px',width:'100%',fontSize:'17px' }} >SignUp</button>
               </div>
            </form> 
         </div>
      </div>
      <div ref={errorRef} style={{marginTop: '10px'}}/>
   </div>   
</div>

 :
    <>
      <span class="spinner-border" role="status" style={{position:'absolute', top:'50%',transform: 'translate(-50%, -50%)', color: 'black', height:'35px', width:'35px'}} ref={spinnerRef}/>      
    </>
 }
 <div class='toaster' ref={toastRef}/>
</>
		)

}

export default VerifyUser