  import React from "react"
  import {useState, setState, useRef, useEffect} from 'react';
  import {useNavigate} from "react-router-dom"
  import * as axiosClient from '../../../js/Axios';
  import * as constants from '../../../js/constants'
  import '../../../css/main.css'
  import {over} from 'stompjs';
  
  import { io } from 'socket.io-client';
  const voice = require('@twilio/voice-sdk')

  function ChatAgentInfo(props){
      const chatBodyRef = useRef(null)
      const inputTextRef = useRef(null)
      const clientRef = useRef(null)
      var socket = null

      function onMessage(message){
          message = JSON.parse(message)
          console.log("Message on user side received "+message.type)
          if(message.type === 'welcome'){
       
          }else if(message.type === 'connect_to_user'){
             console.log(message)
          }
      }
      function onConnect(){ 
        var message = {"type": "user_connect", "user_id" : 1}
        socket.send(message)
      }

      function onDisconnect(){console.log("onDisconnect")}

      function onClickEvent(){
          var token = ((localStorage.getItem("chat_token") == null) ? "" : localStorage.getItem("chat_token"))
          var conversationId = ((localStorage.getItem("conversation_id") == null) ? 0 : localStorage.getItem("conversation_id"))
          var message = inputTextRef.current.value 
          socket.send({"type": "chat","content": message, "chat_token": token, 'conversation_id': conversationId});
      }
              

      function onChangeEvent(event){
          console.log(event.which)
          if(event.which === 13 )
              onClickEvent()
      }

      return(
              <div class="chat-Profile">
 <div class="chat-profile-img">
  <img src={require('../../../img/test.png')} alt=''/>
 </div>
 <div class="Profile-details">
     <h2 class="blue-color">props.user.name</h2>
     <p>props.user.role</p>   
 </div>
              </div>       
          )

  }

  export default ChatAgentInfo;