import React, {useState} from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../js/constants'

function CallListDisplayHeader(props){

const navigate = useNavigate(); 
const [filterState, setFilterState] = useState('All Calls')
function openCall(id){
	let path = constants.CALL_URL_ABS +props.call.id ;
    console.log("path is "+path)
    navigate(path);
}
function displayCallMenu(){
    document.getElementById("header-menu").style.display = 'inline'
}
  function hideCallMenu(){
      document.getElementById("header-menu").style.display = 'none'
  }  
  function selectFilter(filter, type){
  	hideCallMenu()
  	setFilterState(type)
  	props.preDefinedFilterCalls(filter,0)
  }  
return (
    <div class='header-body'>
	    <div class="main-header">
          <a class="btn-icon" style={{cursor:'pointer', marginRight:'10px'}} onClick={props.displayFilterBoxFun}>
            <img src={require('../../../img/filter.png')} alt='' style={{rotate: "90deg", height: "20px",rotate:'270deg' }}/>
          </a>
		    <div class="header-dropdown" onClick={displayCallMenu} onMouseLeave = {hideCallMenu}>
    				<a class="btn-icon">
    					<img src={require('../../../img/dots.png')} alt='' style={{rotate: "90deg", height: "20px" }}/>
    				</a>
    				<div>
    						<div id="header-menu" style={{display: "none"}} onClick={displayCallMenu}>
    						   <div><a class="dropdown-item-menu" href="#" onClick={() => selectFilter('all', 'All Calls') }>All Calls</a></div>    						      						   
           			   <div><a class="dropdown-item-menu" href="#" onClick={() => selectFilter('day', 'Last One day') }>Created Last One day</a></div>
           			   <div><a class="dropdown-item-menu" href="#" onClick={() => selectFilter('week', 'Last One week') }>Created Last One week</a></div>
           			   <div><a class="dropdown-item-menu" href="#" onClick={() => selectFilter('month', 'Last One Month') }>Created Last One Month</a></div>
                	</div>
    				</div>
		    </div>
	            

	    	<div style={{fontWeight:'bold',fontSize:'14px' }}>{filterState}</div>
             <div class="header-right" ref={props.groupButtonsRef}>
              {/*	
            <div>
             		         <button class='ss-button'  type="button"  onClick={() => props.performAction('call_group')}>Create Group</button>
             		         <input style={{position: 'absolute', display: 'none'}}/>
             	           </div>
	                   <button class='ss-button'  type="button" onClick={() => props.performAction('block')}>Block</button>
	                   <button class='ss-button'  type="button" onClick={() => props.performAction('spam')}>Mark As Spam</button>
	            */}
	            
	          </div>	         
	    </div>
    </div>
    );
}

export default CallListDisplayHeader;