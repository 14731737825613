import React from "react"
import {useState, setState, useRef, useEffect} from 'react';
import {useNavigate, Redirect} from "react-router-dom"
import * as axiosClient from '../../js/Axios';
import * as constants from '../../js/constants';
import * as awsProfile from '../../js/awsProfile.js'
import '../../css/main.css'


function SignUp(props){
	const errorRef=useRef(null)
	const spinnerRef = useRef(null)
	const toastRef = useRef(null)
	const [email, setEmail] = useState(null)
	const emailRef=useRef(null)
	const navigate = useNavigate();
	const [showVerificationCode, setShowVerificationCode] = useState(false)
	useEffect(() => {
		toastRef.current.style.display = 'none'
		spinnerRef.current.style.display = 'none'		
		errorRef.current.style.display = 'none'
	})

function displayError(msg){
  errorRef.current.innerHTML = "<span style='color:red'>*&nbsp;&nbsp;"+msg+"</span>"
  errorRef.current.style.display='block'
  spinnerRef.current.style.display = 'none'
  errorRef.current.scrollIntoView()
}

function hideToastAndRedirectToLogin(){
	toastRef.current.innerHTML = ''	
	toastRef.current.style.display = 'none'
	redirectToLogin()
}
function displayToast(msg){
	toastRef.current.innerHTML = msg
	toastRef.current.style.display = 'flex'

}
function createAccount(payload){
	console.log('CreateAccount')
	axiosClient.postRequest(constants.SIGNUP_URL,payload)
	.then(function (response) {		
		toastRef.current.innerHTML = 'A verification link has been sent to your email. Please verify your email id.'
		toastRef.current.style.display = 'flex'
		spinnerRef.current.style.display = 'none'
		setTimeout(hideToastAndRedirectToLogin,5000)
	}).catch(function (error) {
		console.log('Signup error '+error)
		if(error.response.data === undefined){
			console.log(error.response)
			errorRef.current.innerHTML="Unexpected error"
	    	errorRef.current.style.display = 'block'
		}else{			
	    	errorRef.current.innerHTML=error.response.data.error
	    	errorRef.current.style.display = 'block'
		}
		spinnerRef.current.style.display = 'none'
	});
}
	function handleSubmit(event){
		console.log('handleSubmit')
		errorRef.current.style.display = 'none'
		event.preventDefault();
		let payload = {'email': event.target.email.value,
					   'fullName' : event.target.fullName.value,
					   'companyName': event.target.companyName.value,
					   'mobileNumber': event.target.mobile.value
					}
		if(event.target.password.value !== event.target.cpassword.value){
			displayError('Passwords does not match')
			return
		}
		spinnerRef.current.style.display = 'inline-block'
		  awsProfile.userPool.signUp(event.target.email.value, 
		  	event.target.password.value, [], null, 
		  	function(err, result){
		      if (err) {
	    		displayError(err.message)
		        return
		      }
		    createAccount(payload)
    	})// awsProfile signup
	}

function redirectToLogin(){
	window.location.href= '/login'
}



	return(
		<>		
<div class="login-wrapper" >
   <div class="inside-wrapper" >
      <div class="logo"> <img src={require('../../img/logo.png')}/></div>
      <div class="form-container">
         <div class="slide-controls">
            <input type="radio" name="slide" id="login" onChange={redirectToLogin}/>
            <input type="radio" name="slide" id="signup" checked/>
            <label for="login" class="slide login">Login</label>
            <label for="signup" class="slide signup">Signup</label>
            <div class="slider-tab"></div>
         </div>
         <div class="form-inner">
            <form onSubmit={handleSubmit} >
               <div class="field">
                  <input type="text" placeholder="Full Name" required name='fullName' style={{padding:'5px',height:'33px'}}/>
               </div>
               <div class="field">
                  <input type="text" placeholder="Email Address" required name='email' style={{padding:'5px',height:'33px'}}/>
               </div>
               <div class="field">
                  <input type="text" placeholder="Mobile Number" required name='mobile' style={{padding:'5px',height:'33px'}}/>
               </div>
               <div class="field">
                  <input type="password" placeholder="Password" required name='password' style={{padding:'5px',height:'33px'}}/>
               </div>
               <div class="field">
                  <input type="password" placeholder="Confirm Password" required name='cpassword' style={{padding:'5px',height:'33px'}}/>
               </div>               
               <div class="field" style={{marginBottom: '30px' }}>
                  <input type="text" placeholder="Company Name" required name='companyName' style={{padding:'5px',height:'33px'}}/>
               </div>
               <div>
               	<label style={{height:'14px'}}><input type="checkbox" required style={{height:'14px'}}/> I agree to the <a href="/terms_conditions" >Terms of Service</a> and <a href="/privacy_policy">Privacy Policy</a>.</label>
               </div>
               <div style={{display:'table',margin:'auto',width:'100%'}}>
                <button type='submit' class='ss-button' style={{borderRadius: '10px', padding:'10px 20px',width:'100%',fontSize:'17px' }}>SignUp</button>
               </div>
               <span class="spinner-border" role="status" style={{position:'relative', left:'145px',bottom:'45px', color: 'black', height:'35px', width:'35px',zIndex:'100'}} ref={spinnerRef}/>
            </form> 
         </div>
      </div>
      <div ref={errorRef} style={{marginTop: '10px'}}/>
   </div>   
</div>

}
 <div class='toaster' ref={toastRef}/>
</>
		)

}

export default SignUp