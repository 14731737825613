import React from "react"
import {useState, useRef} from 'react';
import {useNavigate} from "react-router-dom"

function AddCallCredits(props){
	const creditsInputRef = useRef(null)
	const callCreditsErrorRef = useRef(null)
	const callCreditsButtonRef = useRef(null)
	const paypalButtonRef = useRef(null)

function addCallCredits(event){
	callCreditsErrorRef.current.innerHTML = ''
	event.preventDefault();
	if(creditsInputRef.current.value === ''){
		// callCreditsErrorRef.current.innerHTML = callCreditsButtonRef.current.innerHTML = "<span style='color:red'>*</span>&nbsp;&nbsp; Credits field is empty"
		return
	}
	if(isNaN(creditsInputRef.current.value)){
		callCreditsErrorRef.current.innerHTML = "<span style='color:red'>*</span>&nbsp;&nbsp; Credits must be a number greter than equal to 5"
		return	
	}
	if(Number(creditsInputRef.current.value) < 5){
		callCreditsErrorRef.current.innerHTML = "<span style='color:red'>*</span>&nbsp;&nbsp; Minimum credits to be added is 5$"
		return		
	}
	let payload = {amount: Number(creditsInputRef.current.value),
	               action: 'add_credits'}
	props.createOrder(payload)
}

	return(	
		<>
			<div class="create-wrapper">      	      	
	          <div style={{boxShadow:'0 0 2px grey', padding:'30px', paddingleft:'0px',backgroundColor:'white',height: 'calc(100vh - 105px', overflowY: 'auto'}}>
	          		<a href="javascript:void(0)" class="create-closebtn" onClick={props.closeAddCallCreditsWindow}>&times;</a>
					<div style={{fontSize:'28px', fontWeight: '800', margin:'auto',display: 'table',color: '#044caf'}}>Call Credits: </div>
						<table class='table'>
							<tr style={{height:'50px',marginBottom:'3%'}}>
							   <td style={{ marginTop:'16px',fontSize:'16px',fontWeight:'600'}}>Available Credits</td>
							   <td style={{ fontSize:'16px',fontWeight:'600'}}>{props.subscription ? props.subscription.callCredits : '--' }</td>
							</tr>
							<tr style={{height:'60px'}}>
							   <td style={{ marginTop:'16px',fontSize:'16px',fontWeight:'600'}}>Add Credits</td>
							   <td style={{fontWeight:'400',marginLeft:'10px',fontSize:'16px'}}>$<input type='text' name='credits'  maxlength='4' min='1' max='9999' style={{border:'1px solid black', height:'35px',width:'150px',backgroundColor: 'white',textAlign:'center'}} ref={creditsInputRef}/></td>
							</tr>											
						</table>
						<div>							
							<button class='ss-button' style={{marginLeft:'30%', padding:'5px 10px',width:'150px',display:'inline-block' }} onClick={addCallCredits} ref={callCreditsButtonRef}>Add Call Credits</button>														
						</div>
					<div ref={callCreditsErrorRef} style={{fontSize:'16px', fontWeight:'600'}}/>
			</div>
			<div style={{position:'absolute',height:'100px',width:'100px'}}>
			 
			</div>
		 </div>
		
	</>
		 )
}

export default AddCallCredits;