import React, { useState, useEffect, useRef,useContext } from 'react';
import {useNavigate} from "react-router-dom"

function PaymentLine(props){

function openContact(event){
	event.preventDefault()
    props.openContact(props.contact.id)
}

function fetchWorkPhones(){
	let workPhone = '';
	if(props.contact.workPhone !== '' && props.contact.workPhone !== 'null')
		workPhone = workPhone+props.contact.workPhone;
	if(props.contact.workPhone1 !== '' && props.contact.workPhone1 !== 'null')
		workPhone = workPhone+','+props.contact.workPhone1;
	if(props.contact.workPhone2 !== '' && props.contact.workPhone2 !== 'null')
		workPhone = workPhone+','+props.contact.workPhone2;
	if(workPhone === '')
		workPhone = '--'
	return workPhone;

}
function checkBoxClicked(event){
	props.checkBoxClicked(props.contact.id, event.target.checked)
}
function displayAction(){
	if(props.payment.action.toUpperCase() === 'RENEW')
		return 'Account Renewed'
	if(props.payment.action.toUpperCase() === 'ADD_CREDITS')
		return 'Call Credits Added'

}
return (
	        <tr style={{borderBottom:'1px solid rgb(235, 237, 240)',fontFamily:"Lato,Roboto,Helvetica,BogleWeb,MyriadPro,Helvetica Neue,Helvetica,Arial,sans-serif"}}>	          
	            <td class='add-ellipsis' > 
					<div style={{fontSize:'14px', fontWeight:'500',color: '#2e2f32'}} >{displayAction()}</div>
	            </td>
	            <td class='add-ellipsis' > 					
					<div style={{fontSize:'14px', fontWeight:'500',color: '#2e2f32'}} >{props.payment.amount}</div>					
	            </td>	            
	            <td class='add-ellipsis' > 					
					<div style={{fontSize:'14px', fontWeight:'500',color: '#2e2f32'}} >{props.payment.createdDate}</div>					
	            </td>	            

	         </tr>
    );

}

export default PaymentLine;