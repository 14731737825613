import React from "react"
import {useState, setState, useRef, useEffect} from 'react';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import {useNavigate} from "react-router-dom"
import {Route, Link, Routes, useParams} from 'react-router-dom';
import LoadingAnimation from '../../LoadingAnimation'
import ChatContactBox from './ChatContactBox'
import ChatMiddleWindow from './ChatMiddleWindow'
import ChatLeftWindow from './ChatLeftWindow'
import ChatAgentInfo from './ChatAgentInfo'
import ChatProfile from './ChatProfile'
import ChatUserMessage from './ChatUserMessage'
import ChatCustomerMessage from './ChatCustomerMessage'
import ChatMessageAttachment from './ChatMessageAttachment'
import ChatMiddleBoxHeader from './ChatMiddleBoxHeader'
import ReactDOMServer from 'react-dom/server';
import { io } from 'socket.io-client';

var globalId = null;

function ChatDisplay(props){
  let userId = "1";
  const middleBoxRef = useRef(null)
  const messageRef = useRef(null)
  const chatNotificationRef = useRef(null)
  const  [chatProfileData, setChatProfileData] = useState(-1)
  const  [clickedProfileId, setClickedProfileId] = useState(-1)
  const [chatProfileList, setChatProfileList] = useState(null)
  const [middleBoxChatMessages, setMiddleBoxChatMessages] = useState(null)
	const params = useParams();
	const navigate = useNavigate();
  const [chatData, setChatData] = useState(null)
	const commentsBodyRef = useRef(null)
  const contactBoxRef = useRef(null)
	const [ticket, setTicket] = useState(1)
	
  let globalSocket = null;
    const URL = process.env.NODE_ENV === 'production' ? undefined : 'http://localhost:3002';
    const socket = new io.connect(URL, {
        'reconnection': true,
        'reconnectionDelay': 1000,
        'reconnectionDelayMax' : 5000,
        'reconnectionAttempts': 5000
    });  
  function connectToSocket(){

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('message', onMessage);
    function onMessage(message){
      console.log("onMessage "+message)
      console.log("middleBoxRef "+middleBoxRef.current)
        message = JSON.parse(message)
        if(message.type === 'customer_message'){
          console.log('inside customer_mssage '+message)
          let div = document.createElement('div')
          div.innerHTML = ReactDOMServer.renderToStaticMarkup(<ChatCustomerMessage text={message['content']} id={message['uuid']}/>)
          middleBoxRef.current.appendChild(div)
          div.scrollIntoView()
        }
    }
    function onConnect(){
      console.log("connected sending connect user message ChatDisplay")             
        var message = {"type": "user_connected", "room" : "1"} // room is the user id TODO actual user id needs to be feched from somewh
        socket.send(message)
    }

    function onDisconnect(){console.log("onDisconnect")}
 // inputTextRef.current.disabled = false
  }

  useEffect(() => {    
  console.log("useEffect in ChatDisplay") 
     contactBoxRef.current.style.display='none'
     // middleBoxRef.current.style.display='none'
      var ids=[]      
           console.log("useEffect ChatDisplay ")
 axiosClient.getRequest(constants.CHAT_URL+"fetch_chat_data")
 .then(function (response) {
     var prevUUID = null
     var charProfleListArr = []
     for(var i=0; i< response.data.length; i++){         
         if(response.data[i].uuid !== prevUUID){           
           console.log("Inside for loop "+response.data[i].uuid+"  "+prevUUID)
           // TODO Use conversation id instead of uuid
           charProfleListArr.push(<ChatProfile data = {response.data[i]} chatProfileClicked={chatProfileClicked} id={response.data[i].uuid}/>)
           prevUUID = response.data[i].uuid
         }
     }
   setChatProfileList(charProfleListArr)
 }).catch(function (response) {
     console.log(response.data)
 });
    connectToSocket()
  },[])//use effect


  // useEffect(() => {
  //    console.log("useEffect ChatDisplay ")
  //    if (window.location.href.indexOf("accept_chat") > -1){
  //         axiosClient.getRequest(constants.USER_URL_ABS+"accept_chat")
  //         .then(function (response) {

  //         }).catch(function (response) {
  //             console.log(response.data)
  //         });           
  //     }
  // },[])//use effect


function closeContactBox(){
  contactBoxRef.current.style.display = 'none'
}

function chatProfileClicked(id){
  console.log("chatProfileClicked")
          axiosClient.getRequest(constants.CHAT_URL+"fetch_chats?id="+id)
          .then(function (response) {
            var chatMessagesList = []
            for(var i=0;i<response.data.length;i++){
   if(response.data[i].contact !== "")
     chatMessagesList.push(ReactDOMServer.renderToStaticMarkup(<ChatCustomerMessage text={response.data[i].content} id={response.data[i].id}/>))
   else if(response.data[i].contact !== "")
     chatMessagesList.push(ReactDOMServer.renderToStaticMarkup(<ChatUserMessage text={response[i].data[i].content} id={response.data[i].id}/>))
            }
            //TODO use session storage to display chat messages
            sessionStorage.setItem("chat_messages_"+id, chatMessagesList);
            let div = document.createElement('span');
            div.innerHTML = sessionStorage.getItem("chat_messages_"+id)
            middleBoxRef.current.appendChild(div)
            middleBoxRef.current.style.display='block'
            globalId = id            
          }).catch(function (response) {
              console.log(response.data)
          });
}
  function sendMessage(event){
    console.log("sendMessage "+messageRef.current.value+" "+globalId)
    let payload = {'type' : 'user_message', 'uuid':globalId, 'content': messageRef.current.value,
     'room': userId}
    let div = document.createElement('div')
    div.innerHTML = ReactDOMServer.renderToStaticMarkup(<ChatUserMessage text={messageRef.current.value}/>)
    middleBoxRef.current.appendChild(div)
    div.scrollIntoView()

    socket.send(payload)
  }
	return(
         (ticket != null)       ?    
          <div class="chat-page main-body">
          {/* -------------- Below is left box code */}
            {
              chatProfileList === null ? <LoadingAnimation/> : 
              <div class ="chat-left-box left-chat chat-bg">
 <ChatAgentInfo />
 <div class="chat-search-wrapper">
   <div style= {{position: "relative" }}>
     <img src={require('../../../img/search.png')} alt=''/>
     <input placeholder="search here" class="search " />
   </div>
 </div>

 <div class="chat-listing">
   {
       chatProfileList

   }
 </div>
              </div>              
            }
          {/* -------------- End of  left box code */}
          {/* -------------- Below is middle box code */}
             <div class = 'Middle-Box Middle-Chat middle-chat-height' >
              {<ChatMiddleBoxHeader /> }
              <div class="chat-wrapper ChatMyMessagewrp" >
 <div class="chat-wrapper-items" ref={middleBoxRef}>
 </div>

              <div class="chatbibox-wrapper chat-footer">
 <div class="chatbox-input">
   <input type="text" placeholder="Write something..." ref={messageRef}/>
   <div class="mic-icon" ><img src={require('../../../img/mic.png')} alt=''/></div>
   <ul>    
     <li><img class="chatbox-attachment-img" src={require('../../../img/attachment.png')} /></li>
     <li><img class="chatbox-attachment-img" src={require('../../../img/attachment.png')} /></li>
     <li><img class="chatbox-attachment-img" src={require('../../../img/attachment.png')} /></li>
   </ul>
 </div>
 <div class="chatbibox-footer">   
   <button class='ss-button'  class="send" onClick={sendMessage}><img src={require('../../../img/paper-plane.png')}/> </button>
 </div>
              </div>

              </div>
            </div>

          {/* -------------- End of middle box code */}
            
            <div ref={contactBoxRef}><ChatContactBox closeWindow = {closeContactBox}/></div>
          </div> 
 : <LoadingAnimation />          
          );
}

export default ChatDisplay;