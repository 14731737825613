import React, { useState, useEffect, useRef,createContext } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import ContactGroupLine from "./ContactGroupLine"
import ContactGroupListDisplayHeader from './ContactGroupListDisplayHeader'
import ContactGroupPagination from './ContactGroupPagination'
import CreateContactGroupForm from './CreateContactGroupForm'

function ContactGroupsListDisplay(){
	const primaryCheckBoxRef = useRef(null)
	const [sessionData, setSessionData] = useState(null)
	const [pageNo, setPageNo] = useState(0)
	const [pageSize, setPageSize] = useState(100)
	const [contactGroupLine, setContactGroupLine] = useState("")
	const [checkboxState, setCheckboxState] = useState(false)
	const [showCreateContactGroupForm, setShowCreateContactGroupForm] = useState(false)
	const [ids, setIds] = useState([])
	const groupButtonsRef = useRef(null)
	const editIdRef = useRef(-1)

	useEffect(() => {
		loadContactGroups();
		setSessionData(JSON.parse(sessionStorage.getItem('ss_sess')))
		groupButtonsRef.current.style.visibility = 'hidden'

	},[])//use effect

function loadContactGroups(){
		axiosClient.getRequest(constants.CONTACT_GROUP_URL+'?pageNo='+pageNo+'&pageSize='+pageSize)
		.then(function (response) {
				let contactList = null;
				contactList = response.data.map((element, index) => (									
								<ContactGroupLine contact_group= {element} checkBoxClicked={checkBoxClicked} editContactGroup={editContactGroup}/>
				));
		setContactGroupLine((Object.keys(contactList).length === 0  ? 'No Records Found...' : contactList))
		}).catch(function (response) {
		    console.log(response);
		});	
}
function createContactGroup(){
	let contactsChecked = []
	let elements = document.getElementsByClassName('contact-checkbox')
	for (var i = 0; i < elements.length; i++) {
		if(elements[i].checked)
			contactsChecked.push(elements[i].id)
	
	}
	axiosClient.getRequest(constants.CONTACT_GROUP_URL)
	.then(function (response) {
	let contactList = null;
	contactList = response.data.map((element, index) => (
					<ContactGroupLine contact_group= {element} checkBoxClicked={checkBoxClicked} editContactGroup={editContactGroup}/>
	));
	setContactGroupLine((Object.keys(contactList).length === 0  ? 'No Records Found...' : contactList))
	console.log("ContactGroup line set")
	}).catch(function (response) {
	    console.log(response);
	});		
}

function checkBoxClicked(id, value){
	let idsTemp = ids;
	if(value){
		idsTemp.push(id)
	}else{
		 const index = idsTemp.indexOf(id);
		if (index > -1) {
  			idsTemp.splice(index, 1);
		}
	}
	setIds(idsTemp)
	if(idsTemp.length !== 0){		
		groupButtonsRef.current.style.visibility = 'visible'	
	}else{
		groupButtonsRef.current.style.visibility = 'hidden'
	}
}

function showCreateContactGroupFormFun(){
	setShowCreateContactGroupForm(true)
}
function performAction(type){
	let payload = {'ids': ids}
	let url = null;
	if(type === 'delete')
		url = constants.CONTACT_GROUP_URL+'delete';
	else if(type === 'block')
		url = constants.CONTACT_GROUP_URL+'block';
	else if(type === 'spam')
		url = constants.CONTACT_GROUP_URL+'spam';

//TODO: block becomes unblock
   	axiosClient.postRequest(url, payload)
       .then(function (response) {
           loadContactGroups();
		let elements = document.getElementsByClassName('contact-group-line-checkbox')
		for (var i = 0; i < elements.length; i++) {
			if(elements[i].checked)
				elements[i].checked = false;		
		}           
       }).catch((error) => {
           console.log(error)
       });      
       groupButtonsRef.current.style.visibility = 'hidden'
       primaryCheckBoxRef.current.checked=false
}

function searchContactGroups(event){
	let pageNo=0;
	let pageSize=10;
	axiosClient.getRequest(constants.CONTACT_GROUP_URL+"search?keyword="+encodeURIComponent(event.target.value)+'&pageNo='+pageNo+'&pageSize='+pageSize)
	.then(function (response) {
			let contactList = null;
			contactList = response.data.map((element, index) => (					
							<ContactGroupLine contact_group= {element} checkBoxClicked={checkBoxClicked} editContactGroup={editContactGroup}/>
			));
			setContactGroupLine((Object.keys(contactList).length === 0  ? 'No Records Found...' : contactList))
	}).catch(function (response) {
	    console.log(response);
	});	
}

function showCreateContactGroupFormFun(){
	setShowCreateContactGroupForm(true)
}
function editContactGroup(id){
	editIdRef.current = id
	setShowCreateContactGroupForm(true)
}
function onPrimaryCheckboxClicked(event){
	let idsTemp = ids;
	let checkBoxes = document.getElementsByClassName('contact-group-line-checkbox')
	for(var i=0;i<checkBoxes.length;i++){
		checkBoxes[i].checked = event.target.checked
		let id = checkBoxes[i].name.split('-')[1]
		if(event.target.checked && !idsTemp.includes(id)){
			idsTemp.push(id)
			groupButtonsRef.current.style.visibility = 'visible'				
		}
	}
	if(!event.target.checked){	
		groupButtonsRef.current.style.visibility = 'hidden'
		idsTemp.splice(0,idsTemp.length)
	}
	setIds(idsTemp)

}

function closeCreateWindow(){
	setShowCreateContactGroupForm(false)
	editIdRef.current = -1
}
return (
	<>
	   <ContactGroupListDisplayHeader searchContactGroups={searchContactGroups} 
	   								  showCreateContactGroupFormFun={showCreateContactGroupFormFun}
	   								  groupButtonsRef={groupButtonsRef}
	   								  performAction={performAction}/>
	
		<div class="main-body">
		          <div class="row">
            <div class="col-lg-12">
               <div class>
                  <div class="table-responsive">
                     <table class="table table-nowrap align-middle table-borderless">
                        <thead>
                           <tr>
                              <th scope="col" class="ps-4" style={{width: "50px", backgroundColor:'#f4f4f4'}}>
                                 <div class="form-check font-size-16"><input type="checkbox" class="form-check-input" style={{border: '1px solid #A9A9A9'}} ref={primaryCheckBoxRef} onClick={onPrimaryCheckboxClicked}/></div>
                              </th>
                              <th scope="col" style={{backgroundColor:'#f4f4f4'}}>Name</th>
                              <th scope="col" style={{backgroundColor:'#f4f4f4'}}>Number Of Contacts</th>                                                            
                           </tr>
                        </thead>
                        <tbody style={{textWrap:'nowrap'}}>
                        	{contactGroupLine}                    
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>               

				{ showCreateContactGroupForm ? <CreateContactGroupForm closeCreateWindow={closeCreateWindow}
				editIdRef={editIdRef}/> : '' }
		</div>
	</>
 );

}
export default ContactGroupsListDisplay;
