import React from "react"
import {useState, setState, useRef, useEffect} from 'react';
import DialPad from './DialPad'
import CreateTicketFormMain from '../../Ticket/js/CreateTicketForm'
import CreateTicketForm from './CreateTicketForm'
import {useNavigate} from "react-router-dom"
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import  '../css/call_window.css'
import * as twilioMain from '../../../js/twilio-main';
import Contact from './Contact'

function Contacts(props){
  const dropdownRef = useRef(null)
  const [contactLine, setContactLine] = useState('')
  useEffect(()=>{    
      fetchContacts()  
  },[])

  function searchContacts(event){
      axiosClient.getRequest(constants.CONTACT_URL+"search?keyword="+encodeURIComponent(event.target.value)+'&pageNo=0&pageSize=10')
      .then(function (response) {
          addContactsToDropdown(response)
      }).catch(function (response) {
          console.log(response);
      });
  }
  function addContactsToDropdown(response){
      let contactsList = []
      for(var i=0;i<response.data.length; i++){
            if(response.data[i].phoneNumber !== 'null' && response.data[i].phoneNumber !== '' ){
              contactsList.push({name: response.data[i].name, number: response.data[i].phoneNumber, image: response.data[i].image})
            }
            if(response.data[i].workPhone !== '' && response.data[i].workPhone !== 'null' ){
              contactsList.push({name: response.data[i].name, number: response.data[i].workPhone, image: response.data[i].image})
            }
            if(response.data[i].workPhone1 !== '' && response.data[i].workPhone1 !== 'null' ){
              contactsList.push({name: response.data[i].name, number: response.data[i].workPhone1, image: response.data[i].image})
            }
            if(response.data[i].workPhone2  !== '' &&response.data[i].workPhone2 !== 'null' ){
              contactsList.push({name: response.data[i].name, number: response.data[i].workPhone2, image: response.data[i].image})
            }
          }          
          let contactsArr = contactsList.map((element, index) =>(
                  <Contact name={element.name} number={element.number} callNumber={props.callNumber}/>
                ))
          setContactLine((Object.keys(contactsArr).length === 0  ? <span style={{color: 'white'}}>No Records Found...</span> : contactsArr))    
  }

  function fetchContacts(){
      axiosClient.getRequest(constants.CONTACT_URL)
      .then(function (response) {
          addContactsToDropdown(response) 
      }).catch(function (response) {
          console.log(response)
      });   
  }
  
	return(
				<div>
            <div className = "call-window-input-wrapper" style={{marginTop:'60px'}}>
                <input placeholder= 'Search contacts...' type="text"  className = "call-window-input" onChange={searchContacts}/>
            </div>
            <div style={{marginTop:'5px',maxHeight:'300px', overflowY: 'auto'}}>
              {contactLine}
            </div>
				</div>


	)
}

export default Contacts;