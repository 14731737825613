import React, {useState} from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../js/constants'

function SLAListDisplayHeader(props){

const navigate = useNavigate(); 

function createNewSLA(){
	navigate(constants.SLA_URL_ABS+'create')
}

return (
	<div class='header-body'>
	    <div class="main-header">
	    	<div class="header-left" style={{fontWeight:'bold',paddingLeft:'10px' }}>All SLAs</div>
     		     <div class="search-wrapper">
   					    {<img src={require('../../../img/search.png')} alt=''/>}
   					    <input placeholder="search here" class="search-input" onChange={props.searchSLAs}/>
             </div>
             <div class="header-right" ref={props.groupButtonsRef}>
	            <button class='ss-button'  onClick={() => props.performAction('delete')}>Delete</button>
	         </div>
	         <button class='ss-button' onClick={createNewSLA}>+ New SLA</button>
	    </div>
	</div>
    );

}

export default SLAListDisplayHeader;