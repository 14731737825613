
import React from "react"
import {useState, setState} from 'react';
import {useNavigate} from "react-router-dom"
import * as axiosClient from '../../js/Axios';
import * as constants from '../../js/constants'
;
import '../../css/main.css'
import $ from 'jquery';

function Admin(){

  const fbTemplate = document.getElementById('build-wrap');
  $(fbTemplate).formBuilder();

	return(
		<div class="main-body">
			<div id="build-wrap"></div>
		  </div>
		 )
}

export default Admin;