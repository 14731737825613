import React, { useState, useEffect, useRef,useContext } from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../js/constants'

function NumberLine(props){
	
return (
			<>
			   <a><input type='checkbox' id = {props.number} name={props.number} class='number-checkbox' style={{ marginRight: '10px' }}/> <label for ={props.number} style={{cursor:'pointer',width:'90%'}}><span style={{fontWeight:'500',fontSize:'16px'}}>{props.number}</span></label></a>
			</>
    );

}

export default NumberLine;