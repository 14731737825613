import { CognitoUserPool, CognitoUserAttribute, CognitoUser, AuthenticationDetails, CognitoIdentityCredentials, WebIdentityCredentials } from 'amazon-cognito-identity-js';
import AWS from 'aws-sdk'

const REGION = process.env.REACT_APP_COGNITO_REGION
export const USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID
const CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID


AWS.config.update({
	region: REGION
})
const userData = {
    UserPoolId : USER_POOL_ID,
    ClientId : CLIENT_ID
}
export const userPool = new CognitoUserPool(userData);
export function signUpUser(email, password){
 const p = new Promise((res, rej)=>{  
 const attributeList = []  
 const dataEmail = {
      Name : 'email',
      Value : email
  }
  userPool.signUp(email, password, [], null, 
  				  function(err, result){
      if (err) {
        console.log("Userpool signup error "+ err.message)
          rej(err)
          return
      }
      console.log("Userpool signup result "+ result)
      res(result)
  })
 })
 return p
}
export const GetAuthenticatedUser = () => {
    const userPool = new CognitoUserPool({
        UserPoolId: USER_POOL_ID,
        ClientId: CLIENT_ID
    });
    return userPool.getCurrentUser();
};

 export  const changePassword = (data) => {
    try{
        const currentUser = GetAuthenticatedUser();
        if (currentUser) {
            currentUser.getSession(function (err, session) {
                if (err) {
                    return err
                } else {
                    currentUser.changePassword(data.old_password, data.new_password, function (
                        err,
                        res
                    ) {
                        if (err) {
                            return err.code
                        } else {
                            return res
                        }
                    });
                }
            });
        } else {
            return 'NOT_AUTHENTICATED';
        }
    }catch(err){
      return err
    }
};


export function resetPassword(username) {
    // const poolData = { UserPoolId: xxxx, ClientId: xxxx };
    // userPool is const userPool = new AWSCognito.CognitoUserPool(poolData);

    // setup cognitoUser first
    const userData = {
     Username: username,
     Pool: userPool
    }
    const cognitoUser = new CognitoUser(userData)    

    // call forgotPassword on cognitoUser
    cognitoUser.forgotPassword({
        onSuccess: function(result) {
            console.log('call result: ' + result);
        },
        onFailure: function(err) {
            console.log(err);
        },
        inputVerificationCode() { // this is optional, and likely won't be implemented as in AWS's example (i.e, prompt to get info)
            var verificationCode = prompt('Please input verification code ', '');
            var newPassword = prompt('Enter new password ', '');
            cognitoUser.confirmPassword(verificationCode, newPassword, this);
        }
    });
}

// confirmPassword can be separately built out as follows...  
export function confirmPassword(username, verificationCode, newPassword) {
  
     const userData = {
     Username: username,
     Pool: userPool
    }
    const cognitoUser = new CognitoUser(userData)    

    return new Promise((resolve, reject) => {
        cognitoUser.confirmPassword(verificationCode, newPassword, {
            onFailure(err) {
                reject(err);
            },
            onSuccess() {
                resolve();
            },
        });
    });
}