import React, { useEffect, useRef } from 'react';
import * as constants from '../js/constants'
import  '../css/main.css'
import { io } from 'socket.io-client';
import * as axiosClient from '../js/Axios';

function SocketMain(props){
	var globalSocket = null;
	let socketConnected = true;
	
	useEffect(()=>{
		if(socketConnected){
			socketConnected = false;
			connectToSocket()
		}		
	},[])	

	function connectToSocket(){
		const URL = process.env.NODE_ENV === 'production' ? 'wss://api.supportspoc.com' : 'ws://localhost';
		let token = localStorage.getItem('id_token');
		// const socket = new io(auth: { 'token':  token})
		const socket = io(URL, {auth: (cb) => {
							    cb({
							      token: token
							    });
							  }
							})
		.connect(URL, {
		    'reconnection': true,
		    'reconnectionDelay': 1000,
		    'reconnectionDelayMax' : 5000,
		    'reconnectionAttempts': 5000
		});
		globalSocket = socket
		socket.on('connect', onConnect);
	    socket.on('disconnect', onDisconnect);
	    socket.on('message', onMessage);

		function onMessage(message){
		    message = JSON.parse(message)
		    if(message.type === 'welcome'){
		        localStorage.setItem('chat_token', message.token)
		    }else if(message.type === 'unable_to_connect_to_chat'){		    	
		    }else if(message.type === 'call_ended'){
		    	if(props.socketMainCallbackToDialpad)
		    		props.socketMainCallbackToDialpad(message)
		    }else if(message.type ==='user_status_change'){
		    	if(props.callbackFromTopnav)
		    		props.callbackFromTopnav(message)
		    }
		    else if(message.type === 'customer_accepted_call'){		  
		    	if(props.socketMainCallbackToDialpad)
		    		props.socketMainCallbackToDialpad(message)
		    }
		    else if(message.type === 'call_cancelled'){
		    	if(props.notifyUserCall)
		    		props.notifyUserCall(message)
		    }
		    else if(message.type === 'call_ended'){
		    	if(props.socketMainCallbackToDialpad)
		    		props.socketMainCallbackToDialpad(message)
		    }
		    else if(message.type === 'notify_user_call'||
		    		message.type === 'warm_transfer' ||
		    		message.type === 'cold_transfer'){		    	
		    	if(props.notifyUserCall)
		    		props.notifyUserCall(message)
		    	var message = {"type": "received_notification", "message": message}
		    	socket.send(message) //TODO implement this	    	
		    }
		}
		function onConnect(){
			console.log('onConnect')		    
			let session = JSON.parse(sessionStorage.getItem('ss_sess'))
			if(session == null || session.userId == null)
				return setTimeout(onConnect, 1000)
			var message = {"type": "user_connected", "room" : session.userId }
			socket.send(message)			
		}

	    function onDisconnect(){console.log("onDisconnect")}
		            // inputTextRef.current.disabled = false
	}
		
	return(
	  		<div>
	 		</div>
	 	)
}
export default SocketMain;