import React from "react"
import {useState, setState, useRef, useEffect} from 'react';
import {useNavigate} from "react-router-dom"
import '../css/main.css'
import '../css/loadingAnimation.css'
import {Route, Link, Routes, useParams} from 'react-router-dom';

function LoadingAnimation(props){


	return(
			<div class="main-item">
			  <div class="static-background">
			    <div class="background-masker btn-divide-left"></div>
			  </div>
			  
			  <div class="animated-background">
			    <div class="background-masker btn-divide-left"></div>
			  </div>					  
			</div>

		)
}
export default LoadingAnimation;