  import React from "react"
  import {useState, setState, useRef, useEffect} from 'react';
  import {useNavigate} from "react-router-dom"
  import * as axiosClient from '../../../js/Axios';
  import * as constants from '../../../js/constants'
  import '../../../css/main.css'
  import {over} from 'stompjs';
  
  import { io } from 'socket.io-client';
  const voice = require('@twilio/voice-sdk')

  function ChatUserMessage(props){
      return(
   <div class="chat-header-row">
     <div class="chat-header-img">
     <img src={require('../../../img/Headshot.jpg')} alt=''/>
     </div>
     <div class="chat-header-info">
       <div class="main-header">
         <p>{props.text}</p>
       </div>
     </div>
   </div>        
          )

  }

  export default ChatUserMessage;