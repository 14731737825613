import React, { useState, useEffect, useRef,useContext } from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../js/constants'
import ProfilePicture from '../../ProfilePicture/ProfilePicture'

function CompanyLine(props){

 const navigate = useNavigate(); 
 const [sessionData, setSessionData] = useState(null)
  useEffect(()=>{
    setSessionData(JSON.parse(sessionStorage.getItem('ss_sess')))
},[])
function openCompany(event){
	event.preventDefault()
	props.openCompany(props.company.id)
}
function fetchWorkPhones(){
	let workPhone = '';
	if(props.company.workPhone1 !== '' && props.company.workPhone1 !== 'null')
		workPhone = workPhone+props.company.workPhone1;
	if(props.company.workPhone2 !== '' && props.company.workPhone2 !== 'null')
		workPhone = workPhone+','+props.company.workPhone2;
	if(props.company.workPhone3 !== '' && props.company.workPhone3 !== 'null')
		workPhone = workPhone+','+props.company.workPhone3;
	if(workPhone === '')
		workPhone = '--'
	return workPhone;

}
function checkBoxClicked(event){
	props.checkBoxClicked(props.company.id, event.target.checked)
}

return (
	        <tr scope="row"> 
	            { (sessionData !== null && sessionData.isAdmin) ? 
	            	<th scope="row" class="ps-4">
             				<div class="form-check font-size-16"><input type="checkbox" class="form-check-input company-line-checkbox" onClick={checkBoxClicked} name={'checkbox-'+props.company.id} style={{border:'1px solid #A9A9A9'}}/></div>
          			</th> : '' }
	            <th  onClick = {openCompany}> 
	              {	props.company.image === 'null' ? 
	            		<ProfilePicture name={props.company.name} />
	            		: <img class="list-image"  src={ props.company.link} /> 
	             }
	             <a  href='' style = {{textDecoration: "none", marginLeft: '10px',fontWeight:'800',fonFamily:'Helvetica'}}>{props.company.name}</a>
	            </th>	            
	            <td>
					{(props.company.email === '' || props.company.email === 'null') ?
	                '-' : props.company.email }
	            </td>
	            <td>{(props.company.phoneNumber === '' || props.company.phoneNumber === 'null') ?
	                '-' : props.company.phoneNumber }</td>
	            <td>{fetchWorkPhones()}</td>
	            <td>{(props.company.website === '' || props.company.website === 'null') ?
	                '-' : props.company.website }</td>
	            
	         </tr>
            );

}

export default CompanyLine;