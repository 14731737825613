import React, {useState} from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../js/constants'

function ContactGroupListDisplayHeader(props){

const navigate = useNavigate(); 

function createNewContactGroup(){
	props.showCreateContactGroupFormFun()
}

return (
	<div class='header-body'>
	    <div class="main-header">
	    	<div style={{cursor: 'pointer',fontWeight:'bold',paddingLeft:'10px' }}>All Contact Groups</div>
     		 <div class="search-wrapper">
   					{<img src={require('../../../img/search.png')} alt=''/>}
   					<input placeholder="search here" class="search-input" onChange={props.searchContactGroups}/>
             </div>	          
	         <div class="header-right" ref={props.groupButtonsRef}>
	            <button class='ss-button'   onClick={() => props.performAction('delete')}>Delete</button>	            
	         </div>
	         <button class='ss-button'  style={{width: 'auto',paddingLeft:'10px', paddingRight: '10px', paddingTop: '5px', paddingBottom: '5px' }} onClick={createNewContactGroup}>+ New Contact Group</button>
	    </div>
	</div>
    );

}

export default ContactGroupListDisplayHeader;