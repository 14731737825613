import React, { useState, useEffect, useRef,createContext } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import IVRLine from "./IVRLine"
import IVRListDisplayHeader from './IVRListDisplayHeader'
import CreateIVR from './CreateIVR'
import {ToastContainer, toast } from 'react-toastify';

function IVRListDisplay(){
	const primaryCheckBoxRef = useRef(null)
	const groupButtonsRef = useRef(null)
	const [ids, setIds] = useState([])
	const [IVRLineState, setIVRLineState] = useState('')
	const [checkboxState, setCheckboxState] = useState(false)
	const [showCreateIVRForm, setShowCreateIVRForm] = useState(false)
	useEffect(() => {
		groupButtonsRef.current.style.visibility = 'hidden'	
		loadIVRs()
	},[])//use effect

	function loadIVRs(){
		axiosClient.getRequest(constants.CALL_URL+'ivr')
		.then(function (response) {
		let IVRList = response.data.map((element, index) => (				
						<IVRLine IVR= {element} checkBoxClicked={checkBoxClicked}/>					           
		));
		setIVRLineState(IVRList) 
		}).catch(function (response) {
		    console.log(response);
		});
	}
	function selectMultipleIVRs(){
		let elements = document.getElementsByClassName('IVR-checkbox')
		for (var i = 0; i < elements.length; i++) {
			elements[i].style.visibility = 'visible'
		}
	}

	function createIVRGroup(){
		let IVRsChecked = []
		let elements = document.getElementsByClassName('IVR-checkbox')
		for (var i = 0; i < elements.length; i++) {
			if(elements[i].checked)
				IVRsChecked.push(elements[i].id)
		
		}

		axiosClient.getRequest(constants.CONTACT_URL)
		.then(function (response) {
		let IVRList = response.data.map((element, index) => (
						<IVRLine IVR= {element} checkBoxClicked={checkBoxClicked}/>					           
		));
		setIVRLineState(IVRList) 
		}).catch(function (response) {
		    console.log(response);
		});		
	}

function searchIVRs(event){
	axiosClient.getRequest(constants.CALL_URL+"ivr/search?keyword="+event.target.value)
		.then(function (response) {
		let IVRList = response.data.map((element, index) => (					
						<IVRLine IVR= {element} checkBoxClicked={checkBoxClicked}/>					           
		));
		setIVRLineState(IVRList) 
		}).catch(function (response) {
		    console.log(response);
		});
}
function onPrimaryCheckboxClicked(event){
	let idsTemp = ids;
	let checkBoxes = document.getElementsByClassName('ivr-line-checkbox')
	for(var i=0;i<checkBoxes.length;i++){
		checkBoxes[i].checked = event.target.checked
		let id = checkBoxes[i].name.split('-')[1]
		if(event.target.checked && !idsTemp.includes(id)){
			idsTemp.push(id)
			groupButtonsRef.current.style.visibility = 'visible'				
		}
	}
	if(!event.target.checked){	
		groupButtonsRef.current.style.visibility = 'hidden'
		idsTemp.splice(0,idsTemp.length)
	}
	setIds(idsTemp)

}

function checkBoxClicked(id, value){
	console.log('checkBoxClicked '+value+' '+ids)
	let idsTemp = ids;
	if(value && !idsTemp.includes(id)){
		idsTemp.push(id)
	}else{
		 const index = idsTemp.indexOf(id);
		if (index > -1) {
  			idsTemp.splice(index, 1);
		}
	}
	setIds(idsTemp)
	if(idsTemp.length !== 0){		
		groupButtonsRef.current.style.visibility = 'visible'
	}else{
		groupButtonsRef.current.style.visibility = 'hidden'
	}
	if(!value){
		primaryCheckBoxRef.current.checked = false
	}
	console.log('checkBoxClicked '+ idsTemp)
}

function setShowCreateIVRFormFun(){
	setShowCreateIVRForm(true)
}
function closeCreateWindow(){
	setShowCreateIVRForm(false)
}

function performAction(type){	
	let payload = {'ids': ids}
	let url = null;
	if(type === 'delete')
		url = constants.CALL_URL+'ivr/delete'

   	axiosClient.patchRequest(url, payload)
       .then(function (response) {
	       	loadIVRs()
			let elements = document.getElementsByClassName('ivr-line-checkbox')
			for (var i = 0; i < elements.length; i++) {
				if(elements[i].checked)
					elements[i].checked = false;		
			}
			toast.succcess('IVR Deleted Successfully')
       }).catch((error) => {
           console.log(error)
       }); 
       groupButtonsRef.current.style.visibility = 'hidden' 
       primaryCheckBoxRef.current.checked=false    
}
return (
	<>
		<IVRListDisplayHeader setShowCreateIVRFormFun = {setShowCreateIVRFormFun}
									 groupButtonsRef={groupButtonsRef}
									  performAction={performAction}
									  searchIVRs={searchIVRs}/>
		<div class="main-body">
					         <div class="row">
		            <div class="col-lg-12">
		               <div class>
		                  <div class="table-responsive">
		                     <table class="table table-nowrap align-middle table-borderless">
		                        <thead>
		                           <tr>
		                              <th scope="col" class="ps-4" style={{backgroundColor:'#f4f4f4'}}>
		                                 <div class="form-check font-size-16"><input type="checkbox" class="form-check-input" id="contacusercheck" ref={primaryCheckBoxRef} style={{border:'1px solid #A9A9A9'}} onClick={onPrimaryCheckboxClicked}/></div>
		                              </th>
		                              <th scope="col" style={{backgroundColor:'#f4f4f4'}}>Name</th>
		                              <th scope="col" style={{backgroundColor:'#f4f4f4',width:'80%'}}>Welcome Message</th>
		                           </tr>
		                        </thead>
		                        <tbody>
		                        	{IVRLineState}                    
		                        </tbody>
		                     </table>
		                  </div>
		               </div>
		            </div>
		         </div>         
				{ showCreateIVRForm ? <CreateIVR closeCreateWindow={closeCreateWindow}/> : '' }				
		</div>
		<ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}
                newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss
                draggable pauseOnHover theme="colored"/>						
	</>
 );

}
export default IVRListDisplay;
