import React from "react"
import * as constants from '../../../js/constants'
import {useState, setState, useRef, useEffect} from 'react';
import {useNavigate} from "react-router-dom"
import ProfilePicture from '../../ProfilePicture/ProfilePicture'
import {Route, Link, Routes, useParams} from 'react-router-dom';
import Attachment from './Attachment'
import * as axiosClient from '../../../js/Axios';

function ResolutionLine(props){
    const [edit, setEdit] = useState(false)
    const [attachmentFilesList, setAttachmentFilesList] = useState([])
    let spinnerRef = useRef(null)
    const navigate = useNavigate()
    const [attachmentList, setAttachmentList] = useState([])
    const [attachmentListBackup, setAttachmentListBackup] = useState([])
    const [removedAttachmentList, setRemovedAttachmentList] = useState([])
    const [addedAttachmentList, setAddedAttachmentList] = useState([])
    const contentRef = useRef(null)
    const parser = new DOMParser();
    const commentRef = useRef(null)
    const [comment, setComment] = useState(null)
    const [content, setContent] = useState(null)
    let attachmentsLoaded = false

    useEffect(()=>{
        setComment(props.comment)
        let c = props.content
        setContent(c);
        
        if(!attachmentsLoaded){
          attachmentsLoaded = true
          if(props.comment.attachments !== null){
            let attachmentNames = props.comment.attachments
            let tempAttachmentList = attachmentList
            tempAttachmentList.push(...attachmentNames)
            setAttachmentList(tempAttachmentList)
            setAttachmentListBackup(tempAttachmentList)
          }
        }
    },[])

function removeAttachmentFromEdit(attachmentId){
    var attachmentListArr = [...attachmentList];
    var index = -1;
    for(var i=0;i<attachmentListArr.length;i++){
      if(attachmentId === attachmentListArr[i].id)
        index=i
    }
    if(index === -1)
      return

    attachmentListArr.splice(index, 1);
    setAttachmentList(attachmentListArr);
    setRemovedAttachmentList([...removedAttachmentList, attachmentId])
}


function addComment(){
  const formData = new FormData();
    formData.append('content', commentRef.current.innerHTML)
    formData.append('removed',removedAttachmentList)
    formData.append('isModified', 'true')
      axiosClient.patchRequest(constants.COMMENT_URL+props.comment.id,formData)
        .then(function (response) {
          let c = response.data.content
          setContent(c)
          setComment(response.data)
          setEdit(false)
        }).catch((error) => {

      });  
}
function editButtonClicked(event){
  setEdit(!edit)
}


function getName(){
  if(props.comment.commentee_user !== null)
    return props.comment.commentee_user.name
  if(props.comment.commentee_contact !== null)
    return props.comment.commentee_contact.name
  return 'noname'
}
function openUserOrContact(event){
  event.preventDefault()
  if(props.comment.commentee_user !== 'null')
    navigate(constants.AGENT_URL_ABS+props.comment.commentee_user.id)
  else
    navigate(constants.CONTACT_URL_ABS+props.comment.commentee_contact.id)
}
function deleteClicked(){
  props.deleteClicked(props.comment.id)
}

function displayEditButton(){
  if(props.comment !== undefined && addedByCurrentUser())
    return  <div class='comment-edit-icon' >
               <div style={{position: 'relative',left:'175px'}} class='comment-edit-icon'>
                  <img  src={require('../../../img/dots.png')} alt='' style={{height:'15px', width:'15px'}}/>
                  <div class='comment-edit-dropdown'>
                    <div style={{padding:'5px 10px'}} onClick={editButtonClicked}>Edit</div>
                    <div style={{padding:'5px 10px'}} onClick={deleteClicked}>Delete</div>
                  </div>
               </div>
            </div>
  else
    return ''
}

function cancelEdit(){
  setAttachmentList(attachmentListBackup)
  setRemovedAttachmentList([])
  setEdit(false)
}
function addedByCurrentUser(){
  if(props.comment['commentee_user'] == null)
    return false
  let user = JSON.parse(sessionStorage.getItem('ss_sess'));
  return (user.userId.toString() === props.comment['commentee_user'].id.toString())
}

function renderReporterImage(){
   if(props.comment['commentee_user'] !== null){
    if(props.comment['commentee_user'].image === 'null')
      return (<ProfilePicture name={props.comment['commentee_user'].name} classProp='comment-header-img' smallSize={false}/>)
    else
      return <img src = {props.comment["commentee_user"].image} class='comment-header-img'/>
   }

   if(props.comment['commentee_contact'] !== null){
    if(props.comment['commentee_contact'].image === 'null')
      return (<ProfilePicture name={props.comment['commentee_contact'].name} classProp='comment-header-img' smallSize={false}/>)
    else
      return <img src = {props.comment["commentee_contact"].image} class='comment-header-img'/>
   }
   
}

	return (
			<div class="ticket-comment" style={{backgroundColor: (props.comment.is_private === 'true' ) ? '#F6F4EB' : 'white' }}>
         { displayEditButton() }
         <div style={{marginBottom:'10px',fontWeight:'600',display:'flex',justifyContent:'left',color:'#000' }}>{ (props.comment.is_private === 'true' ) ? 'Private Comment' : '' }</div>
         <div style = {{display:'flex'}}>
            {renderReporterImage()}
            <div>
              <div style={{fontSize:'14px',fontWeight:'500'}}><strong>Added By <a href='' style={{textDecoration: 'none',textAlign: 'center'}} onClick={openUserOrContact}>{getName()}</a></strong></div>
              <div style={{fontSize:'12px',color:'black' }}>{props.comment.created_date}</div>
            </div>            
          </div>

                 
      <div class="comment-header-info"  style={{backgroundColor: (props.comment.is_private === 'true' ) ? '#F6F4EB' : 'white' }}>
           { 
             !edit ?
                      <> 
                        <div style={{paddingLeft: '55px', marginTop:'30px',fontWeight: '500', fontSize: '16px',color : 'black'}} dangerouslySetInnerHTML={{ __html: content }}/>
                        <div style={{display: 'flex',flexWrap:'wrap'}}>                        
                        {
                            attachmentList.map((attachment,index) => 
                                <Attachment index={index} isEditing={edit} name={attachment.name} 
                                removeAttachmentFromEdit={removeAttachmentFromEdit}
                                attachment_id={attachment.id} source={'comment'}
                                size={attachment.size} type={attachment.type}/>
                        )}
                        </div>
                      </>  
                   :
                <>
                  <div contenteditable='true' spellcheck='true' rows = '8'  placeholder="Type a message" ref={commentRef} id='editor' style={{fontWeight:'400',height:'400px',padding:'24px',fontSize:'16px',lineHeight:'1.5' }} ref={commentRef} dangerouslySetInnerHTML={{ __html: (content === '' ? '' : content) }} />
                  <div style={{display: 'flex',flexWrap:'wrap'}}>                      
                      {
                        attachmentList.map((attachment,index) => 
                        <Attachment index={index} isEditing={edit} name={attachment.name}
                        removeAttachmentFromEdit={removeAttachmentFromEdit}
                        attachment_id={attachment.id} source={'comment'}/>
                      )}
                  </div>
                  <div class="chatbibox-footer">
                     <ul style={{textAlign:'center',margin: 'auto'}}>
                      <li style={{textAlign:'center',margin: 'auto'}}>
                        <button class='ss-button'  class="add-comment-button" onClick={cancelEdit}><img src={require('../../../img/paper-plane.png')} style={{textAlign: 'center'}}/> 
                          Cancel
                        </button>
                      </li>

                      <li style={{textAlign:'center',margin: 'auto'}}>
                        <button class='ss-button'  class="add-comment-button" onClick={addComment}><img src={require('../../../img/paper-plane.png')} style={{textAlign: 'center'}}/> 
                          <span class="spinner-border" role="status" style={{color: 'white', height:'15px', width:'15px',position: 'relative',display: 'none' }} ref={spinnerRef}/>
                          Edit
                        </button>
                      </li>
                    </ul>
                  </div>
                 </>
               }
            </div>
        </div>

		)
}


export default ResolutionLine;