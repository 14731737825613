import React, { useState, useEffect, useRef,useContext } from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../js/constants'
function CallRouteLine(props){

const navigate = useNavigate(); 
function openCallRoute(){
	navigate(constants.CALL_ROUTE_URL_ABS+props.callRoute.id)
}
function checkBoxClicked(event){
	props.checkBoxClicked(props.callRoute.id, event.target.checked)
}

return (

		    <tr>
			    <th scope="row" class="ps-4" style={{maxWidth: '50px'}}>
	             <div class="form-check font-size-16"><input type="checkbox" class="form-check-input callroute-line-checkbox" onClick={checkBoxClicked} name={'checkbox-'+props.callRoute.id} style={{border:'1px solid #A9A9A9'}} onClick={checkBoxClicked}/></div>
	          	</th>		    	
		        <td onClick = {openCallRoute} style = {{width: "98%", cursor:'pointer',fontWeight:'800',fonFamily:'Helvetica'}}><a href='' style = {{textDecoration: 'none'}}>{props.callRoute.name}</a></td>
		    </tr>		
        );

}

export default CallRouteLine;