
import React from "react"
import {useState, useRef} from 'react';
import {useNavigate} from "react-router-dom"
import * as axiosClient from '../../../../js/Axios';
import * as constants from '../../../../js/constants'

function AddLicenses(props){
	const numberOfLicensesRef = useRef(null)
	const totalCostRef = useRef(null)
	const buttonRef = useRef(null)
	const errorRef = useRef(null)
	const actionTypeRef = useRef(null)
	const CALCULATE_COST = 'Calculate Cost'
	const RENEW_NOW = 'Renew Now'
	const [numberOfLicenses, setNumberOfLicenses] = useState(null)
	const [proceedWithLicense, setProceedWithLicense] = useState(false)
	const [addLicenseButtonValue, setAddLicenseButtonValue] = useState(CALCULATE_COST)
	const [totalAmount, setTotalAmount] = useState(null)

function changeNumberOfLicenses(event){
	setNumberOfLicenses(event.target.value)
	setProceedWithLicense(false)
	setTotalAmount(null)
	totalCostRef.current.innerHTML = ''
	buttonRef.current.innerHTML = CALCULATE_COST
}
function calculateLicenseCost(event){
	errorRef.current.style.display='none'
	console.log('calculateLicenseCost '+event.target.innerHTML)
	if(event.target.innerHTML === 'Add Licenses' ){
		props.spinnerRef.current.style.display = 'inline-block'
		let payload = {'numberOfLicenses' : numberOfLicensesRef.current.value,
						'action':'add_licenses',
						'amount': totalAmount
			  		}
		
		if(proceedWithLicense){
			if(props.subscription.isTrial){
				props.sendSuccessRequest(payload)
			}else{
				return props.createOrder(payload)
			}
		}
	}else if(event.target.innerHTML === 'Remove Licenses'){
		removeLicenses(event);
		return
	}
	else{
		if(numberOfLicensesRef.current.value === ''){
			return displayError('Number of Licenses is Mandatory')
		}
		if(isNaN(numberOfLicensesRef.current.value)){
			return displayError('Only digits are allowed for licenses')
		}
		if(Number(numberOfLicensesRef.current.value) === 0){
			return displayError('Number of licenses must be atleast one')
		}
		props.spinnerRef.current.style.display = 'inline-block'
		let payload = {'numberOfLicenses' : numberOfLicensesRef.current.value,
						'action':actionTypeRef.current.value
					  }
		
		if(props.subscription.isTrial){
				setProceedWithLicense(true)				
				totalCostRef.current.innerHTML= '0 (Adding Licenses is Free during Trial)'
				setTotalAmount(0)
				props.spinnerRef.current.style.display = 'none'
				console.log('actionTypeRef '+ actionTypeRef.current.value)
				if(actionTypeRef.current.value === 'add_licenses')
					setAddLicenseButtonValue('Add Licenses')
				else
					setAddLicenseButtonValue('Remove Licenses')
				return			
		}
	    axiosClient.postRequest('payments/calculate_cost',payload)
			.then(function (response) {
				setProceedWithLicense(true)				
				totalCostRef.current.innerHTML=response.data.amount
				setTotalAmount(response.data.amount)
				props.spinnerRef.current.style.display = 'none'
				if(actionTypeRef.current.value === 'add_licenses')
					setAddLicenseButtonValue('Add Licenses')
				else
					setAddLicenseButtonValue('Remove Licenses')				
			}).catch(function (error) {
			    displayError(error.response.data.error)
			    console.log(error)
			    props.spinnerRef.current.style.display = 'none'
			})
	}
}

function removeLicenses(event){
	event.target.disabled = true
		let payload = {'numberOfLicenses' : numberOfLicensesRef.current.value,
				'action':'remove_licenses',
				'amount': totalCostRef.current.innerHTML
	  		}
		axiosClient.postRequest('payments/remove_licenses',payload)
			.then(function (response) {
				window.location.reload()
			}).catch(function (response) {
			    console.log(response);
			    displayError('Unable to process the request. Please try again after sometime')
			    event.target.disabled = false
			})		  		
		return	
}

function displayError(msg){
  errorRef.current.innerHTML = "<span style='color:red'>*&nbsp;&nbsp;"+msg+"</span>"
  errorRef.current.style.display='block'
  errorRef.current.scrollIntoView()
}
function actionChanged(){
	numberOfLicensesRef.current.value = ''
	totalCostRef.current.innerHTML = ''
	buttonRef.current.innerHTML= CALCULATE_COST
}
	return(
			<div class="create-wrapper">      	      	
	          <div style={{boxShadow:'0 0 2px grey', padding:'30px', paddingleft:'0px',backgroundColor:'white',height: 'calc(100vh - 105px', overflowY: 'auto'}}>
	          		<a href="javascript:void(0)" class="create-closebtn" onClick={props.closeAddLicnesesWindow}>&times;</a>
				   	<div style={{fontSize:'28px', fontWeight: '800', margin:'auto',display: 'table',color:'#044caf'}}>Manage Licenses: </div>
				    		<table class='table ' style={{marginTop:'30px'}}>
								<tr style={{height:'50px'}}>
								   <td style={{ marginTop:'16px',fontSize:'16px',fontWeight:'600'}}>Billing Cycle</td>
								   <td style={{fontSize:'16px'}}>{(props.subscription === null) ? '--' : props.subscription.billingCycle}</td>
								</tr>
								<tr style={{height:'50px'}}>
								   <td style={{ marginTop:'16px',fontSize:'16px',fontWeight:'600'}}>Add/Remove License</td>
								   <td style={{fontWeight:'400',marginLeft:'10px',fontSize:'16px'}}><select style={{fontWeight:'400',fontSize:'16px',padding:'0px',height:'35px',width:'150px', backgroundColor: 'white'}} onChange={actionChanged} ref={actionTypeRef}><option value='add_licenses'>Add License</option><option value='remove_licenses'>Remove License</option></select></td>
								</tr>
								<tr style={{height:'60px'}}>
								   <td style={{ marginTop:'16px',fontSize:'16px',fontWeight:'600'}}>Number of Licenses</td>
								   <td style={{fontWeight:'400',marginLeft:'10px',fontSize:'16px'}}><input type='text'  maxlength='2' min='1' max='99' style={{border:'1px solid black', height:'35px',width:'150px',backgroundColor: 'white',textAlign:'center'}}  ref={numberOfLicensesRef} onChange={changeNumberOfLicenses} value={numberOfLicenses}/></td>
								</tr>
								<tr style={{height:'50px',marginTop:'30px'}}>
								   <td style={{ marginTop:'16px',fontSize:'16px',fontWeight:'600'}}>Cost Per License</td>
								   <td style={{fontWeight:'400',marginLeft:'10px',fontSize:'16px'}}>{(props.subscription === null ) ? '--' : props.subscription.cost}$ Per Month</td>
								</tr>
								<tr style={{height:'50px',marginBottom:'3%'}}>
								   <td style={{ marginTop:'16px',fontSize:'16px',fontWeight:'600'}}>Total Cost</td>
								   <td style={{ marginTop:'16px',fontSize:'16px',fontWeight:'600'}} ref={totalCostRef} >--</td>
								</tr>
							</table>
						<div>
							<button class='ss-button' style={{marginLeft:'30%', padding:'5px 10px',width:'150px',display:'inline-block' }} onClick={calculateLicenseCost} ref={buttonRef}>{addLicenseButtonValue}</button>
						</div>

						<div ref={errorRef} style={{fontSize:'16px'}}/>
				</div>
			</div>
		 )
}

export default AddLicenses;