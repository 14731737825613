export const ATTACHMENT_URL = 'attachments/'
export const ATTACHMENT_URL_ABS = '/attachments/'
export const CONTACT_URL="contacts/"
export const COMPANY_URL="companies/"
export const CONTACT_URL_ABS="/contacts/"
export const COMPANY_URL_ABS="/companies/"
export const TICKET_URL="tickets/"
export const TICKET_URL_ABS="/tickets/"
export const USER_URL="users/"
export const USER_URL_ABS="/users/"
export const AGENT_URL_ABS="/agents/"
export const AGENT_URL="agents/"
export const COMMENT_URL="comments/"
export const COMMENT_URL_ABS="/comments/"
export const CONTACT_GROUP_URL="contact_groups/"
export const CONTACT_GROUP_URL_ABS="/contact_groups/"
export const BUSINESS_CALENDER_URL="business_calenders/"
export const BUSINESS_CALENDER_URL_ABS="/business_calenders"
export const BUSINESS_HOURS_URL="admin/business_hours/"
export const BUSINESS_HOURS_URL_ABS="/admin/business_hours/"
export const TIMEZONE_URL="timezones/"
export const COMPANY_GROUP_URL='company_groups/'
export const COMPANY_GROUP_URL_ABS='/company_groups/'
export const DEPARTMENT_URL='departments/'
export const DEPARTMENT_URL_ABS='/departments/'
export const CHANNEL_URL='channels/'
export const CHANNEL_URL_ABS='/channels/'
export const TEAM_URL='teams/'
export const TEAM_URL_ABS='/teams/'
export const TICKET_TYPE_URL='ticket_types/'
export const TICKET_TYPE_URL_ABS='/ticket_types/'
export const LOGIN_URL="login/"
export const LOGIN_URL_ABS="/login/"
export const SIGNUP_URL="accounts/"
export const CALL_URL='calls/'
export const CALL_URL_ABS='/calls/'
export const CALL_ROUTE_URL='admin/call_routes/'
export const CALL_ROUTE_URL_ABS='/admin/call_routes/'
export const IVR_URL='admin/ivr/'
export const IVR_URL_ABS='/admin/ivr/'
export const SLA_URL='/admin/sla/'
export const SLA_URL_ABS='/admin/sla/'
export const SLA_URL_REQ='sla/'
export const SLA_URL_ABS_REQ='/sla/'
export const CHAT_URL = 'chats/'
export const CHAT_URL_ABS = '/chats/'
export const ADMIN_URL = 'admin/'
export const ADMIN_URL_ABS = '/admin/'
export const ACCOUNT_URL = 'accounts/'
export const ACCOUNT_URL_ABS = '/accounts/'
export const PHONE_NUMBER_URL = 'admin/phone_number/'
export const PHONE_NUMBER_URL_ABS = '/admin/phone_number/'
export const SESSION_URL = 'session/'
export const SESSION_URL_ABS = '/session/'
export const TWILIO_URL = 'twilio/'
export const TWILIO_URL_ABS = '/twilio/'
export const ANYBODY='anybody'
export const PAYMENT_URL='payments/'