import React, {useState} from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../../js/constants'

function PhoneNumberListDisplayHeader(props){

const navigate = useNavigate(); 

function openPhoneNumber(id){
	let path = constants.CONTACT_URL_ABS +props.phoneNumber.id ;
    console.log("path is "+path)
    navigate(path);
}

function purchasePhoneNumber(){
	props.setShowPurchasePhoneNumberFormFun()  	
}

return (
	<div class='header-body'>
	    <div class="main-header">
     		 <div class="search-wrapper">
   					{<img src={require('../../../../img/search.png')} alt=''/>}
   					<input placeholder="search here" class="search-input" onChange={props.searchPhoneNumbers}/>
             </div>	          
	         <div class="header-right">	            
	            <button class='ss-button'  type="button" onClick={purchasePhoneNumber} style={{opacity: '95%', padding: '5px', borderRadius: '8px', fontWeight: '600', outline:'none', color: 'white', backgroundColor: 'rgb(2, 99, 224)'}}>Purchase Number</button>
	         </div>
	    </div>
	</div>
    );

}

export default PhoneNumberListDisplayHeader;