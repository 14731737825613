import React from "react"
import {useState, useEffect, useRef} from 'react';
import {useNavigate} from "react-router-dom"
import * as axiosClient from '../../../../js/Axios';
import * as constants from '../../../../js/constants'

function RenewSubscription(props){
	const billingCycleRef = useRef(null)
	const errorRef = useRef(null)
	const [amount, setAmount] = useState('0')
	const CALCULATE_COST = 'Calculate Cost'
	const RENEW_NOW = 'Renew Now'
	const [buttonName, setButtonName] = useState(CALCULATE_COST)
	const [costPerUnit, setCostPerUnit] = useState(null)
	const [proceedWithRenew, setProceedWithRenew] = useState(false)
	const [plan, setPlan] = useState(null)

useEffect(()=>{
	// if(props.subscription.isTrial){
		if(props.plan === 'STANDARD'){
			setCostPerUnit(6)
			setPlan('STANDARD')
		}else if(props.plan === 'COMBO'){
			setCostPerUnit(10)
			setPlan('COMBO')
		}
	// }else{
	// 	setCostPerUnit(props.subscription.cost)
	// }
},[])

function displayError(msg){
  errorRef.current.innerHTML = "<span style='color:red'>*&nbsp;&nbsp;"+msg+"</span>"
  errorRef.current.style.display='block'
  errorRef.current.scrollIntoView()
}
function renewNow(event){
	event.preventDefault()
	console.log('RenewNow '+ (event.target.innerHTML === CALCULATE_COST))
	let payload = {action:'renew', 
					billingCycle: billingCycleRef.current.value,
					plan: plan
				}
	if(event.target.innerHTML === CALCULATE_COST){
			props.spinnerRef.current.style.display = 'block'
		    axiosClient.postRequest(constants.PAYMENT_URL+'calculate_cost',payload)
				.then(function (response) {					
					props.spinnerRef.current.style.display = 'none'					
					setAmount(response.data)
					setButtonName(RENEW_NOW)
				}).catch(function (error) {
				    displayError(error.response.data.error)
				    console.log(error)
				    props.spinnerRef.current.style.display = 'none'
				})
	}else if(event.target.innerHTML === RENEW_NOW){
		payload['amount'] = amount
		payload['plan'] = props.plan
		props.createOrder(payload)
	}
}

function handleBillingCycleChange(){
	setAmount(0)
	setButtonName(CALCULATE_COST)
}
	return(
	    <div class="create-wrapper">      	      	
	          <div style={{boxShadow:'0 0 2px grey', padding:'30px', paddingleft:'0px',backgroundColor:'white',height: 'calc(100vh - 105px', overflowY: 'auto'}}>
	          		<a href="javascript:void(0)" class="create-closebtn" onClick={props.hideRenewSubscriptionWindow}>&times;</a>
				   	<div style={{fontSize:'28px', fontWeight: '800', margin:'auto',display: 'table',color:'#044caf'}}>Subscription Details: </div>
				      <table class='table' style={{marginTop:'30px'}}>
						<tr style={{height:'50px'}}>
							<td style={{ marginTop:'16px',fontSize:'16px',fontWeight:'600'}}>Billing Cycle: </td>
							<td style={{fontWeight:'400',marginLeft:'10px',fontSize:'16px'}}><select style={{backgroundColor: 'white', fontWeight:'400',fontSize:'16px',padding:'0px',height:'35px',width:'100px'}} onChange={handleBillingCycleChange} ref={billingCycleRef}><option>Monthly</option><option>Yearly</option></select></td>
						</tr>
						<tr style={{height:'50px'}}>
							<td style={{ marginTop:'16px',fontSize:'16px',fontWeight:'600'}}>Number of Licenses for Agents: </td>
							<td style={{fontWeight:'400',marginLeft:'10px',fontSize:'16px'}}>{(props.subscription === null) ? '' : props.subscription.numberOfLicenses}</td>
						</tr>						
						<tr style={{height:'50px'}}>
							<td style={{ marginTop:'16px',fontSize:'16px',fontWeight:'600'}}>Number of Active Agents: </td>
							<td style={{fontWeight:'400',marginLeft:'10px',fontSize:'16px'}}>{(props.subscription === null) ? '' : props.subscription.numberOfAgents}</td>
						</tr>
						<tr style={{height:'50px'}}>
							<td style={{ marginTop:'16px',fontSize:'16px',fontWeight:'600'}}>Cost Per Agent: </td>
							<td style={{fontWeight:'400',marginLeft:'10px',fontSize:'16px'}}>{(costPerUnit === null ) ? '--' : costPerUnit} $</td>
						</tr>
						<tr style={{height:'50px'}}>
							<td style={{ marginTop:'16px',fontSize:'16px',fontWeight:'600'}}>Total Cost: </td>
							<td style={{fontWeight:'400',marginLeft:'10px',fontSize:'16px',fontWeight:'600'}}>{amount}$</td>
						</tr>
					</table>
					<div><button class='ss-button' style={{marginTop:'3%', marginLeft:'25%',padding:'5px 10px',width:'150px' }} onClick={renewNow} >{buttonName}</button></div>
				</div>	          	         
	        </div>
	)
}

export default RenewSubscription;