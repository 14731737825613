import React from "react"
import {useState, setState, useRef, useEffect} from 'react';
import DialPad from './DialPad'
import CreateTicketForm from './CreateTicketForm'
import {useNavigate} from "react-router-dom"
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import  '../css/call_window.css'
import * as twilioMain from '../../../js/twilio-main';
import RecentCalls from './RecentCalls'
import Contacts from './Contacts'

function CallWindow(props){
  const NO_PHONE_NUMBER = 'phone_number_not_present'
  const CALL_ENDED = 'call_ended'
  const RECENT_CALLS = 'recent_calls'
  const CONTACTS = 'contacts'
  const ALL_OK = 'all_ok'
  const bodyRef = useRef(null)
  const [numberFromContact, setNumberFromContact] = useState(null)
  const timerRef = useRef()
  const [state, setState] = useState(ALL_OK)
  const callWindowNumberListRef = useRef(null)
  const [phoneNumbers, setPhoneNumbers] = useState(null)
  const phoneNumberRef = useRef(null)
  const callWindowRef = useRef(null)
  const [callId, setCallId] = useState(null)
  const [callInProgress, setCallInProgress] = useState(false)
  const [phoneNumberPresent, setPhoneNumberPresent] = useState(false)
  const navigate = useNavigate(null)

    useEffect(()=>{
      fetchNumbers(); 
      twilioMain.setUpTwilioDevice()     
  },[])

  function openCallWindow(){
    console.log('openCallWindow '+callInProgress)
    if(callInProgress)
      return
    console.log('openCallWindow '+callWindowRef.current.style.visibility)
    if(callWindowRef.current.style.visibility === 'hidden')
      callWindowRef.current.style.visibility = 'visible'
  }
  function toggleCallWIndow(){
    if(callInProgress)
      return
    if(callWindowRef.current.style.visibility === 'hidden')
      callWindowRef.current.style.visibility = 'visible'
    else
      callWindowRef.current.style.visibility = 'hidden'
  }
  function fetchNumbers(){
      axiosClient.getRequest(constants.ACCOUNT_URL +'phone_number')
    .then(function (response) {
       let phoneNumbersArr = []
        response.data.forEach((element, index)=>{
            phoneNumbersArr.push(element.phoneNumber)
          })
          if(phoneNumbersArr.length === 0){
              setState(NO_PHONE_NUMBER)
              return
          }
          if(phoneNumberRef.current !== null)
            phoneNumberRef.current.innerHTML = phoneNumbersArr[0]
          if(callWindowNumberListRef.current)
            callWindowNumberListRef.current.innerHTML = ''
          if(callWindowNumberListRef.current){
            for(var i = 0;i< phoneNumbersArr.length; i++){
              let div = document.createElement('div')
              div.onclick=phoneNumberSelected
              div.className = 'class-window-list-item'
              div.innerHTML = phoneNumbersArr[i];
              callWindowNumberListRef.current.appendChild(div)
             }
          }
          setPhoneNumbers(phoneNumbers)         
    }).catch(function (response) {
        console.log(response)
    });   
  }

  function buyPhoneNumber(){
    navigate('/admin/phone_numbers?purchase')
    callWindowRef.current.style.visibility = 'hidden'
  }

  function phoneNumberSelected(event){
    phoneNumberRef.current.innerHTML = event.target.innerHTML;
    showPhoneNumbers()
  }

  function showPhoneNumbers(){
  if(callWindowNumberListRef.current.style.display !== 'block'){
      callWindowNumberListRef.current.style.display = 'block'
      callWindowNumberListRef.current.parentElement.children[0].style.display = 'block'
      bodyRef.current.style.display = 'none'
  }else{
      callWindowNumberListRef.current.parentElement.children[0].style.display = 'none'
      callWindowNumberListRef.current.style.display = 'none'      
      bodyRef.current.style.display = 'block'    
    }
  }

function handleFooterClick(event, type){
  event.preventDefault()
  let elements = document.getElementsByClassName('footers')
  for(var i=0;i<elements.length;i++)
    elements[i].style.textDecoration = 'none'
  event.target.style.textDecoration = 'underline'
  if(type === 'keypad'){
    setState(ALL_OK)
  }else if(type==='calls'){
    setState(RECENT_CALLS)
  }else
  setState(CONTACTS)
}

function setCallInProgressFun(value){
  setCallInProgress(value)
}

function cancelTicket(){
  setState(ALL_OK)
}
function callNumber(number){
  console.log('calling number '+number)
  setNumberFromContact(number)
  setState(ALL_OK)  
}
  function renderCallWindow(){    
    if(state === NO_PHONE_NUMBER) 
      return  <div class='phone-number-purchase-message'><div><div className='call-window-buy-message'>Phone Number not Purchased yet</div><button class='ss-button'  className = 'call-window-buy-buton' onClick={buyPhoneNumber}>Buy PhoneNumber </button></div></div>
    if(state === CALL_ENDED) 
      return  <CreateTicketForm callId={callId} cancelTicket={cancelTicket}/>
    if(state === RECENT_CALLS){
      return <RecentCalls callNumber={callNumber}/>
    }
    if(state === CONTACTS){
      return <Contacts callNumber={callNumber}/>
    }
    else if(state === ALL_OK)
      return <DialPad showPhoneNumbers={showPhoneNumbers}
                      phoneNumberRef={phoneNumberRef}
                      openCallWindow={openCallWindow}
                      timerRef={timerRef} 
                      setCallInProgressFun={setCallInProgressFun}
                      setCallId={setCallId}
                      numberFromContact={numberFromContact}
                      setState={setState}/>
  }

	return(
		<div>
				<div className="call-window" ref={callWindowRef} style={{visibility: 'hidden'}}>
            <span style={{cursor: 'pointer', left:'0px', display:'inline', color:'white', top:'15px', marginLeft:'10px', position:'absolute', fontWeight: 'bolder'}} ref={phoneNumberRef} onClick={showPhoneNumbers}/>
            <span style={{right:'0px', display:'inline', color:'white', top:'15px', marginRight:'10px', position:'absolute', fontWeight: 'bolder'}} ref={timerRef}/>
        		<div  ref={bodyRef} style={{marginTop:'10px',width:'325px',borderRight:'1px solid white'}}>
  				    	{ 
                  renderCallWindow()
  						  }             
					  </div>              
            <div className="call-window-list">
              <div style={{display:'none', fontWeight: 'bolder', paddingLeft:'15px', marginBottom: '50px', color:'white', cursor: 'pointer'}}>Numbers: </div>
              <div ref={callWindowNumberListRef} style={{display: 'none'}}/>                
            </div>
        { (state === CALL_ENDED || state === NO_PHONE_NUMBER)? '' :
          <div st class='call-window-footer'>
            <a href='' style={{fontSize: '18px', color: 'white', textDecoration: 'underline',cursor:'pointer' }} class='footers' onClick={(event)=>handleFooterClick(event,'keypad')}><img style={{width:'12px', height:'12px'}} src={require('../../../img/keypad.png')}/></a> 
            <a href='' style={{fontSize: '18px', color: 'white', textDecoration: 'none',cursor:'pointer' }} class='footers' onClick={(event)=>handleFooterClick(event,'calls')}><img style={{right:'20px', position:'relative', width:'15px', height:'15px',rotate:'-45deg'}} src={require('../../../img/recent-calls.png')}/></a> 
            <a href='' style={{fontSize: '18px', color: 'white', textDecoration: 'none',cursor:'pointer' }} class='footers' onClick={(event)=>handleFooterClick(event,'contacts')}><img style={{right:'40px', position:'relative', width:'12px', height:'12px'}} src={require('../../../img/contacts-callwindow.png')}/></a> 
          </div>            
        }
				</div>
			 <div onClick={toggleCallWIndow}><button class='ss-button'  className="call-window-button">Call</button></div>       

		</div>
	)
}

export default CallWindow;