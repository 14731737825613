import React from "react"
import {useState, setState, useRef, useEffect} from 'react';
import {useNavigate} from "react-router-dom"
import '../css/main.css'
import '../css/loadingAnimation.css'
import {Route, Link, Routes, useParams} from 'react-router-dom';
import * as axiosClient from '../js/Axios';
import * as constants from '../js/constants';

function Account(props){

useEffect(()=>{		
		let url = window.location.href;
		console.log('useEffect in Accont '+ window.location)
		if(url.includes('activation?code')){
			const urlParams = new URLSearchParams(window.location.search);
			const code = urlParams.get('code');
			axiosClient.postRequest(constants.ACCOUNT_URL+'activate?code='+code)
			.then(function (response) {
			
			}).catch(function (response) {
			    console.log(response);
			});
		}
		window.location.href='/login'
})

return(<div></div>)
}
export default Account;