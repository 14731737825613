import React from "react"
import {useState, setState, useRef, useEffect} from 'react';
function SmallScreenError(){

return(		
		 <div style={{width:'fit-content', minHeight:'fit-content',backgroundColor:'white'}}>
		   <div style={{width:'fit-content', height:'fit-content',backgroundColor:'white',boxSizing: 'border-box'}}>
		      	<div style={{paddingTop:'20%',backgroundColor:'white',boxSizing: 'border-box'}}> <div style={{marginTop:'10%',width:'300px', height:'150px',display:'table', margin:'auto'}}> <img src={require('../../img/logo.png')} /></div></div>
		      	<div style={{padding:'6%',lineHeight:'2'}}>
		      		<h1 style={{fontSize:'32px'}}><strong>Mobile Support Coming Soon!</strong></h1>
			  		<p style={{marginTop:'5%',fontSize:'20px',backgroundColor:'white'}}>Our website currently does not support mobile devices. We apologize for the inconvenience.</p>
			  		<p style={{fontSize:'20px',backgroundColor:'white'}}>We are working hard to launch a mobile app shortly. Stay tuned for updates!</p>
			  	</div>
		   </div>
		 </div> 
)
}

export default SmallScreenError;