import React from "react"
import {useState, useRef, useEffect} from 'react';
import {useNavigate} from "react-router-dom"
import * as axiosClient from '../../../../js/Axios';
import * as constants from '../../../../js/constants'
import {ToastContainer, toast } from 'react-toastify';


function AddAddress(props){
	const lnameRef = useRef(null)
	const fnameRef = useRef(null)
	const countryRef = useRef(null)
	const cityRef = useRef(null)
	const zipRef = useRef(null)
	const stateRef = useRef(null)
	const addressRef = useRef(null)

	useEffect(()=>{
		let address;	
		if(props.address){
			if(props.addressType.current === 'Shipping'){
				 address = JSON.parse(props.address)
				 address = address.shippingAddress
			}else{
				address = JSON.parse(props.address)
				address = address.billingAddress
			}
			if(Object.keys(address).length !== 0)
			{
				lnameRef.current.value = address.lastName
				fnameRef.current.value = address.firstName
				addressRef.current.value = address.address
				countryRef.current.value = address.country
				cityRef.current.value = address.city
				stateRef.current.value = address.state
				zipRef.current.value = address.zipcode
			}
		}
		
	})

	function handleSubmit(event){
		event.preventDefault()
		let payload = { address:{
			firstName: event.target.firstname.value,
			lastName: event.target.lastname.value,
			country: event.target.country.value,
			city: event.target.city.value,
			state: event.target.state.value,
			address: event.target.address.value,
			zipcode: event.target.zipcode.value,
			same: event.target.same.checked,
			type: props.addressType.current
		 }
		}
		axiosClient.patchRequest(constants.ACCOUNT_URL+'modify_address',payload)
		.then(function (response) {
			toast.success('Address Updated')
			props.hideAddress()
		}).catch(function (response) {
		    console.log(response);
		  
		})
	}


	return(	
			<div class="create-wrapper">      	      	
	          <div style={{boxShadow:'0 0 2px grey', padding:'30px', paddingleft:'0px',backgroundColor:'white',height: 'calc(100vh - 105px', overflowY: 'auto'}}>
	          		<a href="javascript:void(0)" class="create-closebtn" onClick={props.hideAddress} >&times;</a>
					<div style={{fontSize:'28px', fontWeight: '800', margin:'auto',display: 'table',color: '#044caf'}}>{props.addressType.current} Address: </div>
						<div class="container">									  
							<form class="form" onSubmit={handleSubmit}>							    
							  <div class="fields fields--2">
							    <label class="field">
							      <span class="field__label" for="firstname" style={{fontWeight:'800'}}>First name</span>
							      <input class="field__input" type="text" name="firstname"  required ref={fnameRef}/>
							    </label>
							    <label class="field">
							      <span class="field__label" for="lastname" style={{fontWeight:'800'}}>Last name</span>
							      <input class="field__input" type="text" name="lastname" required ref={lnameRef}/>
							    </label>
							  </div>
							  <label class="field">
							    <span class="field__label" for="address" style={{fontWeight:'800'}}>Address</span>
							    <input class="field__input" type="text" name="address" required ref={addressRef}/>
							  </label>
							  <label class="field">
							    <span class="field__label" for="country" style={{fontWeight:'800'}}>Country</span>
							    <input class="field__input" type="text" name="country" required ref={countryRef}/>
							  </label>
							  <div class="fields fields--3">
							    <label class="field">
							      <span class="field__label" for="zipcode" style={{fontWeight:'800'}}>Zip code</span>
							      <input class="field__input" type="text" name="zipcode" required ref={zipRef}/>
							    </label>
							    <label class="field">
							      <span class="field__label" for="city" style={{fontWeight:'800'}}>City</span>
							      <input class="field__input" type="text" name="city" required ref={cityRef}/>
							    </label>
							    <label class="field">
							      <span class="field__label" for="state" style={{fontWeight:'800'}}>State</span>
							      <input class="field__input" type="text" name='state' required ref={stateRef}/>
							    </label>
							  </div>
							  <input  type="checkbox"  name='same' style={{height:'0.8rem',fontWeight:'800' }}/><span >{props.addressType.current === 'Billing' ? '  Shipping' : '  Billing' } Address Same as {props.addressType.current} Address </span>
							  <button class='ss-button' style={{marginLeft:'30%', padding:'5px 10px',width:'150px',display:'inline-block',marginTop:'10%' }} >Add Address</button>
							  </form>
							</div>							 
						<div>
														
						</div>					
			</div>
			<ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}
                newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss
                draggable pauseOnHover theme="colored"/>			
		 </div>
		 )
}

export default AddAddress;