import React, { useState, useEffect, useRef,useContext } from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../js/constants'
import MyContext from './TeamsListDisplay'
function TeamLine(props){

const navigate = useNavigate(); 
 const [sessionData, setSessionData] = useState(null)
  useEffect(()=>{
    setSessionData(JSON.parse(sessionStorage.getItem('ss_sess')))
},[])

function editTeam(){
	if(sessionData.role !== 'agent')
		props.editTeam(props.team.id)
}
function checkBoxClicked(event){
	props.checkBoxClicked(props.team.id, event.target.checked)
}

let marginTopIndex = (props.index+3)+'px'
return (
	<tr>
	  { (sessionData !== null && sessionData.isAdmin) ?  
	  	 <th scope="row" class="ps-4" style={{width:'50px'}}>
	  		<div class="form-check font-size-16"><input type="checkbox" onClick={checkBoxClicked} class='form-check-input team-line-checkbox' name={'checkbox-'+props.team.id} style={{border:'1px solid #A9A9A9'}}/></div></th> 
	  		: '' 
	  	}
	    <th scope="row" class="ps-4" style = {{width: "10%", cursor: 'pointer', color: '#0052cc',fontWeight:'800',fonFamily:'Helvetica'}} onClick = {editTeam}>{props.team.name}</th>
	    <td style = {{width: "10%"}}>{props.team.count}</td>
	 </tr>
            );

}

export default TeamLine;