import React from "react"
import {useState, setState, useRef, useEffect} from 'react';
import DialPad from './DialPad'
import CreateTicketFormMain from '../../Ticket/js/CreateTicketForm'
import CreateTicketForm from './CreateTicketForm'
import {useNavigate} from "react-router-dom"
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import  '../css/call_window.css'
import * as twilioMain from '../../../js/twilio-main';
import ProfilePicture from '../../ProfilePicture/ProfilePicture'
function Contact(props){

	return(
		<div style={{borderRadius: '6px', padding:'10px',  borderRadius:'7px',width:'96%', marginTop:'10px', fontWeight:'bolder',color: 'white' }} class='call-window-contact'>
            <div style={{display:'inline-block', color: 'white',fontSize: '12px',width:'150px',overflow:'hidden',textOverflow:'ellipsis',whiteSpace: 'nowrap' }}>
            		{	props.image === undefined || props.image === 'null' || props.image === null ? 
		            		<ProfilePicture name={props.name} classProp={'call-window-contact-image'}/>
		            		: <img class="list-image"  src={ props.image} /> 
		            }
	            	<a href="#" style = {{ textDecoration: "none", marginLeft: '10px', color:'white'}} >{props.name}</a></div>
            <div style={{cursor:'pointer', color: 'white',fontSize: '12px',float:'right' }} onClick={()=>props.callNumber(props.number)}>{props.number}</div>
		</div>
	)
}

export default Contact;