import React from 'react';
import {useState, setState, useRef, useEffect} from 'react';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import NumberLine from './NumberLine'

function CallListFilterBox(props){
  const priorityDropdownRef = useRef(null)
  const directionsDropdownRef = useRef(null)
  const [selectedNumbers, setSelectedNumbers] = useState("")
  const [selectedDirections, setSelectedDirections] = useState("")
  const [numberList, setNumberList] = useState(null)
  const numbersDropdownRef = useRef(null)
  const statusDropdownRef = useRef(null)
  const [selectedStatus, setSelectedStatus] = useState('')
  const errorRef = useRef(null)
  const REPORTER_DROP_DOWN_UL_POS = 0
  const REPORTER_DROP_DOWN_MENU_POS = 1;
  const REPORTER_DROP_DOWN_INPUT_POS = 2;
  let selectedValuesMapTemp ={'agent': [], 'contact': [],
                          'company':[], 'team': [],
                          'contact_group':[], 'company_group':[],
                          'direction':[]
                        } 
  let tagsMapTemp = {'agent': [], 'contact': [],
              'company':[], 'team': [],
              'contact_group':[], 'company_group':[],'direction':[]
            }
  const [selectedValuesMap, setSelectedValuesMap] = useState(selectedValuesMapTemp)
  const [tagsMap, setTagsMap] = useState(tagsMapTemp)

useEffect(()=>{
    axiosClient.getRequest(constants.ACCOUNT_URL +'phone_number')
    .then(function (response) {
    let numbersListArr = response.data.map((element, index) =>(
           <NumberLine number={element.phoneNumber} />
      ))     
    setNumberList(numbersListArr)
    }).catch(function (response) {
        console.log(response)
    }); 
},[])
function handleSearch(event, type){ 
    let url = {'agent': constants.USER_URL,
              'contact': constants.CONTACT_URL,
              'contact_group': constants.CONTACT_GROUP_URL,
              'company' : constants.COMPANY_URL,
              'company_group' : constants.COMPANY_GROUP_URL,
              'team':constants.TEAM_URL
              // 'numbers':
            }

    event.target.parentElement.parentElement.children[REPORTER_DROP_DOWN_MENU_POS].innerHTML = ''
    if(event.target.value.length < 2)
      return
    axiosClient.getRequest(url[type]+"search_by_name?keyword="+event.target.value)
    .then(function (response) {          
        response.data.forEach((element, index)=>{             
            addChildToDropdown(element,event,type)              
        })
        if(response.data.length > 0)
          event.target.parentElement.parentElement.children[REPORTER_DROP_DOWN_MENU_POS].style.display = 'block'
    }).catch(function (response) {
        console.log(response);
    });
}

function validateForm(event){
      return true;
}

function handleSubmit(event){
  event.preventDefault()    
  if(!validateForm(event)){
    return
  }
  let payload = {'agent' : selectedValuesMap['agent'],
  'contact' : selectedValuesMap['contact'], 
  'company': selectedValuesMap['company'],
  // 'team': selectedValuesMap['team'], 'contact_group' : selectedValuesMap['contact_group'],
  'company_group': selectedValuesMap['company_group'] , 'numbers' : setSelectedNumbers,
  'status' : selectedStatus,  
  'createdFrom':event.target.createdFrom.value,
  'createdTo':event.target.createdTo.value,
  "direction":selectedDirections
  }
  props.handleFilterSubmit(payload,0)
}

function selectStatus(event){
  event.preventDefault()
  setSelectedStatus([])
  let elements = document.getElementsByClassName('status-checkbox')
  let temp = ''
  for(var i=0;i<elements.length;i++){
    if(elements[i].checked === true){      
      temp=temp+elements[i].name +' ,'
    }    
  }
  if(temp.length > 0)
    temp = temp.substring(0, temp.length-1);
  setSelectedStatus(temp)
  event.target.parentElement.style.visibility='hidden'
}
function selectNumbers(event){
  event.preventDefault()
  setSelectedNumbers([])
  let elements = document.getElementsByClassName('number-checkbox')
  let temp = ''
  for(var i=0;i<elements.length;i++){
    if(elements[i].checked === true){      
      temp=temp+elements[i].name +','
    }    
  }
  if(temp.length > 0)
    temp = temp.substring(0, temp.length-1);
  setSelectedNumbers(temp)
  event.target.parentElement.style.visibility='hidden'
}
function selectDirections(event){
  event.preventDefault()
  setSelectedNumbers([])
  let elements = document.getElementsByClassName('direction-checkbox')
  let temp = ''
  for(var i=0;i<elements.length;i++){
    if(elements[i].checked === true){      
      temp=temp+elements[i].name +','
    }    
  }
  if(temp.length > 0)
    temp = temp.substring(0, temp.length-1);
  setSelectedDirections(temp)
  event.target.parentElement.style.visibility='hidden'
}

function displayStatus(event){
  priorityDropdownRef.current.style.visibility='hidden'
  statusDropdownRef.current.style.visibility='visible'
  statusDropdownRef.current.children[1].scrollIntoView()
}
function displayPriorities(event){
  priorityDropdownRef.current.style.visibility='visible'
  statusDropdownRef.current.style.visibility='hidden'
  priorityDropdownRef.current.children[1].scrollIntoView()
}


function addChildToDropdown(element, event, type){
    let selectedValues = selectedValuesMap[type]
    if(selectedValues.includes(element.id))
      return
    let aTag = document.createElement('a')
    aTag.style.display='block'
    aTag.name=element.id
    aTag.innerHTML =  element.name
    aTag.onclick = (event) => addTag(event, type)
    event.target.parentElement.parentElement.children[REPORTER_DROP_DOWN_MENU_POS].appendChild(aTag)
}


function removeTag(event,id,type,ul){
  let selectedValuesMapTemp = selectedValuesMap
  let selectedValues = selectedValuesMapTemp[type]
  let idx = selectedValues.indexOf(id)  
  let tags = tagsMap[type]
  // selectedValues and tags must be in sync
  selectedValues.splice(idx, 1)
  tags.splice(idx,1)
  ul.querySelectorAll("li").forEach(li => li.remove());
  tags.slice().forEach((tag,index) =>{
      var li = document.createElement('li');  
      var span = document.createElement('span')      
      span.onclick=(event)=>removeTag(event, selectedValues[index], type, ul)
      span.innerHTML = `&times;`
      span.style.cursor = 'pointer'
      li.innerHTML = tag
      li.value = index      
      li.appendChild(span)
      ul.insertBefore(li, ul.lastChild) 
  }); 
  selectedValuesMapTemp[type] =  selectedValues
  setSelectedValuesMap(selectedValuesMapTemp)
}
function createTag(event, tags, type){
  // fetching corresponding ul
  const ul = event.target.parentElement.parentElement.children[0]
  ul.querySelectorAll("li").forEach(li => li.remove());
  let selectedValues = selectedValuesMap[type]
  tags.slice().forEach((tag,index) =>{
      var li = document.createElement('li');  
      var span = document.createElement('span')
      span.onclick=(event)=>removeTag(event, selectedValues[index], type, ul)
      span.innerHTML = `&times;`
      span.style.cursor = 'pointer'
      li.innerHTML = tag
      li.value = selectedValues[index]   
      li.appendChild(span)
      ul.insertBefore(li, ul.lastChild) 
      // ul.insertAdjacentHTML("afterbegin", li);
  });
}

function addTag(event, type){
  console.log('addTag '+event.target.name)            
  let tag = event.target.innerHTML.replace(/\s+/g, ' ');
  let tags = tagsMap[type]
  let selectedValuesMapTemp = selectedValuesMap
  let selectedValues = selectedValuesMapTemp[type]
  if( !selectedValues.includes(event.target.name)){
    // selectedValues and tags must be in sync
    selectedValues.push(event.target.name)
    tags.push(tag);        
    createTag(event,tags, type);
  }
  event.target.parentElement.parentElement.children[REPORTER_DROP_DOWN_UL_POS].lastChild.value=''
  event.target.parentElement.parentElement.children[REPORTER_DROP_DOWN_MENU_POS].style.display='none'
  event.target.parentElement.parentElement.children[REPORTER_DROP_DOWN_MENU_POS].innerHTML = ''  
  console.log('addTag '+JSON.stringify(selectedValuesMap))
  selectedValuesMapTemp[type] = selectedValues
  setSelectedValuesMap(selectedValuesMapTemp)
}
function displayNumber(){
  numbersDropdownRef.current.style.visibility='visible'
}
function displayDirections(){
  directionsDropdownRef.current.style.visibility='visible'
}

  return (
          <div class="ticket-filter-frame" ref={props.filterBoxRef} >
            <div><a href="javascript:void(0)"  onClick={props.closeFilterBox} class="ticket-filter-close-button-wrapper" >&times;</a></div>
             <b style={{marginTop: '2%'}}>Apply Filter</b>
             <form onSubmit={handleSubmit}>
               <div style={{ width:'100%', paddingleft:'0px',backgroundColor:'white',height: 'calc(100vh - 250px)', overflowY:'scroll', overflowX:'none'}}>
                 <div class="create-ticket-form-label" style={{ marginTop: "3%"}}>Agent:</div>
                  <div class="wrapper">
                    <div class="content">
                      <ul><input type="text" style={{height:'30px', width: '95%'}} spellcheck="false" onChange={(event) => handleSearch(event, 'agent')} name='agent'/></ul>
                      <div style={{display:'none', position :'absolute',width:'90%'}} class='create-ticket-filter-dropdown-content'/>
                      <input type='text' style={{display:'none'}} />
                    </div>
                  </div>                                   
                  <div class="create-ticket-form-label" style={{ marginTop: "3%"}}>Numbers</div>
                    <div style={{position:'relative'}}>
                        <div id="input" contenteditable='false' style={{paddingTop:'10px', fontSize:'16px',fontWeight:'500', height:'36px',width: '90%', border:' 1px solid black', borderRadius:'0px',verticalAlign: 'sub'}} onClick={displayNumber}>{selectedNumbers}</div>
                        <div style={{visibility: 'hidden', position: 'absolute', overflowY:'hidden',width:'90%'}} class='create-ticket-filter-dropdown-content' ref={numbersDropdownRef}>
                            <div style={{maxHeight:'200px', overflowY:'scroll',borderBottom:'1px solid #DAD9D2'}}>
                              {numberList}
                            </div>  
                          <a onClick={selectNumbers} style={{paddingLeft:'35%', cursor: 'pointer',fontWeight:'600',fontSize:'16px' }}>Done</a>
                        </div>
                  </div> 
                  <div class="create-ticket-form-label" style={{ marginTop: "3%"}}>Direction</div>
                    <div style={{position:'relative'}}>
                        <div id="input" contenteditable='false'  style={{paddingTop:'10px', fontSize:'16px',fontWeight:'500', height:'36px', width: '90%',  border:' 1px solid black', borderRadius:'0px',verticalAlign: 'sub'}} onClick={displayDirections}>{selectedDirections}</div>
                        <div style={{visibility: 'hidden', position: 'absolute', overflowY:'hidden',width:'90%'}} class='create-ticket-filter-dropdown-content' ref={directionsDropdownRef}>
                            <div style={{maxHeight:'200px', overflowY:'scroll',borderBottom:'1px solid #DAD9D2'}}>
                              <a><input id = 'outgoing' type='checkbox' name="Outgoing" class='direction-checkbox' style={{marginRight: '10px' }}/> <label for ='outgoing' style={{width:'90%',cursor:'pointer'}}><span style={{fontWeight:'500',fontSize:'16px'}}>Outgoing</span></label> </a>
                              <a><input id = 'incoming' type='checkbox' name="Incoming" class='direction-checkbox' style={{marginRight: '10px' }}/> <label for ='incoming' style={{width:'90%',cursor:'pointer'}}><span style={{fontWeight:'500',fontSize:'16px'}}>Incoming</span></label> </a>
                            </div>  
                          <a onClick={selectDirections} style={{paddingLeft:'35%', cursor: 'pointer',fontWeight:'600',fontSize:'16px' }}>Done</a>
                        </div>
                  </div>                                   
                  <div class="create-ticket-form-label" style={{ marginTop: "3%"}}>Customer:</div>
                 <div class="wrapper">
                    <div class="content">
                      <ul><input type="text" style={{height:'30px', width: '95%'}} spellcheck="false" onChange={(event) => handleSearch(event, 'contact')} name='contact'/></ul>
                      <div style={{display:'none', position :'absolute'}} class='create-ticket-filter-dropdown-content'/>
                    </div>
                 </div>
                 <div class="create-ticket-form-label" style={{ marginTop: "3%"}}>Company:</div>
                 <div class="wrapper">
                      <div class="content">
                        <ul><input type="text" style={{height:'30px', width: '95%'}} spellcheck="false" onChange={(event) => handleSearch(event, 'company')} name='company'/></ul>
                        <div style={{display:'none', position :'absolute'}} class='create-ticket-filter-dropdown-content'/>
                      </div>
                 </div>                
              {/*
                 <div class="create-ticket-form-label" style={{ marginTop: "3%"}}>Team:</div>
                    <div class="wrapper">
                      <div class="content">
                        <ul><input type="text" style={{height:'30px', width: '95%'}} spellcheck="false" onChange={(event) => handleSearch(event, 'team')} name='team'/></ul>
                        <div style={{display:'none', position :'absolute'}} class='create-ticket-filter-dropdown-content'/>
                      </div>
                 </div>
              */}                                
                 <div class="create-ticket-form-label" style={{ marginTop: "3%"}}>Contact Group:</div>
                    <div class="wrapper">
                      <div class="content">
                        <ul><input type="text" style={{height:'30px', width: '95%'}} spellcheck="false" onChange={(event) => handleSearch(event,'contact_group')} name='contact_group'/></ul>
                        <div style={{display:'none', position :'absolute'}} class='create-ticket-filter-dropdown-content'/>
                      </div>
                    </div>
                 <div class="create-ticket-form-label" style={{ marginTop: "3%"}}>Company Group:</div>
                    <div class="wrapper">
                      <div class="content">
                        <ul><input type="text" style={{height:'30px', width: '95%'}} spellcheck="false" onChange={(event) => handleSearch(event,'company_group')} name='company_group'/></ul>
                        <div style={{display:'none', position :'absolute'}} class='create-ticket-filter-dropdown-content'/>
                      </div>
                    </div>
                    <div class="create-ticket-form-label" style={{fontSize:'14px', marginTop: "3%"}}>Created Date</div>
                    <div>
                        <input type='date' name='createdFrom' style = {{width: "90%", borderRadius: '5px',  height: '30px', border: 'solid 1px'}}/> 
                        <div style={{width:'90%', marginTop:'3px', marginBottom:'3px', fontSize:'14px', fontWeight:'600',display:'flex',justifyContent:'center'}}>TO</div>
                        <input type='date' name='createdTo' style = {{width: "90%",  borderRadius: '5px', height: '30px', border: 'solid 1px'}}/>
                    </div>
                </div>                                
                <div ref={errorRef}/>
                <div style={{display:'flex', alignItems:'center', justifyContent:'center', position:'relative',bottom:'15px',height:'105px',width:'100%',margin:'auto'}}>
                  <button class='ss-button ticket-filter-button'  >Filter Calls</button>
                </div>                
             </form>
        </div>
    )
}






export default CallListFilterBox;